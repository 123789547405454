/* eslint-disable */
import React, { Component } from 'react';
import PureModal from 'react-pure-modal';
import { Multiselect } from 'multiselect-react-dropdown';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from 'react-loader-spinner';
import jwt_decode from 'jwt-decode';
import ReactTooltip from 'react-tooltip';
import { GiTrashCan } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
import moment from 'moment';
import Greeting from '../layouts/Greeting';
import LocationDetails from '../layouts/LocationDetails';
import SearchInput from '../inputs/SearchInput';
import { formatDateforPicker } from '../../utility/dateTime';
import { Get, Delete, Post, Put } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import notification from '../../utility/notification';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import AssignAssetFormModal from '../layouts/AssignAssetFormModal';
import ColorController from '../../controllers/ColorController';
import Cookies from 'js-cookie';
import { RiAddLine } from 'react-icons/ri';
import AssetProfilerFormModal from '../layouts/AssetProfilerFormModal';
import EditAssetProfilerFormModal from '../layouts/EditAssetProfilerFormModal';
import data2 from '../data';
import AssetProfilerDetailsModal from '../layouts/AssetProfilerDetailsModal';
import FormSearchInput from '../inputs/FormSearchInput';
import { GetFiles } from '../../utility/fetch';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};
const alignLeft = {
  textAlign: 'left',
};

class AssetProfiler extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      isLoading: false,
      selectedDate: 'All Cases',
      startDate: '',
      endDate: '',
      searchText: null,
      calendarDate: new Date(),
      visible: false,
      requestButton: 'Requests',
      approvedButton: 'Approved',
      initiatedreq: [],
      itemId: 0,
      name: '',
      description: '',
      manufacturer: '',
      quantity: '',
      deliveryDate: '',
      assetSerial: '',
      category: '',
      editVisibility: false,
      list: [],
      loadTable: false,
      categoryRecords: [],
      decodedToken: {},
      fileData: [],
      loadDeleteSupportingDoc: false,
      approverFileData: [],
      openApproversTableModal: false,
      tableApproverId: [],
    };
    this.filterReviewsOnApprover = this.filterReviewsOnApprover.bind(this);
  }

  openModal = () => {
    this.setState({
      validationStatus: '',
      itemName: '',
      categoryId: '',
      manufacturer: '',
      supplier: '',
      unitCost: '',
      serialNumber: '',
      quantity: '',
      description: '',
      deliveryDate: '',
      supportingDoc: [],
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({ visible: false, editVisibility: false });
  };

  viewAsset = (item) => {
    this.setState({
      editVisibility: true,
      itemId: item.id,
      name: item.name,
      description: item.description,
      manufacturer: item.manufacturer,
      quantity: item.quantity,
      deliveryDate: item.deliveryDate,
      assetSerial: item.assetSerial,
      category: item.category,
    });
  };

  setApproved = () => {
    this.setState({
      requestButton: 'Approved',
    });
  };

  getApproval = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      this.setState({ list: _records, loadTable: false });
    }
  };

  filterReviewsOnApprover = () => {
    this.setState({
      initiatedreq: this.state.initiatedreq.filter(
        (rec) => rec.id !== parseInt(this.state.requestId),
      ),
    });
    Get(URLAPI.requestApprovals.Get, this.getApproval);
  };

  componentDidMount() {
    this._isMounted = true;
    try {
      const token = Cookies.get('token');
      const decodedToken = jwt_decode(token);

      this.setState({ loadTable: true, decodedToken });
      Get(URLAPI.profiler.getSavedList, this.getApproval);
      Get(URLAPI.profiler.GetCategoryItems, this.getCategory);
    } catch (err) {
      this.setState({ loadTable: false });
    }
  }

  searchDocument = (value) => {
    this.setState({ loadSearch: true });
    GetFiles(this.getDocuments, `user_id=${this.state.decodedToken.userid}&DocTitle=${value}`);
  };

  searchApprovers = (value) => {
    this.setState({ loadSearch: true });
    const name = value.trim();

    if (name === '') {
      return this.setState({
        loadSearch: false,
      });
    }
    Get(`api/profiler/searchapprovers/${name}`, this.getApprovers);
  };

  isError = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Error]";
  }

  getDocuments = (res) => {
    console.log(res, 'searching document here, res was here');

    if (this.isError(res)) {
      return;
    }

    this.setState({ fileData: res, loadSearch: false });
  };

  getApprovers = (res) => {
    this.setState({ approverFileData: res, loadSearch: false });
  };

  getCategory = (records) => {
    this.setState({
      categoryRecords: records,
    });
  };

  onEdit = (item) => {
    this.setState({
      supportingDoc: [],
      validationStatus: '',
    });
    if (item) {
      const {
        id,
        itemName,
        description,
        supplier,
        manufacturer,
        deliveryDate,
        unitCost,
        serialNumber,
        categoryId,
        quantity,
        action,
        supportingDoc,
        category,
      } = item;

      let formattedSupportingDoc = [];
      if (supportingDoc.length > 0) {

        supportingDoc.forEach((element) => {
          const newObject = {
            id: element.id,
            docPath: element.documentPath,
            docTitle: element.documentTitle,
          };
          formattedSupportingDoc.push(newObject);
        });
      }

      this.setState({
        id,
        itemName,
        description,
        supplier,
        manufacturer,
        deliveryDate: new Date(deliveryDate).toISOString().substr(0, 10),
        unitCost,
        serialNumber,
        categoryId,
        quantity,
        action: 'save',
        supportingDoc: formattedSupportingDoc,
        category,
        visible: false,
        editVisibility: true,
      });
      formattedSupportingDoc.length === 0;
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  returnRequestButtonState = () => {
    this.setState({
      requestButton: 'Requests',
    });
  };

  handleEditRecord = (item) => {
    deleteMessage(() => this.submitAsset(item));
  };

  submitAsset = (item) => {
    // confirmToSubmit(() => Put({}, URLAPI.profiler.SubmitProfiler + '/' + item.id, this.handleSubmitResult))

    confirmAlert({
      title: 'Confirm to submit',
      message: `Are you sure to submit ${item.name}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            Put({}, URLAPI.profiler.SubmitProfiler + '/' + item.id, this.handleSubmitResult);
            this.setState({ loadTable: true });
          },
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  handleSubmitResult = (res) => {
    if (res.code === 1) {
      this.setState({
        openApproversTableModal: false,
      });
      successMessage(res.message);
      Get(URLAPI.profiler.getSavedList, this.getApproval);
    } else {
      errorMessage(res.message);
      this.setState({ loadTable: false, openApproversTableModal: false });
    }
  };

  onSubmitTableApprover = (id) => {
    /*const { tableApproverId } = this.state;
    this.setState({ loadTable: true });

    // check if it's empty and return an error message
    if (tableApproverId.length === 0) {
      this.setState({
        openApproversTableModal: false,
        loadTable: false,
      });
      return errorMessage(
        'Asset Entry Request needs approvers to submit. Please add approvers to submit',
      );
    }

    this.setState({
      openApproversTableModal: false,
    });*/
    confirmAlert({
      title: 'Confirm to submit',
      message: `Are you sure to submit this record`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            Put({ approverId: [0] }, 'api/profiler/submit' + '/' + id, this.handleSubmitResult);
            this.setState({ loadTable: true });
          },
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  renderTable = () => {
    if (this.state.requestButton === 'Requests')
      return (
        <>
          <div className='flex'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Name</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Manufacturer</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Quantity</p>
                  </div>
                  {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Category</p>
                  </div> */}
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Delivery Date</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Serial Number</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Supplier</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Total value</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Actions</p>
                  </div>
                </div>
              </div>
              {this.state.loadTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <div className='table-body no-border'>
                  {this.state.list.length === 0 ? (
                    <div>
                      <br />
                      <h3>
                        <i></i>
                      </h3>
                    </div>
                  ) : (
                    <>
                      {this.state.list.map((item, idx) => (
                        <div className='table-row no-border' key={idx}>
                          <div
                            className='td font-sz-13'
                            style={{
                              ...tableBorder,
                            }}>
                            {item.itemName || 'Empty  name'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.manufacturer || 'Empty manufacturer'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.quantity || 'Empty quantity'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.deliveryDate || 'Empty delivery date'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.serialNumber || 'Empty serial number'}
                          </div>
                          <div className='td font-sz-13 ' style={{ ...tableBorder }}>
                            {item.supplier || 'Empty supplier'}
                          </div>
                          <div className='td font-sz-13 ' style={{ ...tableBorder }}>
                            &#8358;{item.totalCost || 'Empty total cost'}
                          </div>
                          <div className='td m-l-10 font-sz-22 pointer' style={{ ...tableBorder }}>
                            <button
                              className='btn'
                              onClick={() => {
                                this.onSubmitTableApprover(item.id);
                              }}>
                              Submit
                            </button>
                            {'                   '}

                            {this.state.loadForm ? null : (
                              <>
                                <button
                                  data-tip
                                  data-for='edit-btn'
                                  type='button'
                                  className='pointer td actions'
                                  onClick={() => this.onEdit(item)}>
                                  <GrEdit />
                                </button>
                                <ReactTooltip id='edit-btn' type='warning' effect='solid'>
                                  <span>Edit Asset</span>
                                </ReactTooltip>
                                <button
                                  data-tip
                                  data-for='del-btn'
                                  type='button'
                                  className='m-l-10 del pointer td actions'
                                  onClick={() => this.handleDeleteRecord(item)}>
                                  <GiTrashCan />
                                </button>
                                <ReactTooltip id='del-btn' type='error' effect='solid'>
                                  <span>Delete Asset</span>
                                </ReactTooltip>
                              </>
                            )}
                          </div>

                          {this.state.openApproversTableModal && (
                            <div class='col-3 m-t-30'>
                              <PureModal
                                // style={{ width: '50%'}}
                                width='500px'
                                header='Add Asset Entry Approvers in the order of approval'
                                footer={
                                  <div>
                                    <button
                                      onClick={() =>
                                        this.setState({
                                          openApproversTableModal: false,
                                        })
                                      }>
                                      Cancel
                                    </button>
                                  </div>
                                }
                                isOpen={this.state.openApproversTableModal}
                                closeButton='close'
                                closeButtonPosition='bottom'
                                onClose={() => {
                                  this.setState({
                                    openApproversTableModal: false,
                                  });
                                }}>
                                <Multiselect
                                  options={this.state.approverFileData}
                                  displayValue='name'
                                  placeholder=''
                                  style={{
                                    chips: {
                                      background: 'gray',
                                      'white-space': 'normal',
                                    },
                                    multiselectContainer: {
                                      color: 'gray',
                                      'background-color': 'white',
                                      'margin-bottom': '10px',
                                      'white-space': 'normal',
                                    },
                                  }}
                                  // loading
                                  emptyRecordMsg='Search for approvers'
                                  onSearch={(val) => {
                                    this.searchApprovers(val);
                                  }}
                                  onSelect={(selectedList, selectedItem) => {
                                    const modulesList = selectedList.map((item) => item.id);
                                    this.setState({ tableApproverId: modulesList });
                                  }}
                                  onRemove={(selectedList, removedItem) => {
                                    const modulesList = this.state.tableApproverId.filter(
                                      (item) => item !== removedItem.id,
                                    );
                                    this.setState({ tableApproverId: modulesList });
                                  }}
                                />
                                <br />
                                <button
                                  className='btn btn-large w-100'
                                  type='button'
                                  onClick={() => {
                                    this.onSubmitTableApprover(item.id);
                                  }}>
                                  Select Approvers/Submit
                                </button>
                              </PureModal>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      );
  };

  renderMessage = () => {
    if (this.state.requestButton === 'Requests')
      return (
        <>
          <div className='w-100 flex' style={{ backgroundColor: '#d5ffd5' }}>
            <p>Add Existing Asset</p>
          </div>
          <div
            className='row td actions justify-content-end pd-x-7 col-2 no-margin'
            style={{ display: 'flex', ...alignLeft }}>
            <div>
              <div className='tooltip'>
                <button
                  type='button'
                  style={{ backgroundColor: '#FFFFFF' }}
                  className='m-l-10  add-button pointer'
                  onClick={this.openModal}>
                  <RiAddLine />
                  Add An Asset
                  <span className='tooltiptext'>Add an Asset</span>
                </button>
              </div>
            </div>
          </div>
        </>
      );
  };

  onDelete = (record) => {
    this.setState({ loadTable: true });
    Delete(URLAPI.profiler.delete + '/' + record.id, this.onAfterDelete);
  };

  onAfterDelete = (res) => {
    if (res.code === 1) {
      successMessage(res.message);
      Get(URLAPI.profiler.getSavedList, this.getApproval);
    } else if (res.code === 1) {
      errorMessage(res.message);
      this.setState({ loadTable: false, visible: false });
    } else {
      errorMessage(res.message);
      this.setState({ loadTable: false });
    }
  };

  handleDeleteRecord = (record) => {
    deleteMessage(() => this.onDelete(record));
  };

  handleDeleteSupportingDocument = (file) => {
    this.onDeleteSupportingDocument(file.id);
  };

  onDeleteSupportingDocument = (id) => {
    this.setState({ loadDeleteSupportingDoc: true });
    Delete(URLAPI.profiler.SupportingDocDelete + '/' + id, this.afterDeleteSupportingDoc);
  };

  afterDeleteSupportingDoc = (response) => {
    if (response.code === 1) {
      Get(URLAPI.profiler.getSavedList, this.getApproval);
      this.setState({ loadDeleteSupportingDoc: false });
    } else {
      this.setState({ loadDeleteSupportingDoc: false });
    }
  };

  validateForm = (state) => {
    const {
      itemName,
      categoryId,
      manufacturer,
      supplier,
      unitCost,
      serialNumber,
      quantity,
      description,
      deliveryDate,
      supportingDoc,
    } = state;

    if (!itemName) return { status: true, message: 'Please, Select enter an item name' };
    if (categoryId === 0) return { status: true, message: 'Please, Select a category' };
    if (!manufacturer) return { status: true, message: 'Please, Enter a manufacturer' };
    //if (!supplier) return { status: true, message: 'Please, Enter a supplier' };
    //if (unitCost === 0) return { status: true, message: 'Please, Enter a unit cost' };
    if (!serialNumber) return { status: true, message: 'Please, Enter a serial number' };
    if (quantity === 0) return { status: true, message: 'Please, Enter a quantity' };
    if (!deliveryDate) return { status: true, message: 'Please, Enter a delivery date' };
    if (!description) return { status: true, message: 'Please, Enter a description' };
    // if (supportingDoc.length === 0)
    //   return { status: true, message: 'Please upload Assets pictures' };
    return false;
  };

  onUpdate = (data, action) => {
    this.setState({
      validationStatus: '',
      validationCode: 0,
      loading: true,
    });
    let error_data = this.validateForm(data);

    if (error_data.status && error_data.status === true) {
      return this.setState({
        loadTable: false,
        loadForm: false,
        validationStatus: error_data.message,
        validationCode: 0,
        loading: false,
      });
    }

    if (error_data === false) {
      const {
        // action,
        itemName,
        categoryId,
        manufacturer,
        supplier,
        unitCost,
        serialNumber,
        quantity,
        description,
        deliveryDate,
        separateNewFile,
        supportingDoc,
      } = data;

      const request = {
        itemName: itemName.trim(),
        categoryId: Number(categoryId),
        manufacturer: manufacturer.trim(),
        supplier: supplier.trim(),
        unitCost: parseFloat(unitCost.replace(/,/g, '')),
        serialNumber: serialNumber.trim(),
        quantity: Number(quantity),
        description: description.trim(),
        deliveryDate,
        supportingDoc: separateNewFile,
        action,
      };

      this.onUpdateAsset(request);
    }
  };

  onSubmit = (data, action) => {
    this.setState({
      validationStatus: '',
      validationCode: 0,
      loading: true,
    });
    let error_data = this.validateForm(data);

    if (error_data.status && error_data.status === true) {
      this.setState({
        loadTable: false,
        loadForm: false,
        // validationStatus: error_data.message,
        // validationCode: 0,
        loading: false,
      });
      return notification({
        title: 'Invalid Entry',
        message: error_data.message,
        type: 'danger',
      });
    }

    if (error_data === false) {
      const {
        // action,
        itemName,
        categoryId,
        manufacturer,
        supplier,
        unitCost,
        serialNumber,
        quantity,
        description,
        deliveryDate,
        supportingDoc,
        approverId,
      } = data;
      let request;
      let vendor = supplier;
      if (supplier === "") {
        vendor = "None";
      }
      if (unitCost === "") {
        unitCost = 0;
      }

      if (action === 'Save') {
        request = {
          itemName: itemName.trim(),
          categoryId,
          manufacturer: manufacturer.trim(),
          supplier: vendor.trim(),
          unitCost,
          serialNumber,
          quantity,
          description: description.trim(),
          deliveryDate,
          supportingDoc,
          action,
        };
        this.onSaveAsset(request);
      }

      if (action === 'Submit') {
        request = {
          itemName: itemName.trim(),
          categoryId,
          manufacturer: manufacturer.trim(),
          supplier: vendor.trim(),
          unitCost,
          serialNumber,
          quantity,
          description: description.trim(),
          deliveryDate,
          supportingDoc,
          action,
          approverId,
        };
        this.onSubmitAsset(request);
      }
    }
  };

  onSaveAsset = (data) => {
    Post(data, URLAPI.profiler.post, this.handleProfilerSubmit);
  };

  onSubmitAsset = (data) => {
    Post(data, URLAPI.profiler.post, this.handleProfilerSubmit);
  };

  onUpdateAsset = (data) => {
    Put(data, URLAPI.profiler.UpdateProfiler + '/' + this.state.id, this.handleProfilerSubmit);
  };

  handleProfilerSubmit = (record) => {
    if (record.code === 1) {
      this.setState({
        // validationStatus: record.message,
        // validationCode: 1,
        loading: false,
        editVisibility: false,
        visible: false,
      });
      notification({
        title: 'Successful',
        message: record.message,
        type: 'success',
      });
      successMessage(record.message);
      Get(URLAPI.profiler.getSavedList, this.getApproval);
      this.setState({
        validationStatus: '',
        fileData: [],
      });
    }

    if (record.code === 0) {
      notification({
        title: 'Error',
        message: record.message,
        type: 'danger',
      });
      return this.setState({
        loading: false,
        // validationStatus: record.message,
        // validationCode: 0,
      });
    }

    if (record.code === undefined || !record.code) {
      this.setState({
        loading: false,
        editVisibility: false,
        visible: false,
      });

      return errorMessage('Profiler process encountered an error, please try again later.');
    }
  };

  render() {
    const { editVisibility, visible } = this.state;
    return (
      <>
        <div className='w-100'>
          <div className='w-100 flex flex-v-center space-between'>
            <Greeting />
            <LocationDetails />
          </div>
          <div className='w-100 m-t-20'>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
              {this.renderMessage()}
            </div>
          </div>
          {this.renderTable()}
        </div>

        {visible && (
          <AssetProfilerFormModal
            description={this.state.description}
            supplier={this.state.supplier}
            manufacturer={this.state.manufacturer}
            deliveryDate={this.state.deliveryDate}
            unitCost={this.state.unitCost}
            serialNumber={this.state.serialNumber}
            quantity={this.state.quantity}
            supportingDoc={this.state.supportingDoc}
            itemName={this.state.itemName}
            category={this.state.categoryRecords}
            categoryValue={this.state.category}
            onSubmit={this.onSubmit}
            validationStatus={this.state.validationStatus}
            validationCode={this.state.validationCode}
            loading={this.state.loading}
            searchDocument={this.searchDocument}
            searchApprovers={this.searchApprovers}
            fileData={this.state.fileData}
            loadingModal={this.state.loadSearch}
            loadingApproversModal={this.state.loadSearch}
            approverFileData={this.state.approverFileData}
            closeModal={() => this.setState({ visible: false })}
          />
        )}

        {editVisibility && (
          <EditAssetProfilerFormModal
            description={this.state.description}
            supplier={this.state.supplier}
            manufacturer={this.state.manufacturer}
            deliveryDate={this.state.deliveryDate}
            unitCost={this.state.unitCost}
            serialNumber={this.state.serialNumber}
            quantity={this.state.quantity}
            supportingDoc={this.state.supportingDoc}
            itemName={this.state.itemName}
            category={this.state.categoryRecords}
            categoryId={this.state.categoryId}
            categoryValue={this.state.category}
            onUpdate={this.onUpdate}
            validationStatus={this.state.validationStatus}
            validationCode={this.state.validationCode}
            loading={this.state.loading}
            searchDocument={this.searchDocument}
            fileData={this.state.fileData}
            loadingModal={this.state.loadSearch}
            closeModal={() => this.setState({ editVisibility: false })}
            handleDeleteSupportingDocument={this.handleDeleteSupportingDocument}
            loadDeleteSupportingDoc={this.state.loadDeleteSupportingDoc}
          />
        )}

        {/* add approvers for submit from the table */}
        {/* {this.state.openApproversTableModal && (
          <div class='col-3 m-t-10'>
            <PureModal
              header='Add Asset Approvers'
              footer={
                <div>
                  <button
                    onClick={() => this.setState({ openApproversTableModal: !openApproversTableModal })}>
                    Cancel
                  </button>
                </div>
              }
              isOpen={this.state.openApproversTableModal}
              closeButton='close'
              closeButtonPosition='bottom'
              onClose={() => {
                this.setState({ openApproversTableModal: !openApproversTableModal });
              }}>
              <Multiselect
                options={this.state.approverFileData}
                displayValue='name'
                placeholder=''
                style={{
                  chips: {
                    background: 'gray',
                    'white-space': 'normal',
                  },
                  multiselectContainer: {
                    color: 'gray',
                    'background-color': 'white',
                    'margin-bottom': '10px',
                    'white-space': 'normal',
                  },
                }}
                // loading
                emptyRecordMsg='Search for approvers'
                onSearch={(val) => {
                  this.searchApprovers(val);
                }}
                onSelect={(selectedList, selectedItem) => {
                  const modulesList = selectedList.map((item) => item.id);
                  this.setState({ tableApproverId: modulesList });
                }}
                onRemove={(selectedList, removedItem) => {
                  const modulesList = this.state.tableApproverId.filter(
                    (item) => item !== removedItem.id,
                  );
                  this.setState({ tableApproverId: modulesList });
                }}
              />
              <br />
              <button
                className='btn btn-large w-100'
                type='button'
                onClick={() => {
                  this.onSubmitTableApprover(id);
                }}>
                Submit Approvers
              </button>
            </PureModal>
          </div>
        )} */}
      </>
    );
  }
}

export default AssetProfiler;
