/*eslint-disable */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import CommentForm from './CommentForm';
import ButtonLoader from '../../assets/svg/loading.svg';
import moment from 'moment';
import FormSearchInput from '../inputs/FormSearchInput';
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
import Select from 'react-select';
import { Post, Get, Delete, Put, GetwithQueryString } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import { GrEdit } from 'react-icons/gr';
import data from '../../data';
import { ImCheckmark, ImCancelCircle, ImEye } from 'react-icons/im';
import Cookies from 'js-cookie';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import ViewReviewDisposalDetails from '../layouts/ViewReviewDisposalDetails';
import LoadingSpinner from '../../resources/loadingSpinner';
const bottomBorder = {
  borderBottom: '.5px solid rgba(99,99,99,.6)',
};
const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class AssetWithdrawalRequest extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      approvalId: '',
      sequence: '',
      button: 'Save Record',
      records: [],
      employees: [],
      employee: 0,
      employeeName: '',
      bgColor: '',
      requestId: 0,
      approval: [],
      loading: false,
      bgColor: 'white',
      loadTable: false,
      loadForm: false,
      category: 'Request',
      sequence: 0,
      roleId: 0,
      visibleDetails: false,
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEmpOption = (approvalId) => {
    // this.setState({
    //   approvalId: e.target.options[e.target.selectedIndex].text,
    //   [app.target.name]: e.target.value,
    //   approvalId

    // }, () => console.log('option selected', this.state.approvalId))
    this.setState({ approvalId }, () => console.log('option selected', this.state.approvalId));
  };

  // getEmployee = (data) => {
  //   console.log(data);

  //   if (this._isMounted) {
  //     let _records = data
  //     this.setState({ employee: _records, loading: false });

  //   }
  // };

  getEmployee = (data) => {
    // let _title = data

    // let records = [];

    // if (_title != "") {
    //   _title.forEach(function (l, index) {
    //     let sn = { value: l.id, label: l.firstName + ' ' + l.lastName, };
    //     records.push(sn)
    //   })
    // }
    // this.setState({ employee: records, loading: false });

    console.log('Logging employees data ', data);

    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];

      this.setState({ employees: _records, loading: false });
    }
  };

  getApproval = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];

      this.setState({ approval: _records, loadTable: false });
    }
  };

  getRoleList = (data) => {
    this.setState({
      employees: data,
    });
  };

  componentDidMount() {
    this.setState({ loadTable: true });
    this._isMounted = true;
    try {
      //   Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);
      Get('api/disposal/pendingapprovallist', this.getApprovalPendingList);
    } catch (err) {
      this.setState({ loadTable: false });
    }
  }

  getApprovalPendingList = (data) => {
    // console.log(data, 'withdrawal data was here and here today');
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      this.props.recordWithdrawalLength({ len: _records.length, loading: false });

      this.setState({ approval: _records, loadTable: false });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateForm = () => {
    const { sequence, roleId } = this.state;

    if (!roleId) return { status: true, message: 'Please, Select a role' };
    if (!sequence) return { status: true, message: 'Please, Enter Approval Sequence' };
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loadTable: true,
      loadForm: true,
    });

    const { sequence, roleId, category, id } = this.state;

    let error_data = this.validateForm();

    if (error_data.status && error_data.status === true) {
      this.setState({
        loadTable: false,
        loadForm: false,
      });
      return errorMessage(error_data.message);
    }
    if (error_data === false) {
      let request;

      if (this.state.button === 'Save Record') {
        request = {
          roleId: Number(roleId),
          category,
          sequence: Number(sequence),
        };
        this.onSubmitSave(request);
      } else if (this.state.button === 'Update') {
        request = {
          id,
          roleId: Number(roleId),
          category,
          sequence: Number(sequence),
        };
        this.onSubmitUpdate(request);
      }
    }
  };

  onSubmitSave = (data) => {
    Post(data, URLAPI.approval.Post, this.onSubmitAfterSave);
  };

  onSubmitAfterSave = (data) => {
    if (data.code === 1) {
      successMessage(data.message);

      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);

      this.setState({
        roleId: 0,
        role: '',
        sequence: 0,
        loadForm: false,
      });
    }
    if (data.code === 0) {
      this.setState({
        roleId: 0,
        role: '',
        sequence: 0,
        loadTable: false,
        loadForm: false,
      });
      errorMessage(data.message);
    }
  };

  handleDeleteRecord(record) {
    deleteMessage(() => this.onDelete(record));
  }

  onDelete = (record) => {
    this.setState({ loadTable: true });
    Delete(URLAPI.approval.Delete + '/' + record.id, this.onAfterDelete);
  };

  onAfterDelete = (res) => {
    if (res.code === 1) {
      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);
      successMessage(res.message);

      this.setState({ loadTable: false });
    } else if (res.code === 1) {
      this.setState({ loadTable: false, visible: false });
      errorMessage(res.message);
    } else {
      this.setState({ loadTable: false });
      errorMessage(res.message);
    }
  };

  onEdit = (item) => {
    if (item) {
      const { roleId, sequence, id, role } = item;
      this.setState({
        roleId,
        id,
        button: 'Update',
        sequence,
        role,
        bgColor: '#c2f542',
      });
    }
  };

  onSubmitUpdate = (request) => {
    if (request.itemCategoryId !== 0) {
      Put(request, `${URLAPI.approval.Put}/${request.id}`, this.onSubmitAfterUpdate);
    }
  };

  onSubmitAfterUpdate = (data) => {
    if (data.code === 1) {
      successMessage(data.message);
      this.setState({ loadTable: true });
      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);
      //commit to state
      this.setState({
        selectedRole: '',
        roleId: 0,
        sequence: 0,
        button: 'Save Record',
        loadForm: false,
        bgColor: 'white',
      });

      //this.handleCancel();
    } else if (data.code === 0) {
      this.setState({ loadTable: false, loadForm: false, button: 'Save Record', bgColor: 'white' });
      errorMessage(data.message);
    } else {
      errorMessage('Something went wrong while trying to update record Please, try again later.');
      this.setState({ loadTable: false, loadForm: false, button: 'Save Record', bgColor: 'white' });
    }
  };

  handleClear = () => {
    //clear all action
    this.setState({
      sequence: 0,
      role: '',
      button: 'Save Record',
      bgColor: 'white',
    });
  };

  handleStateChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleAction = (comment) => {
    this.setState({
      validationStatus: '',
      validationCode: 0,
      loading: true,
    });
    if (!comment) {
      return this.setState({
        validationStatus: 'Comment cannot be empty',
        validationCode: 0,
        loading: false,
      });
    }
    Put(
      {
        id: this.state.id,
        action: this.state.type,
        comment,
      },
      'api/disposal/approve',
      this.afterAction,
    );
  };

  afterAction = (response) => {
    if (response.code === 1) {
      this.setState({
        loadTable: true,
      });
      Get('api/disposal/pendingapprovallist', this.getApprovalPendingList);
      this.setState({
        visibility: false,
        loading: false,
      });

      successMessage(response.message);
    } else {
      this.setState({
        visibility: false,
        loading: false,
      });
      errorMessage(response.message);
    }
  };

  render() {
    const { sequence, button, loading, loadTable, loadForm, approval, visibleDetails } = this.state;
    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '25px 15px',
                marginLeft: 10,
                // marginRight: 10,
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Initiator</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Status</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Name</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Serial Number</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Supplier</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Manufacturer</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Date Entered</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Actions</p>
                  </div>
                </div>
              </div>

              {loadTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <>
                  {approval.length === 0 ? (
                    <div style={{ marginLeft: 10 }}>
                      <br />
                      <h3>No Pending Asset Disposal</h3>
                    </div>
                  ) : (
                    <div className='table-body no-border'>
                      {approval.map((item, i) => (
                        <div className='table-row no-border' key={item.id}>
                          <div
                            className='td font-sz-13'
                            style={{
                              ...tableBorder,
                            }}>
                            {item.initiatedBy || 'null'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.assetStatus || 'null'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.itemName || 'null'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.serialNumber || 'null'}
                          </div>

                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.supplier || 'null'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.manufacturer || 'null'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.dateProfiled || 'null'}
                          </div>

                          <div className='td m-l-10 font-sz-22 pointer' style={{ ...tableBorder }}>
                            {button === 'Update' || loadForm ? null : (
                              <>
                                <button
                                  data-tip
                                  data-for='view-details'
                                  type='button'
                                  className='pointer td actions'
                                  onClick={() => {
                                    const {
                                      itemName,
                                      initiatedBy,
                                      manufacturer,
                                      supplier,
                                      serialNumber,
                                      unitCost,
                                      disposalNote,
                                      disposalCategory,
                                      description,
                                      dateProfiled,
                                      dateInitiated,
                                      dateDelivered,
                                      assetStatus,
                                      assetCategory,
                                      assetApprovalStatus,

                                      assignmentHistory,
                                      assetSupportingDoc,
                                      approvers,
                                      disposalSupportingDoc,

                                      currentValue
                                    } = item;

                                    // console.log(item, 'item on withdrawal')

                                    this.setState({
                                      visibleDetails: true,
                                      //   requestedBy: item.requestedBy,
                                      //   requestTitle: item.requestTitle,
                                      //   beneficiary: item.beneficiary,
                                      //   requestDetail: item.requestDetail,
                                      //   itemCount: item.itemCount,
                                      //   dateSubmitted: item.dateSubmitted,
                                      //   lastUpdated: item.lastUpdated,
                                      //   supportingDoc: item.supportingDoc,
                                      //   approvers: item.approvers,
                                      //   assetItems: item.assetItems,

                                      itemName,
                                      initiatedBy,
                                      manufacturer,
                                      supplier,
                                      serialNumber,
                                      unitCost,
                                      disposalNote,
                                      disposalCategory,
                                      description,
                                      dateProfiled,
                                      dateInitiated,
                                      dateDelivered,
                                      assetStatus,
                                      assetCategory,
                                      assetApprovalStatus,

                                      assignmentHistory,
                                      assetSupportingDoc,
                                      approvers,
                                      disposalSupportingDoc,
                                      currentValue
                                    });
                                  }}>
                                  <ImEye />
                                </button>
                                <ReactTooltip id='view-details' type='success' effect='solid'>
                                  <span>View Details</span>
                                </ReactTooltip>
                                {'                   '}
                                <button
                                  data-tip
                                  data-for='edit-btn'
                                  type='button'
                                  className='pointer td actions'
                                  onClick={() =>
                                    this.setState({
                                      visibility: true,
                                      id: item.id,
                                      type: 1,
                                      itemName: item.itemName,
                                    })
                                  }>
                                  <ImCheckmark />
                                </button>
                                <ReactTooltip id='edit-btn' type='warning' effect='solid'>
                                  <span>Approve Asset Entry</span>
                                </ReactTooltip>

                                <button
                                  data-tip
                                  data-for='del-btn'
                                  type='button'
                                  className='m-l-10 del pointer td actions'
                                  onClick={() =>
                                    this.setState({
                                      visibility: true,
                                      id: item.id,
                                      type: 0,
                                      itemName: item.itemName,
                                    })
                                  }>
                                  <ImCancelCircle />
                                </button>
                                <ReactTooltip id='del-btn' type='error' effect='solid'>
                                  <span>Decline Asset Entry</span>
                                </ReactTooltip>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.visibility && (
          <CommentForm
            onEnterAction={this.handleAction}
            loading={this.state.loading}
            type={this.state.type}
            itemName={this.state.itemName}
            validationStatus={this.state.validationStatus}
            validationCode={this.state.validationCode}
            closeModal={() => this.setState({ visibility: !this.state.visibility })}
          />
        )}

        {visibleDetails && (
          <ViewReviewDisposalDetails
            // requestedBy={this.state.requestedBy}
            // requestTitle={this.state.requestTitle}
            // beneficiary={this.state.beneficiary}
            // requestDetail={this.state.requestDetail}
            // itemCount={this.state.itemCount}
            // dateSubmitted={this.state.dateSubmitted}
            // lastUpdated={this.state.lastUpdated}
            // supportingDoc={this.state.supportingDoc}
            // approvers={this.state.approvers}
            // assetItems={this.state.assetItems}
            data={this.state}
            closeModal={() => this.setState({ visibleDetails: false })}
          />
        )}
      </>
    );
  }
}

export default AssetWithdrawalRequest;
