/* eslint-disable */
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import TextareaInput from '../inputs/TextareaInput';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { IoMdClose } from 'react-icons/io';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class ViewRequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    let data;

    if (this.props.pageName && this.props.pageName === 'Report') {
      data = this.props.data;
    } else {
      data = this.props;
    };

    const {
      requestedBy,
      requestTitle,
      beneficiary,
      requestDetail,
      itemCount,
      dateSubmitted,
      lastUpdated,
      supportingDoc,
      approvers,
      assetItems,
    } = data;

    return (
      <>
        <div className='overlay'>
          <IoMdClose className='close-btn pointer m-l-5' onClick={this.props.closeModal} />
          <div className='modal-box' style={{ width: '90%' }}>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Request Details</p>
              <p>{requestTitle} Information</p>
            </div>
            <div className='content'>
              <div className='w-100 bg-white'>
                <div className='flex flex-direction-column'>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Request Officer: </div>
                    <div className='w-80 bold-text'>{requestedBy || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Assignee: </div>
                    <div className='w-80 bold-text'>{beneficiary || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Request Detail: </div>
                    <div className='w-80 bold-text'>{requestDetail || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Item Count: </div>
                    <div className='w-80 bold-text'>{itemCount}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Submitted: </div>
                    <div className='w-80 bold-text'>{dateSubmitted || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Last Updated: </div>
                    <div className='w-80 bold-text'>{lastUpdated || ''}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Supporting Document</p>
            </div>
            {supportingDoc.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no supporting document</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {supportingDoc.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                            {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Items</p>
            </div>
            {assetItems.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no asset items</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {/* {assetItems.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div
                            className='col-2-4 padding no-margin bold-text'
                            style={{ fontWeight: 'bold' }}>
                            Category:
                          </div>
                          <div className='w-80 bold-text'>
                            {'    '}
                            {value.categoryName || ''}
                          </div>
                          <div
                            className='col-2-4 padding no-margin bold-text'
                            style={{ fontWeight: 'bold' }}>
                            Name:{' '}
                          </div>
                          <div className='w-80 bold-text'>
                            {'    '}
                            {value.itemName || ''}
                          </div>
                          <div
                            className='col-2-4 padding no-margin bold-text'
                            style={{ fontWeight: 'bold' }}>
                            Quantity:{' '}
                          </div>
                          <div className='w-80 bold-text'>
                            {'    '}
                            {value.quantity || ''}
                          </div>
                        </div>
                      );
                    })} */}
                    <div className='flex'>
                      <div
                        id='bordered-table'
                        className='table-view '
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '5px 15px',
                          ...this.props.overrideTableStyles,
                        }}>
                        <div
                          className='table-header'
                          style={{
                            backgroundColor: `${this.props.headerBGColor || '#D3FAD5'}`,
                            color: `${this.props.headerColor || '#43425D'}`,
                          }}>
                          <div className='table-row'>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Category Name</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Item Name</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Description</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Quantity</p>
                            </div>
                          </div>
                        </div>
                        <div className='table-body no-border'>
                          {assetItems.length === 0 ? (
                            <div>
                              <br />
                              <h3>
                                <i>There are no asset items</i>
                              </h3>
                            </div>
                          ) : (
                            <>
                              {assetItems.map((item, idx) => (
                                <div className='table-row no-border' key={idx}>
                                  <div
                                    className='td font-sz-13'
                                    style={{
                                      ...tableBorder,
                                    }}>
                                    {item.categoryName || ''}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.itemName || ''}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.itemDescription || ''}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.quantity || ''}
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewRequestDetails;
