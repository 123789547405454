import React, { useState, useRef } from 'react';
import DisposalCAtegoryForm from './DisposalCAtegoryForm';
import DepreciationRatioForm from './DepreciationRatioForm';
//import SettingTable from '../layouts/SettingTable';
import AssetCategoryForm from './AssetCategoryForm';
import AppreciationRatioForm from './AppreciationRatioForm';
import AssetProfilingApprovalForm from './AssetProfilingApprovalForm';
import DisposalApprovalSequenceForm from './DisposalApprovalSequenceForm';
// import UserManagementForm from './UserManagementForm';
import AssignAssetApprovalSequenceForm from './AssignAssetApprovalSequenceForm';

import AssetProfiler from './AssetReviewProfiler';
import AssignRequest from './AssetReviewRequest';
import AssignAssetRequest from './AssignAssetRequest';
import AssignExistingAsset from './AssignExistingAsset';
import AssignWithdrawal from './AssignWithdrawal';
import AssetAssignWithdrawal from './AssignAssetWithdrawal';
import WithdrawalCategory from './WithdrawalCategory';
// WithdrawalCategory
const SettingStrip = ({
  title,
  pendingProfilerRecordLength,
  pendingRequestRecordLength,
  pendingWithdrawalLength,
  pendingAssetWithdrawalRecordLength,
  searchVal,
  ...props
}) => {
  const [active, setActive] = useState(props.active || '');
  const [height, setHeight] = useState(props.height || '0px');

  const oldContent = useRef(null);
  const toggleAccordion = () => {
    setActive(active === '' ? 'active' : '');
    setHeight(active === 'active' ? '0px' : '100%');
  };

  const recordProfilerLength = (len) => {
    pendingProfilerRecordLength(len);
  };

  const recordRequestLength = (len) => {
    pendingRequestRecordLength(len);
  };

  const recordAssetWithdrawalLength = (len) => {
    pendingAssetWithdrawalRecordLength(len);
  };

  // pendingAssetWithdrawalRecordLength

  const recordWithdrawalLength = (len) => {
    pendingWithdrawalLength(len);
  };

  const categoryUpdate = (value) => {
    // console.log(value, 'value was here');

    props.updateRatioCategory(value);
  };
  return (
    <div className={`accordion__section ${props.className && props.className}`}>
      <button className={`accordion p-15 ${active}`} onClick={toggleAccordion}>
        <p className='accordion__title'>{title}</p>
      </button>
      <div
        ref={oldContent}
        className='accordion__content'
        style={{
          maxHeight: `${height}`,
          backgroundColor: props.bodyBGColor,
        }}>
        {props.bodyType ? (
          <div className='w-100 p-5'>
            <div className='row space-between'>
              {props?.bodyType === 'item/asset' ? (
                <AssetCategoryForm className='col-4' categoryUpdate={categoryUpdate} />
              ) : props?.bodyType === 'disposal' ? (
                <DisposalCAtegoryForm className='col-4' />
              ) : props?.bodyType === 'withdrawalCategory' ? (
                <WithdrawalCategory className='col-4' />
              ) : props?.bodyType === 'depreciationratio' ? (
                <DepreciationRatioForm className='col-4' loading={props.loading} />
              ) : props?.bodyType === 'appreciationratio' ? (
                <AppreciationRatioForm className='col-4' loading={props.loading} />
              ) : props?.bodyType === 'approvalsequence' ? (
                <AssetProfilingApprovalForm className='col-4' />
              ) : props?.bodyType === 'disposalapproval' ? (
                <DisposalApprovalSequenceForm className='col-4' />
              ) : props?.bodyType === 'assignassetapproval' ? (
                <AssignAssetApprovalSequenceForm className='col-4' />
              ) : props?.bodyType === 'assetprofiler' ? (
                <AssetProfiler recordProfilerLength={recordProfilerLength} className='col-4' />
              ) : props?.bodyType === 'assetrequest' ? (
                <AssignRequest recordRequestLength={recordRequestLength} className='col-4' />
              ) : props?.bodyType === 'assetassignrequest' ? (
                <AssignAssetRequest recordRequestLength={recordRequestLength} className='col-4' />
              ) : props?.bodyType === 'assignexistingasset' ? (
                <AssignExistingAsset   searchVal = {searchVal} recordRequestLength={recordRequestLength} className='col-4' />
              ) : props?.bodyType === 'assetassignwithdrawal' ? (
                <AssetAssignWithdrawal
                  recordAssetWithdrawLength={recordAssetWithdrawalLength}
                  className='col-4'
                  searchVal = {searchVal}
                />
              ) : props?.bodyType === 'assetwithdrawal' ? (
                <AssignWithdrawal
                  recordWithdrawalLength={recordWithdrawalLength}
                  className='col-4'
                />
              ) : null}
            </div>
          
          </div>
        ) : (
          'Setting'
        )}
      </div>
    </div>
  );
};

export default SettingStrip;
