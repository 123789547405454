/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { BsSearch } from 'react-icons/bs';

const FormSearchInput = (props) => {
  // const [suggested, setSuggested] = useState([]);
  const reliefOfficers = props.searchValue && props.searchValue;

  console.log('search input props', reliefOfficers);
  return (
    <div className={`${props.className} form-group form-group-search`}>
      <label htmlFor={props.name}>{props.label}</label>
      <BsSearch
        style={{
          color: `${!props.color ? 'black' : props.color}`,
          position: 'absolute',
          top: `${props.formState ? '20px' : '30px'}`,
        }}
      />
      {/* {console.log('set State value in searchInput :', props.formState)} */}
      <input
        type='text'
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        // onmouseleave={() => props.onChange(false)}
        // readOnly={props.readOnly}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        // disabled={props.disabled}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        required={props.required}
        className='sfield'
        // onInput={() => searchInput(value)}
      />
      {props.open && (

      <div id='suggestions' className='suggestions'>
        {reliefOfficers &&
          reliefOfficers.map((suggestion, idx) => (
            <div
              key={idx}
              className='suggestion-item'
              onClick={() => {
                props.setSuggestions(suggestion);
                // props.changeOpen(false);
              }}>
              {/* {`${suggestion.firstName} ${suggestion.lastName}`} */}
              {props.displayType === 'Asset Entry Report'
                ? `${suggestion.name}`
                : `${suggestion.name}`
}
            </div>
          ))}
      </div>
      )}
    </div>
  );
};

export default FormSearchInput;
