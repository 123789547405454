/*eslint-disable */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import ButtonLoader from '../../assets/svg/loading.svg';
import moment from 'moment';
import FormSearchInput from '../inputs/FormSearchInput';
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
import Select from 'react-select';
import { Post, Get, Delete, Put, GetwithQueryString } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import { GiTrashCan } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
import data from '../../data';
import { GiRecycle } from 'react-icons/gi';
import Cookies from 'js-cookie';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import LoadingSpinner from '../../resources/loadingSpinner';
const bottomBorder = {
  borderBottom: '.5px solid rgba(99,99,99,.6)',
};
const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class AssignAssetApprovalSequenceForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      approvalId: '',
      sequence: '',
      button: 'Save Record',
      records: [],
      employees: [],
      employee: 0,
      employeeName: '',
      bgColor: '',
      requestId: 0,
      approval: [],
      loading: false,
      bgColor: 'white',
      loadTable: false,
      loadForm: false,
      category: 'Request',
      sequence: 0,
      roleId: 0,
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEmpOption = (approvalId) => {
    // this.setState({
    //   approvalId: e.target.options[e.target.selectedIndex].text,
    //   [app.target.name]: e.target.value,
    //   approvalId

    // }, () => console.log('option selected', this.state.approvalId))
    this.setState({ approvalId }, () => console.log('option selected', this.state.approvalId));
  };

  // getEmployee = (data) => {
  //   console.log(data);

  //   if (this._isMounted) {
  //     let _records = data
  //     this.setState({ employee: _records, loading: false });

  //   }
  // };

  getEmployee = (data) => {
    // let _title = data

    // let records = [];

    // if (_title != "") {
    //   _title.forEach(function (l, index) {
    //     let sn = { value: l.id, label: l.firstName + ' ' + l.lastName, };
    //     records.push(sn)
    //   })
    // }
    // this.setState({ employee: records, loading: false });

    // console.log('Logging employees data ', data);

    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];

      this.setState({ employees: _records, loading: false });
    }
  };

  getApproval = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];

      this.setState({ approval: _records, loadTable: false });
    }
  };

  getRoleList = (data) => {
    this.setState({
      employees: data,
    });
  };

  componentDidMount() {
    this.setState({ loadTable: true });
    this._isMounted = true;
    try {
      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);
      Get(URLAPI.approval.GetRoles, this.getRoleList);
    } catch (err) {
      this.setState({ loadTable: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateForm = () => {
    const { sequence, roleId } = this.state;

    if (!roleId) return { status: true, message: 'Please, Select a role' };
    if (!sequence) return { status: true, message: 'Please, Enter Approval Sequence' };
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loadTable: true,
      loadForm: true,
    });

    const { sequence, roleId, category, id } = this.state;

    let error_data = this.validateForm();

    if (error_data.status && error_data.status === true) {
      this.setState({
        loadTable: false,
        loadForm: false,
      });
      return errorMessage(error_data.message);
    }
    if (error_data === false) {
      let request;

      if (this.state.button === 'Save Record') {
        request = {
          roleId: Number(roleId),
          category,
          sequence: Number(sequence),
        };
        this.onSubmitSave(request);
      } else if (this.state.button === 'Update') {
        request = {
          id,
          roleId: Number(roleId),
          category,
          sequence: Number(sequence),
        };
        this.onSubmitUpdate(request);
      }
    }
  };

  onSubmitSave = (data) => {
    Post(data, URLAPI.approval.Post, this.onSubmitAfterSave);
  };

  onSubmitAfterSave = (data) => {
    if (data.code === 1) {
      successMessage(data.message);

      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);

      this.setState({
        roleId: 0,
        role: '',
        sequence: 0,
        loadForm: false,
      });
    }
    if (data.code === 0) {
      this.setState({
        roleId: 0,
        role: '',
        sequence: 0,
        loadTable: false,
        loadForm: false,
      });
      errorMessage(data.message);
    }
  };

  handleDeleteRecord(record) {
    deleteMessage(() => this.onDelete(record));
  }

  onDelete = (record) => {
    this.setState({ loadTable: true });
    Delete(URLAPI.approval.Delete + '/' + record.id, this.onAfterDelete);
  };

  onAfterDelete = (res) => {
    if (res.code === 1) {
      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);
      successMessage(res.message);

      this.setState({ loadTable: false });
    } else if (res.code === 1) {
      this.setState({ loadTable: false, visible: false });
      errorMessage(res.message);
    } else {
      this.setState({ loadTable: false });
      errorMessage(res.message);
    }
  };

  onEdit = (item) => {
    if (item) {
      const { roleId, sequence, id, role } = item;
      this.setState({
        roleId,
        id,
        button: 'Update',
        sequence,
        role,
        bgColor: '#c2f542',
      });
    }
  };

  onSubmitUpdate = (request) => {
    if (request.itemCategoryId !== 0) {
      Put(request, `${URLAPI.approval.Put}/${request.id}`, this.onSubmitAfterUpdate);
    }
  };

  onSubmitAfterUpdate = (data) => {
    if (data.code === 1) {
      successMessage(data.message);
      this.setState({ loadTable: true });
      Get(URLAPI.approval.GetApprovalList + '/' + this.state.category, this.getApproval);
      //commit to state
      this.setState({
        selectedRole: '',
        roleId: 0,
        sequence: 0,
        button: 'Save Record',
        loadForm: false,
        bgColor: 'white',
      });

      //this.handleCancel();
    } else if (data.code === 0) {
      this.setState({ loadTable: false, loadForm: false, button: 'Save Record', bgColor: 'white' });
      errorMessage(data.message);
    } else {
      errorMessage('Something went wrong while trying to update record Please, try again later.');
      this.setState({ loadTable: false, loadForm: false, button: 'Save Record', bgColor: 'white' });
    }
  };

  handleClear = () => {
    //clear all action
    this.setState({
      sequence: 0,
      role: '',
      button: 'Save Record',
      bgColor: 'white',
    });
  };

  handleStateChange = (name, value) => {
    this.setState({ [name]: value });
  };
  render() {
    const { sequence, button, loading, loadTable, loadForm } = this.state;
    return (
      <>
        <div className='row'>
          <div className='col-4'>
            <div
              className='form-group p-10 m-l-10 pd-b-0  h-10'
              style={{ background: this.state.bgColor }}>
              <label>select Role</label>
              <select
                className='w-100 m-b-10'
                style={{
                  fontSize: 13,
                }}
                name='roleId'
                // value={this.state.categoryId}
                onChange={(e) => this.handleChange(e)}>
                <option>{this.state.role}</option>
                {this.state.employees.map((res) => (
                  <option value={res.id}>{res.name}</option>
                ))}
              </select>
              <br />
              <TextInput
                label='sequence'
                value={sequence}
                name='sequence'
                type='number'
                color='rgba(112,112,112,.7)'
                onChange={(e) => this.handleChange(e)}
              />
              <div
                className='w-100 m-b-auto flex flex-v-center space-between m-t-10'
                style={{ alignSelf: 'flex-end' }}>
                <button
                  className='btn btn-large w-49 p-10'
                  type='button'
                  onClick={this.onSubmit}
                  disabled={loadForm}>
                  {button}{' '}
                  {loadForm && <img src={ButtonLoader} className='btn-loading' alt='Loading...' />}
                </button>
                <button
                  className='btn-large w-49 cancel-btn p-10'
                  type='button'
                  onClick={this.handleClear}
                  disabled={loadForm}>
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className='col-8'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#43425D'}`,
                  color: `${this.props.headerColor || 'white'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <GiRecycle fontSize='20px' />
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Title</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Approval Sequence</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Last Updated</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Updated By</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>

              {loadTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <>
                  {this.state.approval.length === 0 ? (
                    <div>
                      <br />
                      <h3>No request approval sequence created</h3>
                    </div>
                  ) : (
                    <div className='table-body no-border'>
                      {this.state.approval.map((item, i) => (
                        <div className='table-row no-border' key={item.id}>
                          <div
                            className='td left-text font-sz-22 pointer'
                            style={{ ...tableBorder }}>
                            {loadForm ? null : (
                              <>
                                <ReactTooltip id={`editTip-${i}`} place='bottom' effect='solid'>
                                  Edit Ratio
                                </ReactTooltip>
                                <GrEdit
                                  data-tip
                                  data-for={`editTip-${i}`}
                                  onClick={() => this.onEdit(item)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className='td font-sz-13'
                            style={{
                              ...tableBorder,
                            }}>
                            {item.role || 'empty role'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.sequence || 'empty sequence'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.lastUpdated || 'empty last updated'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.lastUpdatedBy || 'empty last updated by'}
                          </div>

                          <div className='td m-l-10 font-sz-22 pointer' style={{ ...tableBorder }}>
                            {button === 'Update' || loadForm ? null : (
                              <>
                                <ReactTooltip id={`requestDeleteTip-${i}`} place='bottom' effect='solid'>
                                  Delete
                                </ReactTooltip>
                                <GiTrashCan
                                  data-tip
                                  data-for={`requestDeleteTip-${i}`} 
                                  onClick={() => this.handleDeleteRecord(item)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AssignAssetApprovalSequenceForm;
