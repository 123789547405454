/*eslint-disable */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import CommentForm from './CommentForm';
import { GrDown, GrUp } from 'react-icons/gr';
import ButtonLoader from '../../assets/svg/loading.svg';
import moment from 'moment';
import FormSearchInput from '../inputs/FormSearchInput';
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
import Select from 'react-select';
import { Post, Get, Delete, Put, GetwithQueryString } from '../../utility/fetch';
import { Gets, } from '../../utility/FetchServices';
import { URLAPI } from '../../utility/ApiMethods';
import { GrEdit } from 'react-icons/gr';
import data from '../../data';
import { ImCheckmark, ImCancelCircle, ImEye } from 'react-icons/im';
import Cookies from 'js-cookie';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import ViewRequestDetails from '../layouts/ViewRequestDetails';
import LoadingSpinner from '../../resources/loadingSpinner';
import PureModal from 'react-pure-modal';
import { Multiselect } from 'multiselect-react-dropdown';
import AutoComplete from '../inputs/AutoComplete'
import notification from '../../utility/notification';


const bottomBorder = {
    borderBottom: '.5px solid rgba(99,99,99,.6)',
};
const tableBorder = {
    border: 'none',
    borderRight: '1px solid #0000000A',
    borderBottom: '1px solid #0000000A',
};

class AssetAssignRequest extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            searchText: null,
            visible: false,
            requestId: 0,
            category: '',
            initiatedreq: [],
            holder: [],
            beneficiary: [],
            details: {},
            request: false,
            mda: [],
            assetMDAId: 0,
            assetMDAName: "",
            tagNo: "",
            mdaEmployees:[]
        };
    }

    loadAssetList = (data) => {
        console.log(data)
        if (this._isMounted) {
            let _records = Array.isArray(data) ? data : [];
            this.setState({ initiatedreq: _records, loading: false });
            this.setState({ holder: _records, loading: false });
            this.props.recordRequestLength({
                loadingRequest: false,
                length: this.state.initiatedreq.length,
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({ loading: true });
        try {
            Get('api/assetassign/existing/pendingassignlist', this.loadAssetList);
            Get(`api/assetassign/mda/get`, this.loadMDA);
        } catch (err) {
            console.log(err);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {

        // if (prevState?.props?.searchVal.toUpperCase !== this?.props?.searchVal.toUpperCase) {
        if (prevProps?.searchVal !== this?.props?.searchVal) {
            console.log(this.state.holder)
            console.log(this.state.initiatedreq)
          // Write logic here.
           let val = this?.state?.holder?.filter((item)=>(
            item.assetCategory?.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) ||
            item.assetName?.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) ||
            item.manufacturer?.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) 
          ))
        this.setState({initiatedreq:val})
        // console.log(initiatedreq)
        // console.log(this.state.initiatedreq)
    
        }
        // console.log(this.state.initiatedreq)

    }

    onAssign = () => {
        const { assetMDAId, assetMDAName, beneficiary, tagNo, details } = this.state;
        // console.log(details)


        if (beneficiary.length > 1) {
             notification({
               title: 'ERROR',
               message: 'Please select only one staff',
               type: 'danger',
             });
            // errorMessage("Please select only one staff")
            return;
        }

        if (beneficiary.length === 0 || assetMDAId === 0) {
            console.log(details.serialNumber);
             notification({
               title: 'ERROR',
               message: 'Please select staff/ MDA as a beneficiary',
               type: 'danger',
             });
            // errorMessage("Please select staff/ MDA as a beneficiary");
            return;
        }
        this.setState({ assignloading: true });


        let assignee = assetMDAName;
        let userId = 0;
        let tagNumber = ""

        if (tagNo === "") {
            tagNumber = details.serialNumber;
            // tagNo = details.serialNumber;
            
        }
        // console.log(tagNumber);

        if (beneficiary.length > 0) {
            assignee = beneficiary[0].name;
            userId = beneficiary[0].id;
        }

        let d = new Date(); // today!
        let x = 5; // go back 5 days!
        let date = new Date(d.setDate(d.getDate() - x));

        Put(
          {
            id: details.id,
            userId,
            mdaId: parseInt(assetMDAId),
            assignee,
            tagNo: details.serialNumber,
            comment: ' ',
            dateAssigned: date,
          },
          'api/assetassign/existing/assign',
          this.handleAssignResult,
        );
        
    };

    handleAssignResult = async (res) => {
        if (res.code === 1) {
            successMessage(res.message);
            Get('api/assetassign/existing/pendingassignlist', this.loadAssetList);
        } else {
            errorMessage(res.message);
        }
        this.setState({ assignloading: false, visible: false, details:{}, assetMDAId:0, assetMDAName:"",beneficiary:[] });
    };

    searchStaff = (value) => {
        console.log(value)
        const name = value.trim();

        if (name === '') {
            return false;
        }
       let v = this.state.mdaEmployees.filter((item)=>
        item.name.toUpperCase().includes(name.toUpperCase())
        )
        // console.log(v)
        this.loadStaff(v)
        // Get(`api/request/searchapprovers/${name}`, this.loadStaff);
    };
    handleEmployeeChange = (e) => {
        let mdaName = this.state.mdaEmployees.filter((l)=>(
           e == l.id 
     
         ))
         console.log(mdaName[0])
         console.log(mdaName[0].name)
         console.log(e)
         // console.log(k)
         this.setState({
           currentMDAId:e,
           currentMDAname:mdaName[0].name,
           // [e.target.name]: e.target.value,
           loadPage: true,
         });
     
        //  Get(`api/report/onselect/mda/${e}`, this.getEmployees);
       };
       getEmployees = (data) => {
        console.log(data, 'employess data was here >>>>');
        this.setState({mdaEmployees:data})
    
        // const {
        //   reportCardCounts: { totalAssets, totalAssigned, totalDisposed, totalUnassigned },
        //   assetCategory,
        //   assetDisposalCategory,
        // } = data;
    
        // this.setState({
        //   assetCategory,
        //   assetDisposalCategory,
        //   totalAssets,
        //   totalAssigned,
        //   totalDisposed,
        //   totalUnassigned,
        //   loadPage: false,
        // });
      };
    

       handleMdaChange = (e) => {
        let mdaName = this.state.mda.filter((l)=>(
           e == l.id 
     
         ))
         console.log(mdaName[0])
         console.log(mdaName[0].name)
         console.log(e)
         // console.log(k)
         this.setState({
            assetMDAId:e,
           assetMDAName:mdaName[0].name,
           // [e.target.name]: e.target.value,
           loadPage: true,
         });

        //  /api/Employees/employees_by_mda
     
         Gets(`api/Employees/employees_by_mda?mdaid=${e}`, this.getEmployees);
        //  Get(`api/report/onselect/mda/${e}`, this.getMDA);
       };


    loadStaff = (res) => {
        console.log(res)
        this.setState({ staffs: res });
    };

    loadMDA = (res) => {
        console.log(res)
        this.setState({ mda: res });
    };

    render() {
        const { loading, staffs, visible, mda, details, assignloading } =
            this.state;
        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <div
                            id='bordered-table'
                            className='table-view '
                            style={{
                                borderCollapse: 'collapse',
                                borderSpacing: '25px 15px',
                                marginLeft: 10,
                                // marginRight: 10,
                                // border: 'none',
                                ...this.props.overrideTableStyles,
                            }}>
                            <div
                                className='table-header'
                                style={{
                                    backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                                    color: `${this.props.headerColor || '#43425D'}`,
                                }}>
                                <div className='table-row'>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Category</p>
                                    </div>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Asset Item</p>
                                    </div>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Manufacturer</p>
                                    </div>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Supplier</p>
                                    </div>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Asset Value</p>
                                    </div>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Tag/Serial Numberr</p>
                                    </div>
                                    <div className='th case title w-25' style={{ ...tableBorder }}>
                                        <p>Action</p>
                                    </div>
                                </div>
                            </div>

                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                                    <Loader type='ThreeDots' color='green' height={80} width={80} />
                                </div>
                            ) : (
                                <>
                                    {this.state.initiatedreq.length === 0 ? (
                                        <div style={{ marginLeft: 10 }}>
                                            <br />
                                            <h3>No Pending Asset Request</h3>
                                        </div>
                                    ) : (
                                        <div className='table-body no-border'>
                                            {this.state.initiatedreq.map((value, i) => {
                                                return (
                                                    <>
                                                        <div className='table-row no-border'>
                                                            <div
                                                                key={i}
                                                                className='td font-sz-13'
                                                                style={{
                                                                    ...tableBorder,
                                                                }}>
                                                                {value.assetCategory}
                                                            </div>
                                                            <div className='td font-sz-13' style={{ ...tableBorder }}>
                                                                {value.assetName}
                                                            </div>
                                                            <div className='td font-sz-13' style={{ ...tableBorder }}>
                                                                {value.manufacturer}
                                                            </div>
                                                            <div className='td font-sz-13' style={{ ...tableBorder }}>
                                                                {value.supplier}
                                                            </div>
                                                            <div className='td font-sz-13' style={{ ...tableBorder }}>
                                                                {value.assetValue}
                                                            </div>
                                                            <div className='td font-sz-13' style={{ ...tableBorder }}>
                                                                {value.serialNumber}
                                                            </div>
                                                            <div
                                                                className='td m-l-10 font-sz-22 pointer'
                                                                style={{ ...tableBorder }}>
                                                                <button
                                                                    data-tip
                                                                    data-for='view-details'
                                                                    type='button'
                                                                    className='pointer td actions'
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            visible: true,
                                                                            details: value
                                                                        })
                                                                    }>
                                                                    Assign Asset
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* Assign approvers */}
                {visible && (
                    <div class='col-6 m-t-10'>
                        <PureModal
                            header='Assign Asset to beneficiary'
                            width='500px'
                            footer={
                                <div>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                visible: false,
                                            })
                                        }>
                                        Cancel
                                    </button>
                                </div>
                            }
                            isOpen={this.state.visible}
                            closeButton='X'
                            draggable={true}
                            closeButtonPosition='bottom'
                            onClose={() => {
                                this.setState({
                                    visible: false,
                                });
                            }}>

<div>
                            <label>
                            <h3 className='m-l-10'>Select MDA</h3>
                                                  </label>
                                <div style={{border:'2px solid'}}>
                            
                            <AutoComplete onChange={(e) => this.handleMdaChange(e)}   name='currentMDAId'label={false} options={mda && mda.map((res) => ({
                            name:res.name,
                            value: res.id
                            })
                            )}/> 
                                                  </div>
                        </div>
                        <br />


                            <div class='form-group'>
                                <label>
                                    <h3><b>Search for staff</b></h3>
                                </label>
                                <Multiselect
                                    options={staffs}
                                    displayValue='name'
                                    placeholder=''
                                    muiltple={false}
                                    style={{
                                        chips: {
                                            background: 'gray',
                                            'white-space': 'normal',
                                        },
                                        multiselectContainer: {
                                            color: 'gray',
                                            'background-color': 'white',
                                            'margin-bottom': '10px',
                                            'white-space': 'normal',
                                        },
                                    }}
                                    //loading
                                    emptyRecordMsg='Search for staff'
                                    onSearch={(val) => {
                                        this.searchStaff(val);
                                    }}
                                    onSelect={(selectedList, selectedItem) => {
                                        this.setState({ beneficiary: selectedList });
                                    }}
                                    onRemove={(selectedList, removedItem) => {
                                        const modulesList = this.state.beneficiary.filter((item) => item.id !== removedItem.id);
                                        this.setState({ beneficiary: modulesList });
                                    }}
                                />
                            </div>

                        
                      

                            {/* <div style={{border:'2px solid'}}>
                        
                        <AutoComplete onChange={(e) => this.handleEmployeeChange(e)}   name='mdaEmployees'label={false} options={this.state.mdaEmployees && this.state.mdaEmployees.map((res) => ({
                        name:res.name,
                        value: res.id
                        })
                        )}/> 
                      </div> */}

                            {/* <div class='form-group'>
                                <label>
                                    <h3><b>Select MDA </b></h3>
                                </label>
                                <select
                                    className='w-100 h-100 m-b-8'
                                    style={{
                                        fontSize: 15,
                                        backgroundColor: '#d5ffd5',
                                    }}
                                    name='assetMDA'
                                    onChange={(e) => this.setState({ assetMDAId: e.target.value, assetMDAName:e.target.selectedOptions[0].text })}>
                                        <option value=""></option>
                                    {mda && mda.map((res) => <option value={res.id}>{res.name}</option>)}
                                </select>
                            </div> */}
                            <br />
                            <div className="form-group">
                                <TextInput
                                    className='w-100 m-b-10'
                                    name='tagNo'
                                    label='Enter Tag No'
                                    type='text'
                                    value={details?.serialNumber || ""}
                                    onChange={(e) => this.setState({ tagNo: details?.serialNumber || e.target.value })}
                                    color='rgba(112,112,112,.7)'
                                />
                                <br />
                            </div>
                            {assignloading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                                    <Loader type='ThreeDots' color='green' height={80} width={80} />
                                </div>
                            ) : (
                                <div className='w-100 m-t-10 flex flex-v-center flex-h-center space-between'>
                                    <button
                                        className='btn btn-large w-100'
                                        type='button'
                                        onClick={() => { this.onAssign() }}>
                                        Assign
                                    </button>
                                </div>
                            )}
<div className="form-group">

</div>
                        </PureModal>
                    </div>
                )}
            </>
        );
    }
}

export default AssetAssignRequest;
