/* eslint-disable */
import React, { Component } from 'react';
import { URLAPI } from '../../utility/ApiMethods';
import ReactTooltip from 'react-tooltip';
import ViewRequestDetails from '../layouts/ViewRequestDetails';
import LocationDetails from '../layouts/LocationDetails';
import DashboardRequestCard from '../layouts/DashboardRequestCard';
import LoadingSpinner from '../../resources/loadingSpinner';
import moment from 'moment';
import { formatDateforPicker } from '../../utility/dateTime';
import SearchInput from '../inputs/SearchInput';

import Greeting from '../layouts/Greeting';
import TransactionCard from '../layouts/TransactionCard';
import { FaMailBulk, FaSave, FaSchool, FaUserTie } from 'react-icons/fa';
import { BsPerson, BsTrash2 } from 'react-icons/bs';
import { MdAssignment } from 'react-icons/md';
import { AiOutlineProfile, AiOutlineFileSync } from 'react-icons/ai';
import { GoRequestChanges } from 'react-icons/go';
import { FcApproval } from 'react-icons/fc';
import TablePaginationActions from '../common/TablePaginationActions';

import { Get } from '../../utility/fetch';

import ViewProfilerDetails from '../layouts/ViewProfilerDetails';
import UnassignedRequestDetails from '../layouts/UnassignedRequestDetails';
import ViewDisposalDetails from '../layouts/ViewDisposalDetails';
import AllProfiledAssetReport from '../layouts/AllProfiledAssetReport';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class Dashboard extends Component {
  constructor(history) {
    super(history);
    this.state = {
      isLoading: false,
      cardValues: {},
      details: [],
      errorMsg: '',
      startDate: '',
      endDate: '',
      searchText: '',
      selectedDate: 'All Cases',
      calendarDate: new Date(),
      transactionHistory: [],
      transaction: [],
      requestcount: '',
      requestCategory: '',
      initiatedCategory: '',
      intiatedCount: '',
      approved: '',
      declined: '',
      pendingCategory: '',
      pendingCount: '',
      totalSavedCategory: '',
      totalSavedCount: '',
      searchText: null,
      // pagination state value
      rowsPerPage: 10,
      page: 0,
      searchVar: '',

      pendingAssetProfilingOriginal: [],
      pendingAssetRequestOriginal: [],
      pendingUnassignedAssetRequestOriginal: [],
      pendingAssetDisposalOriginal: [],

      pendingAssetProfiling: [],
      pendingAssetRequest: [],
      pendingUnassignedAssetRequest: [],
      pendingAssetDisposal: [],
      selectedType: 'pendingAssetProfiling',
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ ...this.state, page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ ...this.state, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDate = async (e) => {
    const { startDate, endDate, selectedDate, searchText } = this.state;
    const { value } = e.target;
    selectedDate(value);
    searchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        startDate(formatDateforPicker(new Date()));
        endDate(formatDateforPicker(new Date()));
        break;
      case 'This Week':
        // get meetings for this week
        startDate(formatDateforPicker(moment().startOf('week').toDate()));
        endDate(formatDateforPicker(moment().endOf('week').toDate()));
        break;
      case 'This Month':
        // get meetings for this month
        startDate(formatDateforPicker(moment().startOf('month').toDate()));
        endDate(formatDateforPicker(moment().endOf('month').toDate()));
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        startDate(formatDateforPicker(this.state.calendarDate));
        endDate(formatDateforPicker(this.state.calendarDate));
        break;
      case 'All Cases':
        // get meetings for the day selected in the calendar
        startDate('');
        endDate('');
        break;
      default:
        break;
    }
  };
  onSearch = (e) => {
    // console.log(e.target.value)
    this.setState({ searchVar: e.target.value }, () => {
      console.log(this.state.searchVar);
    });
  };

  getCardValues = (data) => {
    console.log('login data', data);

    try {
      this.setState({
        loading: false,
        requestCategory: data[0].category,
        requestCount: data[0].no,
        initiatedCategory: data[1].category,
        intiatedCount: data[1].no,
        pendingCategory: data[2].category,
        pendingCount: data[2].no,
        totalSavedCategory: data[3].category,
        totalSavedCount: data[3].no,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getTransaction = (data) => {
    let _transact = Array.isArray(data) ? [...data] : [];
    this.setState({ transaction: _transact });
  };

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    Get('api/dashboard/cards', this.getDashboard);
  }

  getDashboard = (data) => {
    console.log(data, 'dashboard data was here >>>');
    // let _transact = Array.isArray(data) ? [...data] : [];
    const {
      pendingAssetProfiling,
      pendingAssetRequest,
      pendingUnassignedAssetRequest,
      pendingAssetDisposal,
    } = data;

    this.setState({
      pendingAssetProfiling,
      pendingAssetRequest,
      pendingUnassignedAssetRequest,
      pendingAssetDisposal,
      isLoading: false,

      pendingAssetProfilingOriginal: data.pendingAssetProfiling,
      pendingAssetDisposalOriginal: data.pendingAssetDisposal,
      pendingUnassignedAssetRequestOriginal: data.pendingUnassignedAssetRequest,
    });

    console.log(pendingAssetProfiling);
  };

  onSearch = (e) => {
    console.log(e.target.value);
    this.setState({ searchVar: e.target.value }, () => {
      console.log(this.state.searchVar);
      if (this.state.selectedType == 'pendingAssetProfiling') {
        this.setState(
          {
            pendingAssetProfiling: this.state.pendingAssetProfilingOriginal?.filter((f) =>
              f?.profiledBy?.toUpperCase()?.includes(this.state.searchVar?.toUpperCase()),
            ),
            pendingAssetDisposal: this.state.pendingAssetDisposalOriginal,
            pendingUnassignedAssetRequest: this.state.pendingUnassignedAssetRequestOriginal,
          },
          () => {
            console.log(this.state.pendingAssetProfiling);
          },
        );
      }
      if (this.state.selectedType == 'pendingAssetDisposal') {
        this.setState(
          {
            pendingAssetDisposal: this.state.pendingAssetDisposalOriginal?.filter((f) =>
              f?.initiatedBy?.toUpperCase()?.includes(this.state.searchVar?.toUpperCase()),
            ),
            pendingAssetProfiling: this.state.pendingAssetProfilingOriginal,
            pendingUnassignedAssetRequest: this.state.pendingUnassignedAssetRequestOriginal,
          },
          () => {
            console.log(this.state.pendingAssetProfiling);
          },
        );
        console.log(this.state.pendingAssetDisposalOriginal);
      }
      if (this.state.selectedType == 'pendingUnassignedAssetRequest') {
        this.setState(
          {
            pendingUnassignedAssetRequest: this.state.pendingUnassignedAssetRequestOriginal?.filter(
              (f) => f?.beneficiary?.toUpperCase()?.includes(this.state.searchVar?.toUpperCase()),
            ),
            pendingAssetProfiling: this.state.pendingAssetProfilingOriginal,
            pendingAssetDisposal: this.state.pendingAssetDisposalOriginal,
          },
          () => {
            console.log(this.state.pendingUnassignedAssetRequest);
          },
        );

        console.log(this.state.pendingUnassignedAssetRequestOriginal);
      }
    });

    // ;
    // ;
    // ;
  };

  render() {
    const {
      isLoading,
      errorMsg,
      requestCategory,
      requestCount,
      initiatedCategory,
      intiatedCount,
      pendingCategory,
      pendingCount,
      totalSavedCategory,
      totalSavedCount,
      transaction,
      page,
      rowsPerPage,

      pendingAssetProfiling,
      pendingAssetRequest,
      pendingUnassignedAssetRequest,
      pendingAssetDisposal,
      selectedType,

      pendingAssetRequestVisible,
      pendingAssetProfilingVisible,
      pendingUnassignedAssetRequestVisible,
      pendingAssetDisposalVisible,
    } = this.state;

    const tableData =
      rowsPerPage > 0
        ? transaction.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : transaction;

    return (
      <>
        <div className='w-100'>
          {isLoading && <LoadingSpinner />}
          <div className='w-100 flex flex-v-center space-between'>
            <Greeting />
            <LocationDetails />
          </div>

          <div className='flex wrap row2'>
            <div className='col-3 no-margin'>
              <DashboardRequestCard
                header='Pending'
                id={1}
                message={'Click to open Asset Entry information'}
                headerb='Asset Entry'
                onClick={() =>
                  this.setState({
                    selectedType: 'pendingAssetProfiling',
                    searchVar: '',
                  })
                }
                header2={
                  isLoading ? '...' : this.state.pendingAssetProfilingOriginal?.length || '0'
                }
                icon={<AiOutlineProfile fontSize='30px' />}
              />
            </div>
            {/* <div className='col-3 no-margin'>
              <DashboardRequestCard
                header='Pending'
                id={2}
                message={'Click to open Asset Request information'}
                headerb='Asset Request'
                onClick={() => this.setState({ selectedType: 'pendingAssetRequest' })}
                header2={isLoading ? '...' : pendingAssetRequest.length || '0'}
                icon={<GoRequestChanges fontSize='30px' />}
              />
            </div> */}
            <div className='col-3 no-margin'>
              <DashboardRequestCard
                header='Pending'
                id={3}
                message={'Click to open Asset Disposal information'}
                headerb='Asset Disposal'
                onClick={() =>
                  this.setState({
                    selectedType: 'pendingAssetDisposal',
                    searchVar: '',
                  })
                }
                header2={isLoading ? '...' : this.state.pendingAssetDisposalOriginal?.length || '0'}
                icon={<BsTrash2 fontSize='30px' />}
              />
            </div>
            <div className='col-3 no-margin'>
              <DashboardRequestCard
                header='Pending'
                id={4}
                message={'Click to open unassigned asset information'}
                headerb='Unassigned Asset'
                onClick={() =>
                  this.setState({
                    selectedType: 'pendingUnassignedAssetRequest',
                    searchVar: '',
                  })
                }
                header2={
                  isLoading
                    ? '...'
                    : this.state.pendingUnassignedAssetRequestOriginal?.length || '0'
                }
                icon={<MdAssignment fontSize='30px' />}
              />
            </div>
          </div>

          <br />
          <input
            type='search'
            placeholder='search'
            onChange={this.onSearch}
            className=' flex flex-v-center search-input m-b-5 p-10'
          />

          <div className='w-100 m-t-20'>
            {selectedType === 'pendingAssetRequest' ? (
              <div className='w-100 m-t-20'>
                <div
                  className='w-100 flex space-between m-t-20'
                  style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                  <p>Asset Request</p>
                </div>

                <div className='flex'>
                  <div
                    id='bordered-table'
                    className='table-view '
                    style={{
                      borderCollapse: 'collapse',
                      borderSpacing: '5px 15px',
                      // border: 'none',
                      ...this.props.overrideTableStyles,
                    }}>
                    <div
                      className='table-header'
                      style={{
                        backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                        color: `${this.props.headerColor || '#43425D'}`,
                      }}>
                      <div className='table-row'>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Assignee</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Requests Title</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Currently With</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Last Updated</p>
                        </div>
                      </div>
                    </div>

                    <div className='table-body no-border'>
                      {!isLoading && pendingAssetRequest.length === 0 ? (
                        <>
                          <br />
                          <p>Asset Request is empty</p>
                        </>
                      ) : (
                        <>
                          {(this.state.rowsPerPage > 0
                            ? pendingAssetRequest.slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
                              )
                            : pendingAssetRequest
                          ).map((item, idx) => (
                            <>
                              <div
                                data-tip
                                data-for={`${item.id}-open-modal`}
                                className='table-row no-border'
                                style={{ cursor: 'pointer' }}
                                key={idx}
                                onClick={() =>
                                  this.setState({
                                    pendingAssetRequestVisible: true,
                                    requestedBy: item.requestedBy,
                                    requestTitle: item.requestTitle,
                                    beneficiary: item.beneficiary,
                                    requestDetail: item.requestDetail,
                                    itemCount: item.itemCount,
                                    dateSubmitted: item.dateSubmitted,
                                    lastUpdated: item.lastUpdated,
                                    supportingDoc: item.supportingDoc,
                                    approvers: item.approvers,
                                    assetItems: item.assetItems,
                                  })
                                }>
                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {item.beneficiary}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.requestTitle || ''}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.currentlyWith}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.lastUpdated}
                                </div>
                              </div>
                              <ReactTooltip id={`${item.id}-open-modal`} effect='solid'>
                                Open {item.requestTitle}
                              </ReactTooltip>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {pendingAssetRequest?.length > 10 && (
                  <TablePaginationActions
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    count={pendingAssetRequest.length}
                    changeRowsPerPage={this.handleChange}
                  />
                )}

                {pendingAssetRequestVisible && (
                  <ViewRequestDetails
                    requestedBy={this.state.requestedBy}
                    requestTitle={this.state.requestTitle}
                    beneficiary={this.state.beneficiary}
                    requestDetail={this.state.requestDetail}
                    itemCount={this.state.itemCount}
                    dateSubmitted={this.state.dateSubmitted}
                    lastUpdated={this.state.lastUpdated}
                    supportingDoc={this.state.supportingDoc}
                    approvers={this.state.approvers}
                    assetItems={this.state.assetItems}
                    closeModal={() => this.setState({ pendingAssetRequestVisible: false })}
                  />
                )}
              </div>
            ) : selectedType === 'pendingUnassignedAssetRequest' ? (
              <div className='w-100 m-t-20'>
                <div
                  className='w-100 flex space-between m-t-20'
                  style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                  <p>Unassigned Asset Request</p>
                </div>

                <div className='flex'>
                  <div
                    id='bordered-table'
                    className='table-view '
                    style={{
                      borderCollapse: 'collapse',
                      borderSpacing: '5px 15px',
                      // border: 'none',
                      ...this.props.overrideTableStyles,
                    }}>
                    <div
                      className='table-header'
                      style={{
                        backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                        color: `${this.props.headerColor || '#43425D'}`,
                      }}>
                      <div className='table-row'>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Assignee</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Requested Title</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Requested Item</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Quantity</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Status</p>
                        </div>

                        {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Category</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Requested Item</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Last Updated</p>
                        </div> */}
                      </div>
                    </div>

                    <div className='table-body no-border'>
                      {!isLoading && pendingUnassignedAssetRequest.length === 0 ? (
                        <>
                          <br />
                          <p> Unassigned Asset is empty</p>
                        </>
                      ) : (
                        <>
                          {(this.state.rowsPerPage > 0
                            ? pendingUnassignedAssetRequest.slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
                              )
                            : pendingUnassignedAssetRequest
                          ).map((item, idx) => (
                            <>
                              <div
                                className='table-row no-border'
                                key={idx}
                                data-tip
                                data-for={`${item.id}-open-modal`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  // console.log(item, 'item >>>>  <<<<<');
                                  const {
                                    requestedBy,
                                    itemName,
                                    requestTitle,
                                    requestDetails,
                                    requestedItemStatus,
                                    itemDescription,
                                    beneficiary,
                                    categoryName,
                                    expectedEndDate,
                                    quantity,
                                    lastUpdated,
                                    supportingDoc,
                                    approvers,
                                    requestedItems,
                                  } = item;

                                  this.setState({
                                    pendingUnassignedAssetRequestVisible: true,
                                    requestedBy,
                                    itemName,
                                    requestTitle,
                                    requestDetails,
                                    requestedItemStatus,
                                    itemDescription,
                                    beneficiary,
                                    categoryName,
                                    expectedEndDate,
                                    quantity,
                                    lastUpdated,
                                    supportingDoc,
                                    approvers,
                                    requestedItems,
                                  });
                                }}>
                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {item.beneficiary}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.requestTitle}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.itemName}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.quantity}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.status}
                                </div>
                                {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.requestedItemStatus}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.lastUpdated}
                                </div> */}
                              </div>
                              <ReactTooltip id={`${item.id}-open-modal`} effect='solid'>
                                Open {item.requestTitle}
                              </ReactTooltip>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {pendingUnassignedAssetRequest?.length > 10 && (
                  <TablePaginationActions
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    count={pendingUnassignedAssetRequest.length}
                    changeRowsPerPage={this.handleChange}
                  />
                )}

                {pendingUnassignedAssetRequestVisible && (
                  <UnassignedRequestDetails
                    requestedBy={this.state.requestedBy}
                    itemName={this.state.itemName}
                    requestTitle={this.state.requestTitle}
                    requestDetails={this.state.requestDetails}
                    requestedItemStatus={this.state.requestedItemStatus}
                    itemDescription={this.state.itemDescription}
                    beneficiary={this.state.beneficiary}
                    categoryName={this.state.categoryName}
                    expectedEndDate={this.state.expectedEndDate}
                    quantity={this.state.quantity}
                    lastUpdated={this.state.lastUpdated}
                    supportingDoc={this.state.supportingDoc}
                    approvers={this.state.approvers}
                    requestedItems={this.state.requestedItems}
                    closeModal={() =>
                      this.setState({ pendingUnassignedAssetRequestVisible: false })
                    }
                  />
                )}
              </div>
            ) : selectedType === 'pendingAssetDisposal' ? (
              <div className='w-100 m-t-20'>
                <div
                  className='w-100 flex space-between m-t-20'
                  style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                  <p>Asset Disposal</p>
                </div>

                <div className='flex'>
                  <div
                    id='bordered-table'
                    className='table-view '
                    style={{
                      borderCollapse: 'collapse',
                      borderSpacing: '5px 15px',
                      // border: 'none',
                      ...this.props.overrideTableStyles,
                    }}>
                    <div
                      className='table-header'
                      style={{
                        backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                        color: `${this.props.headerColor || '#43425D'}`,
                      }}>
                      <div className='table-row'>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Initiated By</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Asset Name</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Currently With</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Status</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Last Updated</p>
                        </div>
                        {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Category</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Currently With</p>
                        </div> */}
                      </div>
                    </div>

                    <div className='table-body no-border'>
                      {!isLoading && pendingAssetDisposal.length === 0 ? (
                        <>
                          <br />
                          <p>Asset Disposal is empty</p>
                        </>
                      ) : (
                        <>
                          {(this.state.rowsPerPage > 0
                            ? pendingAssetDisposal.slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
                              )
                            : pendingAssetDisposal
                          ).map((item, idx) => (
                            <>
                              <div
                                className='table-row no-border'
                                data-tip
                                data-for={`${item.id}-open-modal`}
                                style={{ cursor: 'pointer' }}
                                key={idx}
                                onClick={() => {
                                  // console.log(item, 'asset item here >>')
                                  const {
                                    itemName,
                                    description,
                                    assetStatus,
                                    initiatedBy,
                                    manufacturer,
                                    serialNumber,
                                    supplier,
                                    unitCost,
                                    lastUpdatedBy,
                                    lastUpdated,
                                    dateDelivered,
                                    dateProfiled,
                                    assignmentHistory,
                                    assetSupportingDoc,
                                    currentValue,
                                  } = item;
                                  this.setState({
                                    pendingAssetDisposalVisible: true,
                                    itemName,
                                    itemDescription: description,
                                    itemStatus: assetStatus,
                                    profiledBy: initiatedBy,
                                    manufacturer,
                                    serialNumber,
                                    supplier,
                                    unitCost,
                                    lastUpdatedBy,
                                    lastUpdated,
                                    dateDelivered,
                                    dateProfiled,
                                    assignmentHistory,
                                    assetSupportingDoc,
                                    currentValue,
                                  });
                                }}>
                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {item.initiatedBy}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.itemName}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.currentlyWith}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.status}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.lastUpdated}
                                </div>
                                {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.assetCategory || 'null'}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.currentlyWith || 'null'}
                                </div> */}
                              </div>
                              <ReactTooltip id={`${item.id}-open-modal`} effect='solid'>
                                Open {item.itemName}
                              </ReactTooltip>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {pendingAssetDisposal?.length > 10 && (
                  <TablePaginationActions
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    count={pendingAssetDisposal.length}
                    changeRowsPerPage={this.handleChange}
                  />
                )}

                {pendingAssetDisposalVisible && (
                  <ViewDisposalDetails
                    itemName={this.state.itemName}
                    itemDescription={this.state.itemDescription}
                    itemStatus={this.state.itemStatus}
                    profiledBy={this.state.profiledBy}
                    manufacturer={this.state.manufacturer}
                    serialNumber={this.state.serialNumber}
                    supplier={this.state.supplier}
                    unitCost={this.state.unitCost}
                    currentValue={this.state.currentValue}
                    lastUpdatedBy={this.state.lastUpdatedBy}
                    lastUpdated={this.state.lastUpdated}
                    dateDelivered={this.state.dateDelivered}
                    dateProfiled={this.state.dateProfiled}
                    assignmentHistory={this.state.assignmentHistory}
                    assetSupportingDoc={this.state.assetSupportingDoc}
                    closeModal={() => this.setState({ pendingAssetDisposalVisible: false })}
                  />
                )}
              </div>
            ) : (
              <div className='w-100 m-t-20'>
                <div
                  className='w-100 flex space-between m-t-20'
                  style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                  <p>Asset Entry</p>
                </div>

                <div className='flex'>
                  <div
                    id='bordered-table'
                    className='table-view '
                    style={{
                      borderCollapse: 'collapse',
                      borderSpacing: '5px 15px',
                      ...this.props.overrideTableStyles,
                    }}>
                    <div
                      className='table-header'
                      style={{
                        backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                        color: `${this.props.headerColor || '#43425D'}`,
                      }}>
                      <div className='table-row'>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Entered By</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Item Name</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Quantity</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Status</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Currently With</p>
                        </div>
                        <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Last Updated</p>
                        </div>
                        {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                          <p>Quantity</p>
                        </div> */}
                      </div>
                    </div>

                    <div className='table-body no-border'>
                      {!isLoading && pendingAssetProfiling?.length === 0 ? (
                        <>
                          <br />
                        </>
                      ) : (
                        <>
                          {(this?.state?.rowsPerPage > 0
                            ? pendingAssetProfiling?.slice(
                                this.state?.page * this.state?.rowsPerPage,
                                this.state?.page * this.state?.rowsPerPage +
                                  this.state?.rowsPerPage,
                              )
                            : pendingAssetProfiling
                          )?.map((item, idx) => (
                            <>
                              <div
                                data-tip
                                data-for={`${item.id}-open-modal`}
                                style={{ cursor: 'pointer' }}
                                className='table-row no-border'
                                onClick={() => {
                                  // console.log(item, 'item was here')
                                  this.setState({
                                    pendingAssetProfilingVisible: true,
                                    profiledBy: item.profiledBy,
                                    itemName: item.itemName,
                                    description: item.description,
                                    supplier: item.supplier,
                                    manufacturer: item.manufacturer,
                                    deliveryDate: item.deliveryDate,
                                    unitCost: item.unitCost,
                                    totalCost: item.totalCost,
                                    serialNumber: item.serialNumber,
                                    category: item.category,
                                    quantity: item.quantity,
                                    supportingDoc: item.supportingDoc,
                                    approvers: item.approvers,
                                    lastUpdated: item.lastUpdated,
                                  });
                                }}
                                key={idx}>
                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {item.profiledBy}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.itemName || 'null'}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.quantity}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.status}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.currentlyWith}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.lastUpdated}
                                </div>
                                {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.quantity}
                                </div> */}
                              </div>
                              <ReactTooltip id={`${item.id}-open-modal`} effect='solid'>
                                Open {item.itemName}
                              </ReactTooltip>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {pendingAssetProfiling?.length > 10 && (
                  <TablePaginationActions
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    count={pendingAssetProfiling.length}
                    changeRowsPerPage={this.handleChange}
                  />
                )}

                {pendingAssetProfilingVisible && (
                  <ViewProfilerDetails
                    profiledBy={this.state.profiledBy}
                    itemName={this.state.itemName}
                    description={this.state.description}
                    supplier={this.state.supplier}
                    manufacturer={this.state.manufacturer}
                    deliveryDate={this.state.deliveryDate}
                    unitCost={this.state.unitCost}
                    totalCost={this.state.totalCost}
                    serialNumber={this.state.serialNumber}
                    category={this.state.category}
                    quantity={this.state.quantity}
                    supportingDoc={this.state.supportingDoc}
                    approvers={this.state.approvers}
                    lastUpdated={this.state.lastUpdated}
                    closeModal={() => this.setState({ pendingAssetProfilingVisible: false })}
                  />
                )}
              </div>
            )}

            {isLoading && <div className='w-100 center-text p-20'>Loading...</div>}
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
