/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import PureModal from 'react-pure-modal';
import SettingStrip from '../layouts/SettingStrip';
import { Multiselect } from 'multiselect-react-dropdown';
import ReactTooltip from 'react-tooltip';
import { ImCheckmark, ImCancelCircle } from 'react-icons/im';
import { GrDown, GrUp } from 'react-icons/gr';
import { GiTrashCan } from 'react-icons/gi';
import Greeting from '../layouts/Greeting';
import LocationDetails from '../layouts/LocationDetails';
import SearchInput from '../inputs/SearchInput';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import notification from '../../utility/notification';
import { formatDateforPicker } from '../../utility/dateTime';
import { Get, Put } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import AssignAssetFormModal from '../layouts/AssignAssetFormModal';
import ColorController from '../../controllers/ColorController';
import Cookies from 'js-cookie';
import ViewRequestDetails from '../layouts/ViewRequestDetails';
import ViewWithdrawalDetails from '../layouts/ViewWithdrawalDetails';
import LoadingSpinner from '../../resources/loadingSpinner';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};
const alignLeft = {
  textAlign: 'left',
};

class AssignWithdrawal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedDate: 'All Cases',
      startDate: '',
      endDate: '',
      searchText: null,
      calendarDate: new Date(),
      visible: false,
      requestButton: 'Requests',
      approvedButton: 'Approved',
      initiatedreq: [],
      withdrawalList: [],
      requestId: 0,
      category: '',
      beneficiary: '',
      details: '',
      date: '',
      cost: '',
      title: '',
      request: false,
      withdrawal: false,
      assignedAssetItemId: [],
      loadingRequest: true,
      pendingRequestLength: 0,
      pendingWithdrawalLength: 0,
      searchVar:''
    };
    this.filterReviewsOnApprover = this.filterReviewsOnApprover.bind(this);
  }

  openModal = (item) => {
    this.setState({
      visible: true,
      // requestId: item.id,
      // beneficiary: item.beneficiary,
      // cost: item.cost,
      // category: item.category,
      // title: item.title,
      // details: item.details,
      // date: item.date,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  handleDate = async (e) => {
    const { value } = e.target;

    this.setState({
      selectedDate: value,
      calendarDate: value,
    });
    // searchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        this.setState({
          startDate: formatDateforPicker(new Date()),
          endDate: formatDateforPicker(new Date()),
        });

        break;
      case 'This Week':
        // get meetings for this week
        this.setState({
          startDate: formatDateforPicker(moment().startOf('week').toDate()),
          endDate: formatDateforPicker(moment().endOf('week').toDate()),
        });

        break;
      case 'This Month':
        // get meetings for this month
        this.setState({
          startDate: formatDateforPicker(moment().startOf('month').toDate()),
          endDate: formatDateforPicker(moment().endOf('month').toDate()),
        });
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        this.setState({
          startDate: formatDateforPicker(this.state.calendarDate),
          endDate: formatDateforPicker(this.state.calendarDate),
        });
        break;
      case 'All Cases':
        // get meetings for the day selected in the calendar
        this.setState({
          startDate: '',
          endDate: '',
        });
        break;
      default:
        break;
    }
  };

  setApproved = () => {
    console.log('clicked');
    this.setState({
      requestButton: 'Approved',
    });
  };

  getApproval = (data) => {
    console.log('logging initiate request data', data);

    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      console.log(_records, '_records');
      this.setState({ initiatedreq: _records });
    }
  };

  getWithdrawal = (data) => {
    console.log(data, 'withdrawal list data was here >>>>');
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      console.log(_records, '_records');
      this.setState({ withdrawalList: _records, loading: false });
    }
  };

  filterReviewsOnApprover = () => {
    this.setState({
      initiatedreq: this.state.initiatedreq.filter(
        (rec) => rec.id !== parseInt(this.state.requestId),
      ),
    });
    Get(URLAPI.requestApprovals.Get, this.getApproval);
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    try {
      Get('api/assetassign/pendingassignlist', this.getApproval);
      Get('api/assigneditems/withdrawallist', this.getWithdrawal);
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };
  returnRequestButtonState = () => {
    this.setState({
      requestButton: 'Requests',
    });
  };

  handleGetAvailableItems = (itemCategoryId) => {
    Get(`api/check/availableitems/${itemCategoryId}`, this.getItems);
  };

  getItems = (data) => {
    if (this._isMounted) {
      console.log(data, 'data was here today >>>');
      let _records = Array.isArray(data) ? data : [];
      console.log(_records, '_records');
      const multiSelectData = [];

      _records.forEach((element) => {
        multiSelectData.push({ name: element.itemName, id: element.id });
      });

      this.setState({ availableItems: multiSelectData, loadRequest: false });
    }
  };

  onWithdraw = () => {
    this.setState({ loadComment: true });
    const { comment } = this.state;

    Put(
      { comment },
      `api/assigneditems/withdraw?id=${this.state.itemWithdrawalId}`,
      this.handleWithdrawalResult,
    );
  };

  handleWithdrawalResult = (res) => {
    if (res.code === 1) {
      this.setState({
        visibleWithdrawalComment: false,
        loadComment: false,
        loading: true,
        comment: '',
      });
      successMessage(res.message);
      Get('api/assetassign/pendingassignlist', this.getApproval);
      Get('api/assigneditems/withdrawallist', this.getWithdrawal);
    } else {
      errorMessage(res.message);
      this.setState({ loadComment: false, visibleWithdrawalComment: false });
    }
  };

  onAssign = () => {
    this.setState({ loadComment: true });
    const { action, comment } = this.state;

    Put(
      {
        itemRequestId: this.state.itemRequestId,
        assignedAssetItemId: this.state.assignedAssetItemId,
        action,
        comment,
      },
      'api/assetassign/assign',
      this.handleAssignResult,
    );
  };

  handleAssignResult = async (res) => {
    if (res.code === 1) {
      this.setState({
        visibleComment: false,
        loadComment: false,
        loading: true,
        comment: '',
      });
      successMessage(res.message);
      Get('api/assetassign/pendingassignlist', this.getApproval);
      Get('api/assigneditems/withdrawallist', this.getWithdrawal);
    } else {
      errorMessage(res.message);
      this.setState({ loadComment: false, visibleComment: false });
    }
  };

  renderRequestTable = () => {
    if (this.state.requestButton === 'Requests')
      return (
        <>
          <div className='flex'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Requesting Officer</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Requests Title</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assignee</p>
                  </div>
                  {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Requesting Detail</p>
                  </div> */}
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Item Count</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Date Submitted</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>

              {this.state.loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <div className='table-body no-border'>
                  {this.state.initiatedreq.length === 0 ? (
                    <>
                      <div className='table-row no-border'>
                        <div
                          // key={i}
                          className='td font-sz-13'
                          style={{
                            ...tableBorder,
                          }}>
                          There are no Asset Request information
                        </div>
                      </div>
                    </>
                  ) : null}
                  {this.state.initiatedreq.map((value, i) => {
                    return (
                      <>
                        <div className='table-row no-border'>
                          <div
                            key={i}
                            className='td font-sz-13'
                            style={{
                              ...tableBorder,
                            }}>
                            {value.requestedBy}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {value.requestTitle}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {value.beneficiary}
                          </div>
                          {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.requestDetail}
                        </div> */}
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {value.itemCount}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {value.dateSubmitted}
                          </div>
                          <div className='td m-l-10 font-sz-22 pointer' style={{ ...tableBorder }}>
                            <button
                              style={{
                                backgroundColor:
                                  this.state.visibility === `${value.id}-open`
                                    ? '#44435D'
                                    : 'white',
                                // color: this.state.visibility === `${value.id}-open` ? 'white' : 'black'
                              }}
                              data-tip
                              data-for={`${value.id}-drop-btn`}
                              type='button'
                              className='pointer td actions'
                              onClick={() => {
                                console.log(value, 'value ID');
                                this.setState({
                                  visibility:
                                    this.state.visibility === `${value.id}-open`
                                      ? `${value.id}-close`
                                      : `${value.id}-open`,
                                  itemRequestId: value.id,
                                });
                              }}>
                              {this.state.visibility === `${value.id}-open` ? <GrUp /> : <GrDown />}
                            </button>
                            <ReactTooltip
                              id={`${value.id}-drop-btn`}
                              type={this.state.visibility === `${value.id}-open` ? 'error' : 'dark'}
                              effect='solid'>
                              {this.state.visibility === `${value.id}-open` ? (
                                <span>Close Asset Items</span>
                              ) : (
                                <span>Open Asset Items</span>
                              )}
                            </ReactTooltip>
                            {'                        '}
                            <button
                              className='btn'
                              onClick={() =>
                                this.setState({
                                  visibleRequestDetails: true,
                                  requestedBy: value.requestedBy,
                                  requestTitle: value.requestTitle,
                                  beneficiary: value.beneficiary,
                                  requestDetail: value.requestDetail,
                                  itemCount: value.itemCount,
                                  dateSubmitted: value.dateSubmitted,
                                  lastUpdated: value.lastUpdated,
                                  supportingDoc: value.supportingDoc,
                                  approvers: value.approvers,
                                  assetItems: value.assetItems,
                                })
                              }>
                              View Details
                            </button>
                          </div>
                        </div>

                        {this.state.visibility === `${value.id}-open` ? (
                          <>
                            <div
                              className='table-row no-border'
                              style={{ backgroundColor: '#44435D', color: 'white' }}>
                              <div
                                key={i}
                                className='td font-sz-13'
                                style={{
                                  ...tableBorder,
                                }}>
                                Item name
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>
                                Quantity
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>
                                Category
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>
                                Status
                              </div>
                              <div className='td font-sz-13' style={{ ...tableBorder }}>
                                Action
                              </div>
                            </div>

                            {value.assetItems.map((value, i) => (
                              <div
                                key={i}
                                className='table-row no-border'
                                style={{ backgroundColor: '#F0F0F0', color: 'black' }}>
                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {value.itemName}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {value.quantity}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {value.categoryName}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {value.requestedItemStatus}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {value.requestedItemStatus === 'UnAssigned' ? (
                                    <button
                                      className='btn'
                                      onClick={() => {
                                        this.setState({
                                          visible: !this.state.visible,
                                          loadRequest: true,
                                          itemQuantity: value.quantity,
                                        });
                                        this.handleGetAvailableItems(value.itemCategoryId);
                                      }}>
                                      Assign
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </>
      );

    if (this.state.requestButton === 'Approved') {
      return (
        <>
          <div className='flex'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Request Officer</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assignee</p>
                  </div>
                  {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>MDA</p>
                  </div> */}
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Category</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Details</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Last Updated</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Status</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>
              <div className='table-body no-border'>
                {this.state.initiatedreq.map((item, idx) => (
                  <div className='table-row no-border' key={idx}>
                    <div
                      className='td font-sz-13'
                      style={{
                        ...tableBorder,
                      }}>
                      {Cookies.get('_Fullname') || Cookies.get('fullname')}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.beneficiary}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.category}
                    </div>
                    {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.mda}
                    </div> */}
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.details}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.date}
                    </div>
                    <div className='td font-sz-13 ' style={{ ...tableBorder }}>
                      <div className='flex'>
                        <div className='flex m-r-auto'>{item.status || 'Approved'}</div>
                        <div
                          className=''
                          style={{
                            width: '1.1rem',
                            backgroundColor: `${
                              item.status ? ColorController.getColor(item.status) : '#1C811C'
                            }`,
                            borderRadius: '50%',
                            height: '1.1rem',
                          }}></div>
                      </div>
                    </div>
                    {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                  <div className='flex'>
                    <FaEnvelopeOpenText fontSize='18px' />
                    <a>supporting</a>
                  </div>
                </div> */}
                    <div className='td font-sz-13'>
                      <button className='btn btn w-100' onClick={this.onEdit}>
                        View Approval
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  // asset withdrawal
  renderWithDrawalTable = () => {
    if (this.state.requestButton === 'Requests')
      return (
        <>
          <div className='flex'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assigned To</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assigned By</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Date Assigned</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assignee</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Item Name</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Serial Number</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Item Title</p>
                  </div>
                  {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Request Date</p>
                  </div> */}
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>

              {this.state.loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <div className='table-body no-border'>
                  {this.state.withdrawalList.length === 0 ? (
                    <>
                      <div className='table-row no-border'>
                        <div
                          key={i}
                          className='td font-sz-13'
                          style={{
                            ...tableBorder,
                          }}>
                          There are no Asset Withdrawal information
                        </div>
                      </div>
                    </>
                  ) : null}
                  {this.state.withdrawalList.map((value, i) => {
                    return (
                      <div className='table-row no-border'>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.assignedTo}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.assignedBy}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.dateAssigned}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.beneficiary}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.itemName}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.serialNumber}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.requestedItemTitle}
                        </div>
                        {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.requestDate}
                        </div> */}
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          <button
                            className='btn'
                            onClick={() => {
                              const {
                                assignedBy,
                                requestedItemTitle,
                                assignedTo,
                                requestedItemDetail,
                                itemName,
                                beneficiary,
                                dateAssigned,
                                assignedStatus,
                                assignComment,
                                serialNumber,
                                requestDate,
                                approvers,
                                supportingDoc,
                              } = value;
                              this.setState({
                                visibleWithdrawalDetails: true,
                                assignedBy,
                                requestedItemTitle,
                                assignedTo,
                                requestedItemDetail,
                                itemName,
                                beneficiary,
                                dateAssigned,
                                assignedStatus,
                                assignComment,
                                serialNumber,
                                requestDate,
                                approvers,
                                supportingDoc,
                              });
                            }}>
                            View Asset
                          </button>
                          {'     '}
                          <button
                            style={{ backgroundColor: 'red' }}
                            className='btn'
                            onClick={() => {
                              this.setState({
                                itemWithdrawalId: value.id,
                                visibleWithdrawalComment: true,
                              });
                            }}>
                            Withdraw Asset
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </>
      );

    if (this.state.requestButton === 'Approved') {
      return (
        <>
          <div className='flex'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Request Officer</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assignee</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Category</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Details</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Last Updated</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Status</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>
              <div className='table-body no-border'>
                {this.state.initiatedreq.map((item, idx) => (
                  <div className='table-row no-border' key={idx}>
                    <div
                      className='td font-sz-13'
                      style={{
                        ...tableBorder,
                      }}>
                      {Cookies.get('_Fullname') || Cookies.get('fullname')}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.beneficiary}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.category}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.details}
                    </div>
                    <div className='td font-sz-13' style={{ ...tableBorder }}>
                      {item.date}
                    </div>
                    <div className='td font-sz-13 ' style={{ ...tableBorder }}>
                      <div className='flex'>
                        <div className='flex m-r-auto'>{item.status || 'Approved'}</div>
                        <div
                          className=''
                          style={{
                            width: '1.1rem',
                            backgroundColor: `${
                              item.status ? ColorController.getColor(item.status) : '#1C811C'
                            }`,
                            borderRadius: '50%',
                            height: '1.1rem',
                          }}></div>
                      </div>
                    </div>
                    {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
              <div className='flex'>
                <FaEnvelopeOpenText fontSize='18px' />
                <a>supporting</a>
              </div>
            </div> */}
                    <div className='td font-sz-13'>
                      <button className='btn btn w-100' onClick={this.onEdit}>
                        View Approval
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  handleAccordion = async (value) => {
    const { withdrawal, request } = this.state;
    console.log(value, 'value was here');
    if (value === 'Asset Request') {
      this.setState({ request: !request });
    }

    if (value === 'Asset Withdrawal') {
      this.setState({ withdrawal: !withdrawal });
    }
  };

  renderMessage = (value) => {
    if (this.state.requestButton === 'Requests')
      return (
        <>
          <a
            href='#'
            onClick={() => this.handleAccordion(value)}
            className='w-100 flex'
            style={{ textDecoration: 'none', color: 'black',backgroundColor: 'white'  }}>
            <p style={{ fontSize: 15 }}>{value}</p>
          </a>
        </>
      );
  };

  pendingRequestRecordLength = (val) => {
    console.log(val, 'penindg record >>>>');
    // const { loadingRequest, loadingWithdrawal } = val;
    // if (val.loadingRequest === false && val.loadingWithdrawal === false) {
    this.setState({ pendingRequestLength: val.length, loadingRequest: val.loadingRequest });
    // }
  };

  pendingAssetWithdrawalRecordLength = (val) => {
    this.setState({ pendingWithdrawalLength: val.length });
  };

  onSearch = (e)=>{
// console.log(e.target.value)
this.setState({searchVar:e.target.value},()=>{console.log(this.state.searchVar)})
  }

  render() {
    const { selectedDate, visible, searchVar } = this.state;
// console.log(searchVar)
    return (
      <>
        <div className='w-100'>
          <div className='w-100 flex flex-v-center space-between'>
            <Greeting />
            <LocationDetails />
          </div>

          {/* {this.state.loadingRequest ? <LoadingSpinner /> : null} */}
          <>
            {/* <div className='m-t-20'>
              <SettingStrip
                pendingRequestRecordLength={this.pendingRequestRecordLength}
                title={`Asset Request (${this.state.pendingRequestLength})`}
                bodyType='assetassignrequest'
                tableHeaders={['Category', 'Description', 'Updated By', 'Last Updated']}
                bodyBGColor='white'
              />
            </div> */}

            <div className='w-100 m-t-20'>
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                <input type='search' placeholder='search' onChange={this.onSearch} className=' flex flex-v-center search-input m-b-5'/>
              </div>
              <SettingStrip
                height={'100%'}
                active={'active'}
                pendingAssetWithdrawalRecordLength={this.pendingAssetWithdrawalRecordLength}
                title={`Asset Withdrawal (${this.state.pendingWithdrawalLength})`}
                bodyType='assetassignwithdrawal'
                tableHeaders={['Category', 'Description', 'Updated By', 'Last Updated']}
                bodyBGColor='white'
                searchVal = {this.state.searchVar}
              />
            </div>
          </>

          {/* {this.state.withdrawal && this.renderWithDrawalTable()} */}
        </div>
        {this.state.visibleComment && (
          <AssignAssetFormModal
            loadComment={this.state.loadComment}
            handleAssign={() => this.onAssign()}
            comment={this.state.comment}
            handleComment={(value) => this.setState({ comment: value })}
            closeModal={() => this.setState({ visibleComment: false })}
          />
        )}

        {this.state.visibleWithdrawalComment && (
          <AssignAssetFormModal
            loadComment={this.state.loadComment}
            handleAssign={() => this.onWithdraw()}
            comment={this.state.comment}
            handleComment={(value) => this.setState({ comment: value })}
            closeModal={() => this.setState({ visibleWithdrawalComment: false })}
          />
        )}

        {this.state.visibleRequestDetails && (
          <ViewRequestDetails
            requestedBy={this.state.requestedBy}
            requestTitle={this.state.requestTitle}
            beneficiary={this.state.beneficiary}
            requestDetail={this.state.requestDetail}
            itemCount={this.state.itemCount}
            dateSubmitted={this.state.dateSubmitted}
            lastUpdated={this.state.lastUpdated}
            supportingDoc={this.state.supportingDoc}
            approvers={this.state.approvers}
            assetItems={this.state.assetItems}
            closeModal={() => this.setState({ visibleRequestDetails: false })}
          />
        )}

        {/* view withdrawal details */}
        {this.state.visibleWithdrawalDetails && (
          <ViewWithdrawalDetails
            requestedItemTitle={this.state.requestedItemTitle}
            requestedItemDetail={this.state.requestedItemDetail}
            itemName={this.state.itemName}
            beneficiary={this.state.beneficiary}
            dateAssigned={this.state.dateAssigned}
            assignedTo={this.state.assignedTo}
            assignedStatus={this.state.assignedStatus}
            assignedBy={this.state.assignedBy}
            assignComment={this.state.assignComment}
            serialNumber={this.state.serialNumber}
            requestDate={this.state.requestDate}
            supportingDoc={this.state.supportingDoc}
            approvers={this.state.approvers}
            closeModal={() => this.setState({ visibleWithdrawalDetails: false })}
          />
        )}

        {/* Assign approvers */}
        {visible && (
          <div class='col-3 m-t-10'>
            <PureModal
              header='Assign Asset'
              footer={
                <div>
                  <button
                    onClick={() =>
                      this.setState({
                        visible: false,
                      })
                    }>
                    Cancel
                  </button>
                </div>
              }
              isOpen={this.state.visible}
              closeButton='close'
              closeButtonPosition='bottom'
              onClose={() => {
                this.setState({
                  visible: false,
                });
              }}>
              <Multiselect
                options={this.state.availableItems}
                displayValue='name'
                placeholder=''
                style={{
                  chips: {
                    background: 'gray',
                    'white-space': 'normal',
                  },
                  multiselectContainer: {
                    color: 'gray',
                    'background-color': 'white',
                    'margin-bottom': '10px',
                    'white-space': 'normal',
                  },
                }}
                // loading
                emptyRecordMsg=''
                // onSearch={(val) => {
                //   this.searchApprovers(val);
                // }}
                onSelect={(selectedList, selectedItem) => {
                  const modulesList = selectedList.map((item) => item.id);

                  if (modulesList.length > this.state.itemQuantity) {
                    return errorMessage(
                      `You can't add more than ${this.state.itemQuantity} item quantity`,
                    );
                  }

                  console.log(modulesList, 'modulesList >>>>', selectedList, 'selectedList >>>');
                  this.setState({ assignedAssetItemId: modulesList });
                }}
                onRemove={(selectedList, removedItem) => {
                  const modulesList = this.state.assignedAssetItemId.filter(
                    (item) => item !== removedItem.id,
                  );
                  this.setState({ assignedAssetItemId: modulesList });
                }}
              />
              <br />
              {this.state.loadRequest ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <>
                  <button
                    className='btn'
                    type='button'
                    onClick={() => {
                      // check if itemcategory is empty
                      if (this.state.assignedAssetItemId.length === 0) {
                        return notification({
                          title: 'No Asset Assigned',
                          message: 'You need to assign Asset to continue',
                          type: 'danger',
                        });
                      }

                      this.setState({
                        visible: false,
                        visibleComment: true,
                        action: 1,
                      });
                      // this.onAssign(1);
                    }}>
                    Approve
                  </button>
                  {/* <br /> */}
                  {'       '}
                  <button
                    className='btn'
                    style={{ backgroundColor: 'red' }}
                    type='button'
                    onClick={() => {
                      this.setState({
                        visible: false,
                        visibleComment: true,
                        action: 0,
                      });
                      // this.onAssign(0);
                    }}>
                    Decline
                  </button>
                </>
              )}
            </PureModal>
          </div>
        )}
      </>
    );
  }
}

export default AssignWithdrawal;
