import React from 'react';
//import Cookies from 'js-cookie';
// Icons
import { RiLogoutCircleLine /* , RiSettings3Line */ } from 'react-icons/ri';
import { RiBarChartFill } from 'react-icons/ri';
// import { FaRegCheckCircle } from 'react-icons/fa';
// import { ImCancelCircle } from 'react-icons/im';
import { IoIosSettings } from 'react-icons/io';
import { BsPerson,BsTrash2 } from 'react-icons/bs';
// import { ImFileText } from 'react-icons/im';
import { MdAssignment, MdCallMissed } from 'react-icons/md';
import { AiOutlineProfile, AiOutlineFileSync } from 'react-icons/ai';
// import {GoRequestChanges} from 'react-icons/go';

//import Cookies from 'js-cookie';
const sidebarMenu = () => {
  // const fullName = Cookies.get('fullname');

  // console.log('Full Name: ', fullName);

  const menu = [];

  // menu.push({
  //   title: 'Home',
  //   href: '/admin-portal/home',
  //   icon: <AiOutlineHome className='icon' />,
  // });

  menu.push({
    title: 'Dashboard',
    href: '/admin-portal',
    icon: <RiBarChartFill className='icon' />,
  });
  menu.push({
    title: 'Asset Registry',
    
    icon: <BsPerson className='icon' />,
    href: '/admin-portal/asset/report',
  });
  menu.push({
    title: 'Add Existing Asset',
    href: '/admin-portal/asset/asset-profiler',
    icon: <AiOutlineProfile className='icon' />,
    
  });

  // menu.push({
  //   title: 'Manage Asset',
  //   icon: <MdAssignment className='icon' />,
  //   children: [
  //     {
  //       title: 'Asset Request',
  //       href: '/admin-portal/asset/asset-request',
  //     },
  //     {
  //       title: 'Assign Asset',
  //       href: '/admin-portal/asset/manage-request',
  //     },
  //     {
  //       title: 'Asset Disposal',
  //       href: '/admin-portal/asset/asset-disposal',
  //     },
  //   ],
  // });




  // menu.push({
  //   title: 'Asset Request',
  //   icon: <GoRequestChanges className='icon' />,
  //   href: '/admin-portal/asset/asset-request',
  // });
  //menu.push({
    //title: 'Assign Asset(Request)',
    //icon: <MdAssignment className='icon' />,
    //href: '/admin-portal/asset/manage-request',
  //});
  menu.push({
    title: 'Assign Asset',
    icon: <MdAssignment className='icon' />,
    href: '/admin-portal/asset/assign-asset',
  });
  menu.push({
    title: 'Asset Withdrawal',
    icon: <MdCallMissed className='icon' />,
    href: '/admin-portal/asset/asset-withdrawal',
  });
  menu.push({
    title: 'Asset Disposal',
    icon: <BsTrash2 className='icon' />,
    href: '/admin-portal/asset/asset-disposal',
  });
  menu.push({
    title: 'Disposal Reviews',
    href: '/admin-portal/asset/asset-reviews',
    icon: <AiOutlineFileSync className='icon' />,
    
  });
  menu.push({
    title: 'Settings',
    icon: <IoIosSettings className='icon' />,
    href: '/admin-portal/asset/settings',
  });
  menu.push({
    title: 'Logout',
    icon: <RiLogoutCircleLine className='icon' />,
    href: '/admin-portal/asset/logout',
  });
  // (fullName === 'EdoGov Admin' || fullName === 'Edo Gov Admin') &&
  //   menu.push({
  //     title: 'Setup',
  //     icon: <IoIosSettings className='icon' />,
  //     children: [
  //       {
  //         title: 'Training Category',
  //         href: '/admin-portal/training/category',
  //       },
  //       {
  //         title: 'Training Methods',
  //         href: '/admin-portal/training/method',
  //       },
  //       {
  //         title: 'Training Approver',
  //         href: '/admin-portal/training/approver',
  //       },
  //     ],
  //   });

  return menu;
};

export default sidebarMenu;
