/* eslint-disable */
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import HoverImage from 'react-hover-image';
import TextInput from '../inputs/TextInput';
import FormSearchInput from '../inputs/FormSearchInput';
// import SearchInput from '../inputs/SearchInput';
import { IoMdClose } from 'react-icons/io';
import TextareaInput from '../inputs/TextareaInput';
import { HiOutlineUpload } from 'react-icons/hi';
import { BiMailSend, BiTrash } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import { BsSearch } from 'react-icons/bs';
import { FileUpload } from '../../utility/fetch';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import SearchInput, { createFilter } from 'react-search-input';

import imageJpeg from '../../assets/images/jpeg.png';
import imagePdf from '../../assets/images/pdf.png';
import imageFile from '../../assets/images/file.png';

class EditAssetProfilerFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileMessage: ' Upload Asset(s) picture',
      itemName: '',
      description: '',
      supplier: '',
      manufacturer: '',
      deliveryDate: '',
      unitCost: 0,
      serialNumber: '',
      categoryId: 0,
      quantity: 0,
      action: '',
      supportingDoc: [],
      separateNewFile: [],
      imageUrl: '',
      loading: false,
      openModal: false,
      openConfirmDelete: true,
    };
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    });
  };

  render() {
    const {
      itemName,
      type,
      closeModal
    } = this.props;
    return (
      <>
        <div
          className='overlay'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 2px 6px #0000000A',
          }}>
          <div className='flex flex-v-center flex-h-center flex-direction-column'>
            <div
              className='modal-box'
              style={{
                boxShadow: '0px 2px 6px #0000000A',
                position: 'relative',
                width: '980px',
              }}>
              <div className='content no-padding '>
                <IoMdClose
                  className='pointer m-l-5'
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '100%',
                    fontSize: '24px',
                    color: '#fff',
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#F74F11',
                    padding: '3px',
                  }}
                  onClick={this.props.closeModal}
                />
                <div className={`w-100`}>
                  <div
                    className='w-100 flex space-between'
                    style={{ padding: '15px', backgroundColor: '#d5ffd5', fontSize: 20 }}>
                    { type === 1 ? (<p>
                      {`Are you sure you want to Approve ${itemName.toUpperCase()}`}
                    </p>) : (<p>{`Are you sure you want to Decline ${itemName.toUpperCase()}`}</p>)}
                  </div>

                  <div className='col-12'>
                    <div class='form-group'>
                      <div class='row '>
                        <div class='col-12 m-t-10'>
                          <TextareaInput
                            className='w-100 m-b-10'
                            name='comment'
                            label='comment'
                            value={this.state.comment}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <p
                      style={{
                        color: this.props.validationCode === 1 ? 'green' : 'red',
                        fontSize: 15,
                      }}>
                      {this.props.validationStatus}
                    </p>

                    {this.props.loading ? (
                      <Loader type='ThreeDots' color='green' height={50} width={50} />
                    ) : (
                      <div className='w-100 m-t-10 flex flex-v-center flex-h-center space-between'>
                        <button
                          className='btn btn-large w-49'
                          style={{ backgroundColor: 'green' }}
                          type='button'
                          onClick={() => {
                            this.props.onEnterAction(this.state.comment);
                          }}>
                          Enter
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditAssetProfilerFormModal;
