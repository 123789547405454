/* eslint-disable */
import React, { Component } from 'react';
import Cookies from 'js-cookie';

class Home extends Component {
  state = {};

  loadPage = () => {
    const homelink = Cookies.get('_HomeLink');

    window.location.assign(homelink);
    // Cookies.remove("token");
    // Cookies.remove("tokenExist");
  };

  componentDidMount() {
    // Cookies.remove("token");
    this.loadPage();
  }

  render() {
    return <></>;
  }
}

export default Home;
