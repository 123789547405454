import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import Loader from '../../assets/svg/loading.svg';

import TextareaInput from '../inputs/TextareaInput';
import notification from '../../utility/notification';

const CommentForm = ({
  onEnterAction,
  loading,
  type,
  // itemName,
  validationStatus,
  validationCode,
  closeModal,
}) => {
  const [comment, setComment] = useState('');

  const submitForm = async () => {
    const body = {
      comment,
    };
    const error = validateForm(body);

    if (error) {
      notification({
        title: 'Invalid Entry',
        message: error,
        type: 'danger',
      });
      return;
    }

    return onEnterAction(comment);
  };

  return (
    <div className='overlay'>
      <IoMdClose className='close-btn pointer' onClick={closeModal} />
      <div className='modal-box max-w-400'>
        <div className='content'>
          <TextareaInput
            name='comment'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className='w-100 m-b-10'
            rows={3}
            label='Comment'
          />
          <p style={{ color: validationCode === 1 ? 'green' : 'red' }}>{validationStatus}</p>
          <button
            type='button'
            className='btn btn-large w-100'
            onClick={submitForm}
            disabled={loading}>
            {type === 0 ? 'Decline' : type === 1 ? 'Approve' : null}
            {loading && <img src={Loader} className='btn-loading' alt='Loading...' />}
          </button>
        </div>
      </div>
    </div>
  );
};

const validateForm = ({ comment }) => {
  if (!comment) return 'Please, enter your comment';
  return null;
};

export default CommentForm;
