/* eslint-disable */
import { Component } from 'react';
import { IoMdClose } from 'react-icons/io';
import * as React from 'react';
//import moment from 'moment';
import Accordion from '../layouts/Accordion';
// icons
import { BsCaretDownFill } from 'react-icons/bs';
import { BsFillCaretUpFill } from 'react-icons/bs';
import CommentModal from '../layouts/CommentModal';
// import Successmodal from '../layouts/SuccessModal';
import ErrorModal from '../layouts/ErrorModal';
// Api
import { Get } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
// import { Put, Get } from '../../utility/fetch';
// Image
import imageSrc from '../../assets/images/user-avater.png';
import { formatFileUrl, formatReviewStatus } from '../../utility/general';

import AssignAssetFormModal from './AssignAssetFormModal';

//import moment from 'moment';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class TrainingRequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: '',
      title: '',
      beneficiary: '',
      cost: '',
      details: '',
      fullname: '',
      requestid: 0,
      approvalRecord: [],
    };
  }

  getReportApprovalRecord = (data) => {
    console.log(data);

    let _approvalRecord = Array.isArray(data) ? data : [];
    this.setState({ approvalRecord: _approvalRecord, loading: false });
  };

  componentDidMount() {
    Get(URLAPI.reportApprovalRecord + '/' + this.props.id, this.getReportApprovalRecord);
    this.setState({
      requestid: this.props.id,
      category: this.props.category,
      title: this.props.title,
      beneficiary: this.props.beneficiary,
      cost: this.props.cost,
      details: this.props.details,
      fullname: this.props.firstName + ' ' + this.props.lastName,
    });
  }
  render() {
    return (
      <>
        <div
          className='overlay'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 2px 6px #0000000A',
          }}>
          <div className='flex flex-v-center flex-h-center flex-direction-column'>
            <div
              className='modal-box'
              style={{
                boxShadow: '0px 2px 6px #0000000A',
                position: 'relative',
                width: '650px',
              }}>
              <div className='content no-padding '>
                <IoMdClose
                  className='pointer m-l-5'
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '100%',
                    fontSize: '24px',
                    color: '#fff',
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#F74F11',
                    padding: '3px',
                  }}
                  onClick={this.props.closeModal}
                />
                <div className='w-100 bg-white pd-10'>
                  <div className='flex flex-direction-column'>
                    {/* 
                  <div
                    className='w-100 flex space-between'
                    style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                    <p>Request Initiator: {this.props.firstName + ' ' + this.props.lastName}</p>
                  </div> */}
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Category: </div>
                      <div className='w-80 bold-text'>{this.state.category} </div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Title: </div>
                      <div className='w-80 bold-text'>{this.state.title}</div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>
                        Cost Implication:{' '}
                      </div>
                      <div className='w-80 bold-text'>{this.state.cost} </div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Beneficiary: </div>
                      <div className='w-80 bold-text'>{this.state.beneficiary} </div>
                    </div>

                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>
                        Request Details :{' '}
                      </div>
                      <div className='w-80 bold-text'>{this.state.details} </div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Currently With: </div>
                      <div className='w-80 bold-text'>{this.state.fullname} </div>
                    </div>

                    <div className='m-t-20'>
                      <div
                        id='bordered-table'
                        className='table-view '
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '5px 15px',
                          // border: 'none',
                          ...this.props.overrideTableStyles,
                        }}>
                        <div
                          className='table-header'
                          style={{
                            backgroundColor: `${this.props.headerBGColor || '#d5ffd5'}`,
                            color: `${this.props.headerColor || '#43425D'}`,
                          }}>
                          <div className='table-row'>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Approver</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Date</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Status</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Comment</p>
                            </div>
                          </div>
                        </div>
                        {this.state.approvalRecord.length === 0 ? (
                          <h4 className='m-t-10'>No approval yet for this request</h4>
                        ) : (
                          <div className='table-body no-border'>
                            {this.state.approvalRecord.map((item, idx) => (
                              <div className='table-row no-border' key={idx}>
                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {item.approver}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.date}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.status}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.comment}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TrainingRequestDetails;
