/* eslint-disable */
import Cookies from 'js-cookie';

export const logout = () => {
  // window.location.assign('http://portal-dev.greenzonetechnologies.com.ng/');
  // _HomeLink
  window.location.assign(Cookies.get('_HomeLink'));
  Cookies.remove('_AppToken');
  Cookies.remove('tokenExist');
  Cookies.remove('token');
  // Cookies.remove('tokenExist');
};

export default logout;
