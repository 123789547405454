/* eslint-disable react/destructuring-assignment */
import React from 'react';

const SelectInput = (props) => {
  const options =
    props.options &&
    props.options.map((option) =>
      typeof option === 'object' ? (
        <option
          // id={option.id ? option.id : null}
          value={
            // option.value && option.value.toString().toLowerCase() === 'select item'
            //   ? ''
              option.id
          }
          key={option.name}
          disabled={option.name.toLowerCase() === 'select item'}
          >
          {option.name}
        </option>
      ) : (
        <option
          value={option && option.toString().toLowerCase() === 'select item...' ? '' : option}
          key={option}
          // disabled={option.toLowerCase() === 'select item'}
          >
          {option}
        </option>
      ),
    );
  return (
    <div className={`${props.className} form-group`} style={{ ...props.overideStyle }}>
      <label htmlFor={props.name}>{props.label}</label>
      <select
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        style={{ ...props.overideInputStyle }}>
          <option>
          {props.title}
        </option>
        {options}
      </select>
    </div>
  );
};

export default SelectInput;
