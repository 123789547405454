/* eslint-disable */
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import TextareaInput from '../inputs/TextareaInput';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { IoMdClose } from 'react-icons/io';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class ViewDisposalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      itemName,
      itemDescription,
      itemStatus,
      profiledBy,
      manufacturer,
      serialNumber,
      supplier,
      unitCost,
      currentValue,
      lastUpdatedBy,
      lastUpdated,
      dateDelivered,
      dateProfiled,
      assignmentHistory,
      assetSupportingDoc,
    } = this.props;

    return (
      <>
        <div className='overlay'>
          <IoMdClose className='close-btn pointer m-l-5' onClick={this.props.closeModal} />
          <div className='modal-box' style={{ width: '90%' }}>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Disposal Details</p>
              <p>{itemName} Information</p>
            </div>
            <div className='content'>
              <div className='w-100 bg-white'>
                <div className='flex flex-direction-column'>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Name: </div>
                    <div className='w-80 bold-text'>{itemName || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Description: </div>
                    <div className='w-80 bold-text'>{itemDescription || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Status </div>
                    <div className='w-80 bold-text'>{itemStatus || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Manufacturer: </div>
                    <div className='w-80 bold-text'>{manufacturer || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Supplier: </div>
                    <div className='w-80 bold-text'>{supplier || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Serial Number: </div>
                    <div className='w-80 bold-text'>{serialNumber || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Asset Value: </div>
                    <div className='w-80 bold-text'>&#8358;{unitCost || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Current Value: </div>
                    <div className='w-80 bold-text'>&#8358;{currentValue || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Delivered: </div>
                    <div className='w-80 bold-text'>{dateDelivered || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Entered By: </div>
                    <div className='w-80 bold-text'>{profiledBy || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Entered: </div>
                    <div className='w-80 bold-text'>{dateProfiled || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Last Updated: </div>
                    <div className='w-80 bold-text'>{lastUpdated || ''}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Supporting Document</p>
            </div>
            {assetSupportingDoc.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no supporting document</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {assetSupportingDoc.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                            {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Assign History</p>
            </div>
            {assignmentHistory.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no asset assign history</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {/* {approvers.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='col-2-4 padding no-margin bold-text'>Name: </div>
                          <div className='w-80 bold-text'>{value.approverName || ''}</div>
                          <div className='col-2-4 padding no-margin bold-text'>Role: </div>
                          <div className='w-80 bold-text'>{value.role || ''}</div>
                          <div className='col-2-4 padding no-margin bold-text'>Status: </div>
                          <div className='w-80 bold-text'>{value.approvalStatus || ''}</div>
                        </div>
                      );
                    })} */}
                    <div className='flex'>
                      <div
                        id='bordered-table'
                        className='table-view '
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '5px 15px',
                          ...this.props.overrideTableStyles,
                        }}>
                        <div
                          className='table-header'
                          style={{
                            backgroundColor: `${this.props.headerBGColor || '#D3FAD5'}`,
                            color: `${this.props.headerColor || '#43425D'}`,
                          }}>
                          <div className='table-row'>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Assignee</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Requested By</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Assign Comment</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Assigned Status</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Date Assigned</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Date Withdrawn</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Request Date</p>
                            </div>
                          </div>
                        </div>
                        <div className='table-body no-border'>
                          {assignmentHistory.length === 0 ? (
                            <div>
                              <br />
                              <h3>
                                <i>There are no approvers</i>
                              </h3>
                            </div>
                          ) : (
                            <>
                              {assignmentHistory.map((item, idx) => (
                                <div className='table-row no-border' key={idx}>
                                  <div
                                    className='td font-sz-13'
                                    style={{
                                      ...tableBorder,
                                    }}>
                                    {item.beneficiary}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.requestedBy}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.assignComment}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.assignedStatus}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.dateAssigned}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.dateWithdrawn}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.requestDate}
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewDisposalDetails;
