/* eslint-disable */
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import TextareaInput from '../inputs/TextareaInput';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { IoMdClose } from 'react-icons/io';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class ViewAssetRequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      assignedBy,
      requestedItemTitle,
      assignedTo,
      requestedItemDetail,
      itemName,
      beneficiary,
      dateAssigned,
      assignedStatus,
      assignComment,
      serialNumber,
      requestDate,
      approvers,
      supportingDoc,
    } = this.props;

    return (
      <>
        <div className='overlay'>
          <IoMdClose className='close-btn pointer m-l-5' onClick={this.props.closeModal} />
          <div className='modal-box' style={{ width: '90%' }}>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Withdrawal Details</p>
              <p>{requestedItemTitle} Information</p>
            </div>
            <div className='content'>
              <div className='w-100 bg-white'>
                <div className='flex flex-direction-column'>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Assigned By: </div>
                    <div className='w-80 bold-text'>{assignedBy || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Request Officer: </div>
                    <div className='w-80 bold-text'>{assignedTo || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Request Item Title: </div>
                    <div className='w-80 bold-text'>{requestedItemTitle || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Request Item Detail: </div>
                    <div className='w-80 bold-text'>{requestedItemDetail || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Item Name: </div>
                    <div className='w-80 bold-text'>{itemName || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Assignee: </div>
                    <div className='w-80 bold-text'>{beneficiary || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Assigned: </div>
                    <div className='w-80 bold-text'>{dateAssigned || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Assigned Status: </div>
                    <div className='w-80 bold-text'>{assignedStatus || 'null'}</div>
                  </div>
                  {/* <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Assigned Comment: </div>
                    <div className='w-80 bold-text'>{assignComment || 'null'}</div>
                  </div> */}
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Serial Number: </div>
                    <div className='w-80 bold-text'>{serialNumber || 'null'}</div>
                  </div>

                  {/* <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Request Date: </div>
                    <div className='w-80 bold-text'>{requestDate || 'null'}</div>
                  </div> */}
                </div>
              </div>
            </div>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Supporting Document</p>
            </div>
            {supportingDoc?.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no supporting document</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {supportingDoc?.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                          {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewAssetRequestDetails;
