/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import Loader from 'react-loader-spinner';
import Cookies from 'js-cookie';
import Greeting from '../layouts/Greeting';
import LocationDetails from '../layouts/LocationDetails';
import SearchInput from '../inputs/SearchInput';
import { Get, Post } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import ColorController from '../../controllers/ColorController';
import AssetProfilerFormModal from '../layouts/AssetProfilerFormModal';
import data2 from '../data';
import AssetDisposalFormModal from '../layouts/AssetDisposalFormModal';
import ViewDisposalDetails from '../layouts/ViewDisposalDetails';
import { GetFiles } from '../../utility/fetch';
import notification from '../../utility/notification';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { ImEye } from 'react-icons/im';
import { CgTrashEmpty } from "react-icons/cg";
import ReactTooltip from 'react-tooltip';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};
const alignLeft = {
  textAlign: 'left',
};

class AssetDisposal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedDate: 'All Cases',
      startDate: '',
      endDate: '',
      searchText: null,
      calendarDate: new Date(),
      visible: false,
      requestButton: 'Disposal Requests',
      approvedButton: 'Approved',
      initiatedreq: [],
      itemId: 0,
      name: '',
      description: '',
      manufacturer: '',
      quantity: '',
      deliveryDate: '',
      assetSerial: '',
      category: '',
      editVisibility: false,
      categoryValue: 'Select Category',
      categorySelected: '',
      assetDisposal: [],
      holder: [],
      
      loading: false,
      disposalCategory: [],
      fileData: [],
      decodedToken: {},
      category: '',
      loadDisposalTable: false,
      searchVal:''
    };
    this.filterReviewsOnApprover = this.filterReviewsOnApprover.bind(this);
  }

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({ visible: false, editVisibility: false });
  };

  viewAsset = (item) => {
    this.setState({
      editVisibility: true,
      itemId: item.id,
      name: item.name,
      description: item.description,
      manufacturer: item.manufacturer,
      quantity: item.quantity,
      deliveryDate: item.deliveryDate,
      assetSerial: item.assetSerial,
      category: item.category,
    });
  };

  setApproved = () => {
    this.setState({
      requestButton: 'Approved',
    });
  };

  getCategory = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      this.setState({ category: _records, loading: false });
    }
  };

  getDisposalCategory = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      this.setState({ disposalCategory: _records, loading: false });
    }
  };

  filterReviewsOnApprover = () => {
    this.setState({
      initiatedreq: this.state.initiatedreq.filter(
        (rec) => rec.id !== parseInt(this.state.requestId),
      ),
    });
    Get(URLAPI.requestApprovals.Get, this.getApproval);
  };

  componentDidMount() {
    this._isMounted = true;
    try {
      const token = Cookies.get('token');
      const decodedToken = jwt_decode(token);

      this.setState({ decodedToken });
      Get('api/categories/asset', this.getCategory);
      Get('api/categories/disposal', this.getDisposalCategory);
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState?.props?.searchVal.toUpperCase !== this?.props?.searchVal.toUpperCase) {
    if (prevState?.searchVal !== this?.state.searchVal) {
      console.log(this.state.searchVal)
      // Write logic here.
       let val = this?.state?.holder?.filter((item)=>(
        item.assignedTo.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) ||
        item.itemName.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) 
      ))
      // this.state.assetDisposal.length
    this.setState({assetDisposal:val})
    console.log(val)

    }
  }


  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };
  returnRequestButtonState = () => {
    this.setState({
      requestButton: 'Disposal Requests',
    });
  };

  renderTable = () => {
    return (
      <>
        <div className='flex'>
          <div
            id='bordered-table'
            className='table-view '
            style={{
              borderCollapse: 'collapse',
              borderSpacing: '5px 15px',
              // border: 'none',
              ...this.props.overrideTableStyles,
            }}>
            <div
              className='table-header'
              style={{
                backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                color: `${this.props.headerColor || '#43425D'}`,
              }}>
              <div className='table-row'>
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Entered By</p>
                </div>
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Status</p>
                </div>
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Name</p>
                </div>
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Serial Number</p>
                </div>
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Supplier</p>
                </div>
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Manufacturer</p>
                </div>
                {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Date Profiled</p>
                </div> */}
                {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                      <p>Status</p>
                    </div> */}
                <div className='th case title w-25' style={{ ...tableBorder }}>
                  <p>Action</p>
                </div>
              </div>
            </div>

            <br />
            {this.state.loadDisposalTable ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                <Loader type='ThreeDots' color='green' height={80} width={80} />
              </div>
            ) : (
              <>
                {this.state.assetDisposal.length === 0 ? (
                  <h3>
                    <i>no asset disposal</i>
                  </h3>
                ) : null}

                <div className='table-body no-border'>
                  {this.state.assetDisposal.map((item, idx) => (
                    <div className='table-row no-border' key={idx}>
                      <div
                        className='td font-sz-13'
                        style={{
                          ...tableBorder,
                        }}>
                        {item.profiledBy}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.itemStatus}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.itemName}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.serialNumber}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.supplier}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.manufacturer}
                      </div>
                      {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.dateProfiled}
                      </div> */}
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        <button
                          data-tip
                          data-for='view-details'
                          type='button'
                          className='pointer td actions'
                          onClick={() => {
                            // console.log(item)

                            const {
                              itemName,
                              itemDescription,
                              itemStatus,
                              profiledBy,
                              manufacturer,
                              serialNumber,
                              supplier,
                              unitCost,
                              currentValue,
                              lastUpdatedBy,
                              lastUpdated,
                              dateDelivered,
                              dateProfiled,
                              assignmentHistory,
                              assetSupportingDoc,
                            } = item;
                            // console.log(item, 'item 234 was here >>>>')
                            this.setState({
                              visibleDisposalDetails: true,
                              itemName,
                              itemDescription,
                              itemStatus,
                              profiledBy,
                              manufacturer,
                              serialNumber,
                              supplier,
                              unitCost,
                              currentValue,
                              lastUpdatedBy,
                              lastUpdated,
                              dateDelivered,
                              dateProfiled,
                              assignmentHistory,
                              assetSupportingDoc,
                            });
                          }}>
                          <ImEye />
                        </button>
                        <ReactTooltip id='view-details' type='success' effect='solid'>
                          <span>View Details</span>
                        </ReactTooltip>
                        {'     '}
                        <button
                          data-tip
                          data-for='dispose'
                          type='button'
                          className='pointer td actions'
                          style={{
                            backgroundColor: 'red',
                          }}
                          onClick={() => {
                            this.setState({
                              visibleDispose: true,
                              itemId: item.id,
                              currentValue: item.currentValue,
                              assetValue: item.unitCost,
                            });
                          }}>
                          <CgTrashEmpty/>
                        </button>
                        <ReactTooltip id='dispose' type='error' effect='solid'>
                          <span>Dispose</span>
                        </ReactTooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  renderMessage = () => {
    return (
      <>
        <div className='w-100 flex' style={{ backgroundColor: '#d5ffd5' }}>
          <p>Asset Disposal</p>
        </div>
      </>
    );
  };

  handleCategorySelect = (e) => {
    this.setState({ loadDisposalTable: true });
    e.preventDefault();

    this.setState({
      categoryValue: e.target.value,
      categorySelected: e.target.value,
    });

    Get(`api/asetitems/disposallist?assetcategoryid=${e.target.value}`, this.handleCategoryDisplay);
  };

  handleCategoryDisplay = (data) => {
    this.setState({ assetDisposal: data, loadDisposalTable: false });
  };

  isError = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Error]';
  };

  searchDocument = (value) => {
    this.setState({ loadSearch: true });
    GetFiles(this.getDocuments, `user_id=${this.state.decodedToken.userid}&DocTitle=${value}`);
  };

  getDocuments = (res) => {
    console.log(res, 'searching document here, res was here');

    if (this.isError(res)) {
      return;
    }

    this.setState({ fileData: res, loadSearch: false });
  };

  searchApprovers = (value) => {
    this.setState({ loadSearch: true });
    const name = value.trim();

    if (name === '') {
      return this.setState({
        loadSearch: false,
      });
    }
    Get(`api/profiler/searchapprovers/${name}`, this.getApprovers);
  };

  getApprovers = (res) => {
    this.setState({ approverFileData: res, loadSearch: false });
  };

  validateForm = (state) => {
    const {
      disposalCategoryId,
      approversId,
      supportingDoc,
      assetStatus,
      disposalNote,
      currentValue,
    } = state;

    if (!currentValue) return { status: true, message: 'Please, Enter a current value' };
    if (disposalCategoryId === 0)
      return { status: true, message: 'Please, Select a disposal category' };
    if (!assetStatus) return { status: true, message: 'Please, Enter an asset status' };
    if (!disposalNote) return { status: true, message: 'Please, Enter a disposal note' };
    // if (supportingDoc.length === 0)
    //   return { status: true, message: 'Please, search and/or upload a supporting document' };
    if (approversId.length === 0)
      return { status: true, message: 'Please, Add approvers to continue' };
    return false;
  };

  onSubmit = (data) => {
    this.setState({
      validationStatus: '',
      validationCode: 0,
      loading: true,
    });
    let error_data = this.validateForm(data);

    if (error_data.status && error_data.status === true) {
      this.setState({
        loadTable: false,
        loadForm: false,
        loading: false,
      });
      return notification({
        title: 'Invalid Entry',
        message: error_data.message,
        type: 'danger',
      });
    }

    if (error_data === false) {
      const {
        disposalCategoryId,
        approversId,
        supportingDoc,
        assetStatus,
        disposalNote,
        currentValue,
      } = data;

      const { itemId } = this.state;

      const request = {
        itemId,
        disposalCategoryId,
        approversId,
        supportingDoc,
        assetStatus,
        disposalNote,
        currentValue: parseFloat(currentValue),
      };

      this.onSubmitInitiateAsset(request);
    }
  };

  onSubmitInitiateAsset = (data) => {
    console.log(data, 'data request was here also >>>>');
    Post(data, 'api/disposal/submit', this.handleDisposalSubmit);
  };

  handleDisposalSubmit = (record) => {
    console.log(record, 'record here');
    if (record.code === 1) {
      this.setState({
        loading: true,
        editVisibility: false,
        visible: false,
        visibleDispose: false,
      });
      // notification({
      //   title: 'Successful',
      //   message: record.message,
      //   type: 'success',
      // });
      successMessage(record.message);
      Get('api/categories/asset', this.getCategory);
      this.setState({
        validationStatus: '',
        fileData: [],
        visibleDispose: false,
      });
    }

    if (record.code === 0) {
      notification({
        title: 'Error',
        message: record.message,
        type: 'danger',
      });
      return this.setState({
        loading: false,
      });
    }

    if (record.code === undefined || !record.code) {
      this.setState({
        loading: false,
        editVisibility: false,
        visible: false,
        visibleDispose: false,
      });

      return errorMessage('Profiler process encountered an error, please try again later.');
    }
  };

  onSearch = (e)=>{
    // console.log(e.target.value)
    this.setState({searchVal:e.target.value},()=>{console.log(this.state.searchVal)})
      }

  render() {
    const { editVisibility, visible } = this.state;
    return (
      <>
        <div className='w-100'>
          {/* {isLoading && <LoadingSpinner />} */}
          <div className='w-100 flex flex-v-center space-between'>
            <Greeting />
            <LocationDetails />
          </div>
          <br />
          <div className='m-t-10'>
            {/* <SearchInput onChange={(e) => this.searchSpace(e)} /> */}
            <div className="flex w-100" style={{justifyContent:"space-between"}}>
              <div class='col-4'>
                <label>
                  <b>Select Category</b>
                </label>
                {/* <br/> */}
                <select
                  className='w-100 h-100 m-b-8'
                  style={{
                    fontSize: 15,
                    backgroundColor: '#d5ffd5',
                  }}
                  name='categoryId'
                  value={this.state.categoryId}
                  onChange={(e) => this.handleCategorySelect(e)}>
                  <option>Select Category</option>
                  {this.state.category &&
                    this.state.category.map((res) => <option value={res.id}>{res.name}</option>)}
                </select>
              </div>
              <div style={{display:'', justifyContent:''}}>
              <label>
                  <b>Search</b>
              </label>
                  <input style={{padding:'10px'}} type='search' placeholder='search' onChange={this.onSearch} className=' flex flex-v-center search-input'/>
              </div>
            </div>
          </div>

          <div className='w-100 m-t-20'>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
              {this.renderMessage()}
            </div>
          </div>
          {this.renderTable()}
        </div>
        {visible && (
          <AssetProfilerFormModal
            // requestId={this.state.requestId}
            // details={this.state.details}
            // date={this.state.date}
            // category={this.state.category}
            // title={this.state.title}
            // cost={this.state.cost}
            //filterReviewsOnApprover={this.filterReviewsOnApprover}
            // getApproval={this.getApproval}
            closeModal={() => this.setState({ visible: false })}
          />
        )}

        {editVisibility && (
          <AssetProfilerDetailsModal
            itemId={this.state.itemId}
            name={this.state.name}
            description={this.state.description}
            manufacturer={this.state.manufacturer}
            quantity={this.state.quantity}
            details={this.state.details}
            deliveryDate={this.state.deliveryDate}
            assetSerial={this.state.assetSerial}
            category={this.state.category}
            closeModal={() => this.setState({ editVisibility: false })}
          />
        )}

        {this.state.visibleDisposalDetails && (
          <ViewDisposalDetails
            itemName={this.state.itemName}
            itemDescription={this.state.itemDescription}
            itemStatus={this.state.itemStatus}
            profiledBy={this.state.profiledBy}
            manufacturer={this.state.manufacturer}
            currentValue={this.state.currentValue}
            serialNumber={this.state.serialNumber}
            supplier={this.state.supplier}
            unitCost={this.state.unitCost}
            lastUpdatedBy={this.state.lastUpdatedBy}
            lastUpdated={this.state.lastUpdated}
            dateDelivered={this.state.dateDelivered}
            dateProfiled={this.state.dateProfiled}
            assignmentHistory={this.state.assignmentHistory}
            assetSupportingDoc={this.state.assetSupportingDoc}
            closeModal={() => this.setState({ visibleDisposalDetails: false })}
          />
        )}

        {/* dispose modal */}
        {this.state.visibleDispose && (
          <AssetDisposalFormModal
            supportingDoc={this.state.supportingDoc}
            currentValue={this.state.currentValue}
            assetValue={this.state.assetValue}
            disposalCategory={this.state.disposalCategory}
            searchDocument={this.searchDocument}
            fileData={this.state.fileData}
            approverFileData={this.state.approverFileData}
            searchApprovers={this.searchApprovers}
            loading={this.state.loading}
            onSubmit={this.onSubmit}
            loadingModal={this.state.loadSearch}
            closeModal={() => this.setState({ visibleDispose: false })}
          />
        )}
      </>
    );
  }
}

export default AssetDisposal;
