/*eslint-disable */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import CommentForm from './CommentForm';
import { GrDown, GrUp } from 'react-icons/gr';
import ButtonLoader from '../../assets/svg/loading.svg';
import moment from 'moment';
import FormSearchInput from '../inputs/FormSearchInput';
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
import Select from 'react-select';
import { Post, Get, Delete, Put, GetwithQueryString } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import { GrEdit } from 'react-icons/gr';
import data from '../../data';
import { ImCheckmark, ImCancelCircle, ImEye } from 'react-icons/im';
import { CgPlayListRemove } from 'react-icons/cg';
// CgPlayListRemove
import Cookies from 'js-cookie';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import ViewRequestDetails from './ViewRequestDetails';
import LoadingSpinner from '../../resources/loadingSpinner';

import PureModal from 'react-pure-modal';
import { Multiselect } from 'multiselect-react-dropdown';
import { GiTrashCan } from 'react-icons/gi';
import notification from '../../utility/notification';
import { formatDateforPicker } from '../../utility/dateTime';
import AssignAssetFormModal from './AssignAssetFormModal';
import ColorController from '../../controllers/ColorController';
import ViewWithdrawalDetails from './ViewWithdrawalDetails';
import AssetWithdrawalFormModal from './AssetWithdrawalFormModal';
import { parse } from '@babel/core';

const bottomBorder = {
  borderBottom: '.5px solid rgba(99,99,99,.6)',
};
const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class AssetAssignWithdrawal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedDate: 'All Cases',
      startDate: '',
      endDate: '',
      searchText: null,
      calendarDate: new Date(),
      visible: false,
      requestButton: 'Requests',
      approvedButton: 'Approved',
      initiatedreq: [],
      holder: [],
      withdrawalList: [],
      requestId: 0,
      category: '',
      beneficiary: '',
      details: '',
      date: '',
      cost: '',
      title: '',
      request: false,
      withdrawal: false,
      assignedAssetItemId: [],
      categoryListData: [],
    };
    this.filterReviewsOnApprover = this.filterReviewsOnApprover.bind(this);
  }

  openModal = (item) => {
    this.setState({
      visible: true,
      // requestId: item.id,
      // beneficiary: item.beneficiary,
      // cost: item.cost,
      // category: item.category,
      // title: item.title,
      // details: item.details,
      // date: item.date,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  handleDate = async (e) => {
    const { value } = e.target;

    this.setState({
      selectedDate: value,
      calendarDate: value,
    });
    // searchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        this.setState({
          startDate: formatDateforPicker(new Date()),
          endDate: formatDateforPicker(new Date()),
        });

        break;
      case 'This Week':
        // get meetings for this week
        this.setState({
          startDate: formatDateforPicker(moment().startOf('week').toDate()),
          endDate: formatDateforPicker(moment().endOf('week').toDate()),
        });

        break;
      case 'This Month':
        // get meetings for this month
        this.setState({
          startDate: formatDateforPicker(moment().startOf('month').toDate()),
          endDate: formatDateforPicker(moment().endOf('month').toDate()),
        });
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        this.setState({
          startDate: formatDateforPicker(this.state.calendarDate),
          endDate: formatDateforPicker(this.state.calendarDate),
        });
        break;
      case 'All Cases':
        // get meetings for the day selected in the calendar
        this.setState({
          startDate: '',
          endDate: '',
        });
        break;
      default:
        break;
    }
  };

  setApproved = () => {
    this.setState({
      requestButton: 'Approved',
    });
  };

  getApproval = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      this.setState({ initiatedreq: _records });
    }
  };

  getWithdrawal = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      console.log(_records, '_records');
      this.setState({ withdrawalList: _records, loading: false });
      this.setState({ holder: _records, loading: false });
      
      this.props.recordAssetWithdrawLength({ loadingWithdrawal: false });
      this.props.recordAssetWithdrawLength({ length: _records.length });
    }
  };

  filterReviewsOnApprover = () => {
    this.setState({
      initiatedreq: this.state.initiatedreq.filter(
        (rec) => rec.id !== parseInt(this.state.requestId),
      ),
    });
    Get(URLAPI.requestApprovals.Get, this.getApproval);
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    try {
      Get('api/assigneditems/withdrawalcategory', this.getCategoryList);
      Get('api/assigneditems/withdrawallist', this.getWithdrawal);
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState?.props?.searchVal.toUpperCase !== this?.props?.searchVal.toUpperCase) {
    if (prevProps?.searchVal !== this?.props?.searchVal) {
      // Write logic here.
       let val = this?.state?.holder?.filter((item)=>(
        item.assignedTo.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) ||
        item.itemName.toUpperCase().includes(this?.props?.searchVal.toUpperCase()) 
      ))
    this.setState({withdrawalList:val})
    console.log(val)

    }



    // this.reorderTable()

 }
 reorderTable = ()=>{
  // console.log(this.state.withdrawalList)
  
//  let val =  this?.state?.withdrawalList.filter((item)=>
//     item.assignedTo.includes(this?.props?.searchVal) 
//     // item.itemName.includes( this?.props?.searchVal)
//     )
// console.log(val)
    this.setState({withdrawalList:this.val})
 }


  getCategoryList = (data) => {
    // console.log(data, 'dada category list');
    this.setState({ categoryListData: data });
  };

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };
  returnRequestButtonState = () => {
    this.setState({
      requestButton: 'Requests',
    });
  };

  handleGetAvailableItems = (itemCategoryId) => {
    Get(`api/check/availableitems/${itemCategoryId}`, this.getItems);
  };

  getItems = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      console.log(_records, '_records');
      const multiSelectData = [];

      _records.forEach((element) => {
        multiSelectData.push({ name: element.itemName, id: element.id });
      });

      this.setState({ availableItems: multiSelectData, loadRequest: false });
    }
  };

  validateForm = (state) => {
    const { comment, withdrawalCategoryId, currentValue } = state;

    if (!parseFloat(currentValue))
      return { status: true, message: 'Please, Enter a current value' };
    if (!parseFloat(withdrawalCategoryId) || parseFloat(withdrawalCategoryId) === 0)
      return { status: true, message: 'Please, Select a withdrawal category' };
    if (!comment) return { status: true, message: 'Please, enter your comment' };
    return false;
  };

  onWithdraw = () => {
    this.setState({ loadComment: true });
    const { comment, withdrawalCategoryId, currentValue } = this.state;
    let error_data = this.validateForm({ comment, withdrawalCategoryId, currentValue });

    if (error_data.status && error_data.status === true) {
      this.setState({
        loadComment: false,
      });

      return notification({
        title: 'Error',
        message: error_data.message,
        type: 'danger',
      });
    }

    console.log({ comment, withdrawalCategoryId, currentValue: parseFloat(currentValue) }, 'withdrawalCategoryId >>>');

    Put(
      {
        comment,
        withdrawalCategoryId: parseFloat(withdrawalCategoryId),
        currentValue: parseFloat(currentValue),
      },
      `api/assigneditems/withdraw?id=${this.state.itemWithdrawalId}`,
      this.handleWithdrawalResult,
    );
  };

  handleWithdrawalResult = (res) => {
    console.log(res, 'res from asset withdrawal >>>')
    if (res.code === 1) {
      this.setState({
        visibleWithdrawalComment: false,
        loadComment: false,
        loading: true,
        comment: '',
      });
      successMessage(res.message);
      // Get('api/assetassign/pendingassignlist', this.getApproval);
      Get('api/assigneditems/withdrawallist', this.getWithdrawal);
    } else {
      errorMessage(res.message);
      this.setState({ loadComment: false, visibleWithdrawalComment: false, comment: '' });
    }
  };

  onAssign = () => {
    this.setState({ loadComment: true });
    const { action, comment } = this.state;

    Put(
      {
        itemRequestId: this.state.itemRequestId,
        assignedAssetItemId: this.state.assignedAssetItemId,
        action,
        comment,
      },
      'api/assetassign/assign',
      this.handleAssignResult,
    );
  };

  handleAssignResult = async (res) => {
    if (res.code === 1) {
      this.setState({
        visibleComment: false,
        loadComment: false,
        loading: true,
        comment: '',
      });
      successMessage(res.message);
      // Get('api/assetassign/pendingassignlist', this.getApproval);
      Get('api/assigneditems/withdrawallist', this.getWithdrawal);
    } else {
      errorMessage(res.message);
      this.setState({ loadComment: false, visibleComment: false });
    }
  };
  handleAccordion = async (value) => {
    const { withdrawal, request } = this.state;
    // console.log(value, 'value was here');
    if (value === 'Asset Request') {
      this.setState({ request: !request });
    }

    if (value === 'Asset Withdrawal') {
      this.setState({ withdrawal: !withdrawal });
    }
  };

  renderMessage = (value) => {
    if (this.state.requestButton === 'Requests')
      return (
        <>
          <a
            href='#'
            onClick={() => this.handleAccordion(value)}
            className='w-100 flex'
            style={{ textDecoration: 'none', color: 'black',backgroundColor: 'white'  }}>
            <p style={{ fontSize: 15 }}>{value}</p>
          </a>
        </>
      );
  };

  

  pendingRequestRecordLength = (val) => {
    this.setState({ pendingRequestLength: val.len, loadingRequest: val.loading });
  };

  render() {
    const { sequence, button, loading, loadTable, loadForm, approval, visibleDetails, visible } =
      this.state;

      console.log(this.props.searchVal)
    return (
      <>
        <div className='row'>
          <div className='col-12'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '25px 15px',
                marginLeft: 10,
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#F0F0F0'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Request Officer</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assigned By</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Date Assigned</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assignee</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Assignee MDA</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Item Name</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Serial Number</p>
                  </div>
                  {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Item Title</p>
                  </div> */}
                  {/* <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Request Date</p>
                  </div> */}
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>

              {this.state.loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <div className='table-body no-border'>
                  {this.state.withdrawalList.length === 0 ? (
                    <>
                      <div className='table-row no-border'>
                        <div
                          // key={i}
                          className='td font-sz-13'
                          style={{
                            ...tableBorder,
                          }}>
                          There are no Asset Withdrawal information
                        </div>
                      </div>
                    </>
                  ) : null}
                  {this.state.withdrawalList.map((value, i) => {
                    return (
                      <div className='table-row no-border'>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.assignedTo}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.assignedBy}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.dateAssigned}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.beneficiary}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.mda}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.itemName}
                        </div>
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.serialNumber}
                        </div>
                        {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.requestedItemTitle}
                        </div> */}
                        {/* <div className='td font-sz-13' style={{ ...tableBorder }}>
                          {value.requestDate}
                        </div> */}
                        <div className='td font-sz-13' style={{ ...tableBorder }}>
                          <button
                            data-tip
                            data-for='view-details'
                            type='button'
                            className='pointer td actions'
                            onClick={() => {
                              const {
                                assignedBy,
                                requestedItemTitle,
                                assignedTo,
                                requestedItemDetail,
                                itemName,
                                beneficiary,
                                dateAssigned,
                                assignedStatus,
                                assignComment,
                                serialNumber,
                                requestDate,
                                approvers,
                                supportingDoc,
                              } = value;
                              this.setState({
                                visibleWithdrawalDetails: true,
                                assignedBy,
                                requestedItemTitle,
                                assignedTo,
                                requestedItemDetail,
                                itemName,
                                beneficiary,
                                dateAssigned,
                                assignedStatus,
                                assignComment,
                                serialNumber,
                                requestDate,
                                approvers,
                                supportingDoc,
                              });
                            }}>
                            <ImEye />
                          </button>
                          <ReactTooltip id='view-details' type='success' effect='solid'>
                            <span>View Details</span>
                          </ReactTooltip>
                          {'     '}
                          <button
                            style={{ backgroundColor: 'red' }}
                            data-tip
                            data-for='withdraw'
                            type='button'
                            className='pointer td actions'
                            onClick={() => {
                              this.setState({
                                itemWithdrawalId: value.id,
                                assetValue: value.assetValue,
                                currentValue: '',
                                visibleWithdrawalComment: true,
                              });
                            }}>
                            <CgPlayListRemove />
                          </button>
                          <ReactTooltip id='withdraw' type='error' effect='solid'>
                            <span>Withdraw</span>
                          </ReactTooltip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.visibleComment && (
          <AssignAssetFormModal
            loadComment={this.state.loadComment}
            // categoryList={this.state.categoryList}
            handleAssign={() => this.onAssign()}
            comment={this.state.comment}
            handleComment={(value) => this.setState({ comment: value })}
            closeModal={() => this.setState({ visibleComment: false })}
          />
        )}

        {this.state.visibleWithdrawalComment && (
          <AssetWithdrawalFormModal
            loadComment={this.state.loadComment}
            categoryListData={this.state.categoryListData}
            assetValue={this.state.assetValue}
            currentValue={this.state.currentValue}
            handleAssign={() => this.onWithdraw()}
            comment={this.state.comment}
            categoryList={this.state.categoryList}
            handleCurrentValue={(value) => this.setState({ currentValue: value })}
            handleCategoryList={(value) => {
              console.log(value[0].id, 'value was here');
              this.setState({ withdrawalCategoryId: value[0].id });
            }}
            handleComment={(value) => this.setState({ comment: value })}
            closeModal={() => this.setState({ visibleWithdrawalComment: false, comment: '' })}
          />
        )}

        {this.state.visibleRequestDetails && (
          <ViewRequestDetails
            requestedBy={this.state.requestedBy}
            requestTitle={this.state.requestTitle}
            beneficiary={this.state.beneficiary}
            requestDetail={this.state.requestDetail}
            itemCount={this.state.itemCount}
            dateSubmitted={this.state.dateSubmitted}
            lastUpdated={this.state.lastUpdated}
            supportingDoc={this.state.supportingDoc}
            approvers={this.state.approvers}
            assetItems={this.state.assetItems}
            closeModal={() => this.setState({ visibleRequestDetails: false })}
          />
        )}

        {/* view withdrawal details */}
        {this.state.visibleWithdrawalDetails && (
          <ViewWithdrawalDetails
            requestedItemTitle={this.state.requestedItemTitle}
            requestedItemDetail={this.state.requestedItemDetail}
            itemName={this.state.itemName}
            beneficiary={this.state.beneficiary}
            dateAssigned={this.state.dateAssigned}
            assignedTo={this.state.assignedTo}
            assignedStatus={this.state.assignedStatus}
            assignedBy={this.state.assignedBy}
            assignComment={this.state.assignComment}
            serialNumber={this.state.serialNumber}
            requestDate={this.state.requestDate}
            supportingDoc={this.state.supportingDoc}
            approvers={this.state.approvers}
            closeModal={() => this.setState({ visibleWithdrawalDetails: false })}
          />
        )}

        {/* Assign approvers */}
        {visible && (
          <div class='col-3 m-t-10'>
            <PureModal
              header='Assign Asset'
              footer={
                <div>
                  <button
                    onClick={() =>
                      this.setState({
                        visible: false,
                      })
                    }>
                    Cancel
                  </button>
                </div>
              }
              isOpen={this.state.visible}
              closeButton='close'
              closeButtonPosition='bottom'
              onClose={() => {
                this.setState({
                  visible: false,
                });
              }}>
              <Multiselect
                options={this.state.availableItems}
                displayValue='name'
                placeholder=''
                style={{
                  chips: {
                    background: 'gray',
                    'white-space': 'normal',
                  },
                  multiselectContainer: {
                    color: 'gray',
                    'background-color': 'white',
                    'margin-bottom': '10px',
                    'white-space': 'normal',
                  },
                }}
                // loading
                emptyRecordMsg=''
                // onSearch={(val) => {
                //   this.searchApprovers(val);
                // }}
                onSelect={(selectedList, selectedItem) => {
                  const modulesList = selectedList.map((item) => item.id);

                  if (modulesList.length > this.state.itemQuantity) {
                    return errorMessage(
                      `You can't add more than ${this.state.itemQuantity} item quantity`,
                    );
                  }

                  console.log(modulesList, 'modulesList >>>>', selectedList, 'selectedList >>>');
                  this.setState({ assignedAssetItemId: modulesList });
                }}
                onRemove={(selectedList, removedItem) => {
                  const modulesList = this.state.assignedAssetItemId.filter(
                    (item) => item !== removedItem.id,
                  );
                  this.setState({ assignedAssetItemId: modulesList });
                }}
              />
              <br />
              {this.state.loadRequest ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <>
                  <button
                    className='btn'
                    type='button'
                    onClick={() => {
                      // check if itemcategory is empty
                      if (this.state.assignedAssetItemId.length === 0) {
                        return notification({
                          title: 'No Asset Assigned',
                          message: 'You need to assign Asset to continue',
                          type: 'danger',
                        });
                      }

                      this.setState({
                        visible: false,
                        visibleComment: true,
                        action: 1,
                      });
                      // this.onAssign(1);
                    }}>
                    Approve
                  </button>
                  {/* <br /> */}
                  {'       '}
                  <button
                    className='btn'
                    style={{ backgroundColor: 'red' }}
                    type='button'
                    onClick={() => {
                      this.setState({
                        visible: false,
                        visibleComment: true,
                        action: 0,
                      });
                      // this.onAssign(0);
                    }}>
                    Decline
                  </button>
                </>
              )}
            </PureModal>
          </div>
        )}
      </>
    );
  }
}

export default AssetAssignWithdrawal;
