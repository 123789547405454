/* eslint-disable */

import React, { Component } from 'react';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import Select from 'react-dropdown-select';
import Loader from 'react-loader-spinner';
import { IoMdClose } from 'react-icons/io';
import { BsUpload } from 'react-icons/bs';
import LoadingSpinner from '../../resources/loadingSpinner';
// import Loader from '../../assets/svg/loading.svg';
import TextInput from '../inputs/TextInput';
// import { toggleScroll } from '../../utility/general';

import { URLAPI } from '../../utility/ApiMethods';
import { Get, Put } from '../../utility/fetch';
import TextareaInput from '../inputs/TextareaInput';

import Cookies from 'js-cookie';

class AssetWithdrawalFormModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      requestId: 0,
      title: '',
      details: '',
      costImplication: 0,
      durationDate: '',
      comment: '',
      action: '',
      loading: false,
      approveButton: 'Approve',
      DeclineButton: 'Decline',
      req: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validateForm = () => {
    const { comment } = this.state;
    if (!comment) return 'Please, enter a response';

    return null;
  };

  submitForm = (e) => {
    e.preventDefault();
    const error_data = this.validateForm();

    if (error_data) {
      errorMessage(error_data);
      this.props.closeModal();
    } else if (error_data !== this.validateForm) {
      let request = {
        Id: parseInt(this.state.requestId),
        Comment: this.state.comment,
        Action: 'Approve',
        //status: 0,
      };

      if (this.state.approveButton === 'Approve') {
        this.approveMemo(request);
      }
    }
  };
  submitForm2 = (e) => {
    e.preventDefault();
    const error_data = this.validateForm();

    if (error_data) {
      errorMessage(error_data);
      this.props.closeModal();
    } else if (error_data !== this.validateForm) {
      let request = {
        id: parseInt(this.state.requestId),
        comment: this.state.comment,
        action: 'Decline',
        //status: 0,
      };

      if (this.state.DeclineButton === 'Decline') {
        this.approveMemo(request);
      }
    }
  };

  approveMemo = (memo) => {
    if (this.state.requestId) {
      this.setState({ loading: true }, () => {
        Put(
          memo,
          URLAPI.requestApprovals.Put + '/' + this.state.requestId,
          this.onSubmitAfterApproval,
        );
      });
    }
  };

  onSubmitAfterApproval = (data) => {
    // console.log('entered submit after approval function')

    if (data.code === 1) {
      this.setState({ req: this.props.filterReviewsOnApprover, loading: false });
      successMessage(data.message);
      Get(URLAPI.requestApprovals.Get, this.props.getApproval);

      this.props.closeModal();
    } else if (data.code === 0) {
      this.props.closeModal();
      errorMessage(data.message);

      this.setState({
        ...this.state,
        loading: false,
      });
    } else {
      errorMessage(data.message);
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  // componentDidMount() {
  //   this._isMounted = true;
  //   this.setState({
  //     requestId: this.props.requestId,
  //     title: this.props.title,
  //     category: this.props.category,
  //     details: this.props.details,
  //     durationDate: this.props.date,
  //     costImplication: this.props.cost,
  //   });
  //   Get(URLAPI.requestApprovals.Get, this.props.getApproval);
  // }

  closeModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div
          className='overlay'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 2px 6px #0000000A',
          }}>
          <div>
            <IoMdClose className='close-btn pointer' onClick={this.props.closeModal} />
            <div className='modal-box max-w-400 pd-10'>
              <div>
                <div
                  role='presentation'
                  onClick={() => {}}
                  // className='user-avater-details flex flex-v-center pointer'
                >
                  <div className='col-16'>
                    <div class='form-group'>
                      <h3 className='name'>Please enter the following details to continue </h3>
                      <div class='row '>
                        <div class='col-6 m-t-10'>
                          <TextInput
                            label='Asset Value (&#8358;)'
                            className=''
                            type='text'
                            value={this.props.assetValue || 0}
                          />
                        </div>

                        <div class='col-6 m-t-10'>
                          <TextInput
                            label='Current Value (&#8358;)'
                            className=''
                            type='number'
                            value={this.props.currentValue || 0}
                            onChange={(e) => this.props.handleCurrentValue(e.target.value)}
                          />
                        </div>
                        <div class='col-12 m-t-10'>
                          <label>Withdrawal Category</label>
                          <Select
                            options={this.props.categoryListData && this.props.categoryListData}
                            valueField='id'
                            labelField='name'
                            searchable={false}
                            values={[]}
                            onChange={(values) => this.props.handleCategoryList(values)}
                          />
                        </div>
                        <div class='col-12 m-t-10'>
                          <TextareaInput
                            rows={3}
                            label='Comment'
                            className=''
                            value={this.props.comment}
                            onChange={(e) => this.props.handleComment(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.props.loadComment ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <button
                  className='btn btn-large p-r w-100 flex flex-v-center flex-h-center justify-content-center m-t-10'
                  //disabled={disableBtn}
                  onClick={this.props.handleAssign}
                  style={{ height: '43px' }}>
                  {/* <p style={{ margin: 'auto 15px auto' }}>{props.btnText}</p>
                {disableBtn && <img src={LoaderSrc} alt='Loading...' />} */}
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>

        {/* {loading ? <LoadingSpinner /> : null} */}
      </>
    );
  }
}

export default AssetWithdrawalFormModal;
