/* eslint-disable */
import React from 'react';
import { Switch, Route } from 'react-router';
/* eslint-disable */
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';
//import SessionHoc from '../components/hoc/SessionHOC';
import Dashboard from '../components/pages/Dashboard';
import logout from '../utility/logout';
import Home from '../components/pages/Home';
import AssetProfiler from '../components/pages/AssetProfiler';
import AssetReview from '../components/pages/AssetReview';
import AssetDisposal from '../components/pages/AssetDisposal';
import AssetWithdrawal from '../components/pages/AssetWithdrawal';

// Request Management App
const AssignAsset = React.lazy(() => import('../components/pages/AssignAsset'));
const AssetRequest = React.lazy(() => import('../components/pages/AssetRequest'));
const Report = React.lazy(() => import('../components/pages/Report'));
const Settings = React.lazy(() => import('../components/pages/Settings'));
const History = React.lazy(() => import('../components/pages/History'));
const AssignExistingAsset = React.lazy(() => import('../components/pages/AssignExistingAsset'));

export default pure(() => (
  <Switch>
    <ProtectedRoute path='/admin-portal/' exact component={Dashboard} />
    <ProtectedRoute path='/admin-portal/home' exact component={Home} />
    <ProtectedRoute path='/admin-portal/asset/assign-asset' exact component={AssignExistingAsset} />
    <ProtectedRoute path='/admin-portal/asset/asset-withdrawal' exact component={AssetWithdrawal} />
    <ProtectedRoute path='/admin-portal/asset/asset-request' exact component={AssetRequest} />
    <ProtectedRoute path='/admin-portal/asset/asset-asset' exact component={AssetRequest} />

    <ProtectedRoute path='/admin-portal/asset/asset-reviews' exact component={AssetReview} />
    <ProtectedRoute path='/admin-portal/asset/asset-disposal' exact component={AssetDisposal} />

    <ProtectedRoute path='/admin-portal/asset/history' exact component={History} />
    <ProtectedRoute path='/admin-portal/asset/report' exact component={Report} />
    <ProtectedRoute path='/admin-portal/asset/settings' exact component={Settings} />
    <ProtectedRoute path='/admin-portal/asset/asset-profiler' exact component={AssetProfiler} />
    <ProtectedRoute path='/admin-portal/asset/assign-asset' exact component={AssetProfiler} />
    <ProtectedRoute path='/admin-portal/asset/logout' exact component={logout} />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
