export const URLAPI = {
  Auth: {
    Post: 'api/Auth/signin',
    PostId: 'api/Auth/signin',
  },
  itemAssetCategory: {
    Get: 'assets/category/list',
    Post: 'assets/category/save',
    Delete: 'assets/category/delete',
    Put: 'assets/category/update'
  },
  disposalCategory: {
    Get: 'assets/disposal/category/list',
    Post: 'assets/disposal/category/save',
    Put: 'assets/disposal/category/update',
    Delete: 'assets/disposal/category/delete'
  },
  ratio: {
    GetCategory: 'api/assets/ratio/categoryitems',
    GetRatioList: 'api/assets/ratio/list',
    Post:'api/assets/ratio/save',
    Put: 'api/assets/ratio/update',
    Delete: 'api/assets/ratio/delete'
  },
  approval: {
    GetRoles: 'roles',
    GetApprovalList: 'assets/approval/list',
    Post: 'assets/approval/save',
    Put: 'assets/approval/update',
    Delete: 'assets/approval/delete'
  },
  profiler: {
    getSavedList: 'api/profiler/savedlist',
    delete: 'api/profiler/delete',
    post: 'api/profiler/save',
    GetCategoryItems: 'api/profiler/categoryitems',
    GetApprovalPending: 'api/profiler/pendingapprovallist',
    SubmitProfiler: 'api/profiler/submit',
    UpdateProfiler: 'api/profiler/update',
    SupportingDocDelete: 'api/profiler/supportingdoc',
    ApproveOrDecline: 'api/profiler/approve'
  },
  request: {
    getApprovalPendingAssetRequest: 'api/request/pendingapprovallist'
  }
};
