/* eslint-disable */
import React, { Component } from 'react'
import TextInput from '../inputs/TextInput';
import FormSearchInput from '../inputs/FormSearchInput';
import SearchInput from '../inputs/SearchInput';
import { BiMailSend, BiTrash } from "react-icons/bi";
import { IoMdClose } from 'react-icons/io';
import TextareaInput from '../inputs/TextareaInput';
import Banner from '../../assets/images/banner.png'
const tableBorder = {
    border: 'none',
    borderRight: '1px solid #0000000A',
    borderBottom: '1px solid #0000000A',
  };

class AssetProfilerDetailsModal extends Component {
   constructor(props){
       super(props);

       this.state={
        itemId: 0,
        name: '',
        description: '',
        manufacturer: '',
        quantity: '',
        deliveryDate: '',
        assetSerial: '',
        category:''
       }
   }


   componentDidMount(){
       this.setState({
           itemId:this.props.itemId,
           name:this.props.name,
           description:this.props.description,
           manufacturer:this.props.manufacturer,
           quantity:this.props.quantity,
           deliveryDate:this.props.deliveryDate,
           assetSerial:this.props.assetSerial,
           category:this.props.category
       })
   }

    render() { 
        return ( <>
        
        <div
                className='overlay'
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0px 2px 6px #0000000A',
                }}>
                <div className='flex flex-v-center flex-h-center flex-direction-column'>
                    <div
                        className='modal-box'
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            position: 'relative',
                            width: '850px',
                        }}>
                        <div className='content no-padding '>
                            <IoMdClose
                                className='pointer m-l-5'
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '100%',
                                    fontSize: '24px',
                                    color: '#fff',
                                    width: '40px',
                                    height: '40px',
                                    backgroundColor: '#F74F11',
                                    padding: '3px',
                                }}
                                onClick={this.props.closeModal}
                            />
                            <div className={`w-100 ${this.props.className}`}>
                                <div
                                    className='w-100 flex space-between'
                                    style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                                    <p>Asset Entry</p>
                                </div>
                                <div className="col-12">

                                    <div class='form-group'>
                                        <div class='row '>
                                            <div class='col-8 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='name'
                                                    label='Item name'
                                                    value={this.state.name}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                            <div class='col-4 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='category' 
                                                    label='Category'
                                                    value={this.state.category}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class='form-group'>
                                        <div class='row '>
                                            <div class='col-6 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='manufacturer'
                                                    label='Manufacturer'
                                                    value={this.state.manufacturer}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                            <div class='col-3 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='cost'
                                                    label='supplier'
                                                    value={this.state.manufacturer}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                            <div class='col-3 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='cost'
                                                    label='Unit Cost'
                                                    value={`${'N ' + this.state.quantity}`}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class='form-group'>
                                        <div class='row '>
                                            <div class='col-6 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='cost'
                                                    label='Product Serial Number'
                                                    value={this.state.assetSerial}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                            <div class='col-3 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='cost'
                                                    label='Quantity'
                                                    value={this.state.quantity}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                            <div class='col-3 m-t-10'>
                                                <TextInput
                                                    className='w-100 m-b-10'
                                                    name='cost'
                                                    label='Delivery Date'
                                                    value={this.state.deliveryDate}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class='form-group'>
                                        <div class='row '>
                                            <div class='col-12 m-t-10'>
                                                <TextareaInput
                                                    className='w-100 m-b-10'
                                                    name='cost'
                                                    label='Item Description'
                                                    value={this.state.description}
                                                    type='text'
                                                    onChange={(e) => this.handleChange(e)}
                                                    color='rgba(112,112,112,.7)'
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
  
                            <div className='col-12' >
                          <div className="flex ">
                          <BiMailSend /> <p className="name" style={{ fontWeight: "bold" }}><a href={`${"#" + '#'}`} download={this.state.docs} target="_blank" className="">Picture of Reception/Waiting Area</a></p><BiTrash /> 
                              </div> 
                              <br/>
                              <div class="bg-image"></div>
                    </div>     
                        </div>
                    </div>
                </div>
            </div>
       
        </> );
    }
}
 
export default AssetProfilerDetailsModal;