import React, { Component } from 'react';
import LocationDetails from '../layouts/LocationDetails';
import SettingStrip from '../layouts/SettingStrip';

// Icons
//import { GiRecycle } from 'react-icons/gi';

class Review extends Component {
  state = {
    loading: false,
    pendingProfilerLength: 0,
    pendingRequestLength: 0,
    pendingWithdrawalLength: 0,
    loadingProfiler: true,
    loadingRequest: true,
  };

  updateRatioCategory = (value) => {
    console.log(value, 'value was also here >>>');

    if (value === 'update') {
      this.setState({ loading: true });
    }
    if (value === 'done') {
      this.setState({ loading: false });
    }
  };

  pendingProfilerRecordLength = (val) => {
    console.log(val, 'val was here');
    this.setState({ pendingProfilerLength: val.len, loadingProfiler: val.loading });
  };

  pendingRequestRecordLength = (val) => {
    this.setState({ pendingRequestLength: val.len, loadingRequest: val.loading });
  };

  pendingWithdrawalRecordLength = (val) => {
    this.setState({ pendingWithdrawalLength: val.len, loadingRequest: val.loading });
  };

  render() {
    return (
      <>
        <div className='w-100'>
          <div className='w-100 flex flex-v-center space-between'>
            <h3>
              <b>Pending Asset Disposal</b>
            </h3>
            <LocationDetails />
          </div>

         
         {/* <div className='m-t-20'>
            <SettingStrip
              updateRatioCategory={this.updateRatioCategory}
              pendingProfilerRecordLength={this.pendingProfilerRecordLength}
              title={`Asset Entry (${this.state.pendingProfilerLength})`}
              bodyType='assetprofiler'
              tableHeaders={['Category', 'Description', 'Updated By', 'Last Updated']}
              bodyBGColor='white'
            />
    </div>*/}

          {/* <div className='m-t-20'>
            <SettingStrip
              pendingRequestRecordLength={this.pendingRequestRecordLength}
              title={`Asset Request (${this.state.pendingRequestLength})`}
              bodyType='assetrequest'
              tableHeaders={['Title', 'Approval Sequence', 'Last Updated', 'Last Updated by:']}
              bodyBGColor='white'
            />
          </div> */}

          <div className='m-t-20'>
            <SettingStrip
            active={'active'}
            height={'100%'}
              pendingWithdrawalLength={this.pendingWithdrawalRecordLength}
              title={`Asset Disposal (${this.state.pendingWithdrawalLength})`}
              bodyType='assetwithdrawal'
              tableHeaders={['Title', 'Approval Sequence', 'Last Updated', 'Last Updated by:']}
              bodyBGColor='white'
            />
          </div>
        </div>
      </>
    );
  }
}

export default Review;
