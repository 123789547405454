/* eslint-disable */
import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import HoverImage from 'react-hover-image';
import TextInput from '../inputs/TextInput';
import FormSearchInput from '../inputs/FormSearchInput';
// import SearchInput from '../inputs/SearchInput';
import { IoMdClose } from 'react-icons/io';
import TextareaInput from '../inputs/TextareaInput';
import { HiOutlineUpload } from 'react-icons/hi';
import { BiMailSend, BiTrash } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import { BsSearch } from 'react-icons/bs';
import { FileUpload } from '../../utility/fetch';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import SearchInput, { createFilter } from 'react-search-input';

import imageJpeg from '../../assets/images/jpeg.png';
import imagePdf from '../../assets/images/pdf.png';
import imageFile from '../../assets/images/file.png';

class AssetProfilerFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileMessage: ' Upload Asset(s) picture/Documentation',
      itemName: '',
      description: '',
      supplier: '',
      manufacturer: '',
      deliveryDate: '',
      location: '',
      unitCost: 0,
      serialNumber: '',
      categoryId: 0,
      quantity: 0,
      action: '',
      supportingDoc: [],
      imageUrl: '',
      loading: false,
      openModal: false,
      openApproversModal: false,
      approverId: [],
    };
  }

  fileData = () => {
    if (!this.state.selectedFile) {
      return (
        <div>
          <br />
          {(this.state.supportingDoc || this.props.supportingDoc).map((file, i) => (
            <>
              <ReactTooltip id={`deleteImage-${i}`} place='bottom' effect='solid'>
                {`click to remove ${file.docTitle}`}
              </ReactTooltip>
              <Link onClick={() => this.handleImageDelete(i)}>
                {file.docPath.slice(((file.docPath.lastIndexOf('.') - 1) >>> 0) + 2) === 'jpeg' ||
                'jpg' ? (
                  <div id='preview' data-tip data-for={`deleteImage-${i}`}>
                    <img
                      id='preview'
                      src={imageFile}
                      alt={file.docTitle}
                      style={{ height: 200, width: 200 }}
                    />
                    <h3 style={{ paddingLeft: 65, textDecoration: 'none' }}>{file.docTitle}</h3>
                  </div>
                ) : file.docPath.slice(((file.docPath.lastIndexOf('.') - 1) >>> 0) + 2) ===
                  'pdf' ? (
                  <div id='preview' data-tip data-for={`deleteImage-${i}`}>
                    <img
                      id='preview'
                      src={imageFile}
                      alt={file.docTitle}
                      style={{ height: 200, width: 200 }}
                    />
                    <h3 style={{ paddingLeft: 65, textDecoration: 'none' }}>{file.docTitle}</h3>
                  </div>
                ) : (
                  <div id='preview' data-tip data-for={`deleteImage-${i}`}>
                    <img
                      id='preview'
                      src={imageFile}
                      alt={file.docTitle}
                      style={{ height: 200, width: 200 }}
                    />
                    <h3 style={{ paddingLeft: 65, textDecoration: 'none' }}>{file.docTitle}</h3>
                  </div>
                )}
              </Link>
            </>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <span style={{ color: 'red' }}>Supported docs! (PDF, WORD, EXCEL & JPG)</span>
        </div>
      );
    }
  };

  numberWithCommas(x) {
    if (x?.toString().includes(',')) {
      return x
        ?.toString()
        .replace(/,/g, '')

        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  numberWithOutCommas(x) {
    if (x?.toString().includes(',')) {
      console.log('klkl');

      return x.replace(/,/g, '');
    } else return x;
  }

  handleImageDelete = (index) => {
    const arr = this.state.supportingDoc;
    arr.splice(index, 1);
  };

  onFileChange = (e) => {
    const [file] = e.target.files;

    const newFile = [];
    // const newObj = {
    //   docPath: URL.createObjectURL(file),
    //   docTitle: file.name,
    // };
    // newFile.push(newObj, ...this.state.supportingDoc);
    this.setState({ loading: true });

    const data = new FormData();
    data.append('files', e.target.files[0]);

    FileUpload(data, (response, err) => {
      if (response.code === 1) {
        const newObj = {
          docPath: response.doclink,
          docTitle: file.name,
        };

        newFile.push(newObj, ...this.state.supportingDoc);
        this.setState({
          supportingDoc: newFile,
          loading: false,
        });
      } else {
      }
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNumberChange = (e) => {
    this.setState({
      [e.target.name]: Number(e.target.value),
    });
  };
  handleNumberCommaChange = (e) => {
    this.setState({
      [e.target.name]: this.numberWithCommas(e.target.value),
    });
  };

  // "2021-06-18"

  //   componentDidMount() {
  //     Get(URLAPI.approval.GetRoles, this.getRoleList);
  //   }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  handleSearchChange = (e) => {
    this.props.searchDocument(e.target.value);
  };

  handleApproverSearchChange = (value) => {
    this.props.searchApprovers(value);
  };

  saveSearchFile = (value) => {
    const newObj = {
      docPath: value.fileurl,
      docTitle: value.title,
    };

    const newFile = [];

    newFile.push(newObj, ...this.state.supportingDoc);
    this.setState({
      supportingDoc: newFile,
      openModal: false,
    });
  };

  saveApprover = (value) => {
    const oldApprovers = this.state.approverId;

    oldApprovers.push(value);

    this.setState({
      approverId: oldApprovers,
    });
  };

  render() {
    const { openModal, openApproversModal } = this.state;
    return (
      <>
        <div
          className='overlay'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 2px 6px #0000000A',
          }}>
          <div className='flex flex-v-center flex-h-center flex-direction-column'>
            <div
              className='modal-box'
              style={{
                boxShadow: '0px 2px 6px #0000000A',
                position: 'relative',
                width: '980px',
              }}>
              <div className='content no-padding '>
                <IoMdClose
                  className='pointer m-l-5'
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '100%',
                    fontSize: '24px',
                    color: '#fff',
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#F74F11',
                    padding: '3px',
                  }}
                  onClick={this.props.closeModal}
                />
                <div className={`w-100 ${this.props.className}`}>
                  <div
                    className='w-100 flex space-between'
                    style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                    <p>Asset Entry</p>
                  </div>

                  <div className='col-4 '>
                    {this.fileData()}
                    {(this.state.supportingDoc || this.props.supportingDoc).length === 0 ? (
                      <div
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '10%',
                          transform: 'translate(-50%, -50%)',
                        }}>
                        <p style={{ fontSize: 30 }}>Uploaded Assets will display here</p>
                        <div style={{ marginTop: 500 }} />
                      </div>
                    ) : null}
                  </div>

                  <div className='col-8'>
                    <div class='form-group'>
                      <div class='row '>
                        <div class='col-8 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='itemName'
                            label='Item name*'
                            value={this.state.itemName || this.props.itemName}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                        <div class='col-4 m-t-10'>
                          <label>Select Category*</label>
                          <select
                            className='w-100 m-b-10'
                            style={{
                              fontSize: 13,
                            }}
                            name='categoryId'
                            value={this.state.categoryId}
                            onChange={(e) => this.handleNumberChange(e)}>
                            <option>{this.props.categoryValue}</option>
                            {this.props.category &&
                              this.props.category.map((res) => (
                                <option value={res.id}>{res.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class='form-group'>
                      <div class='row '>
                        <div class='col-6 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='manufacturer'
                            label='Manufacturer*'
                            value={this.state.manufacturer || this.props.manufacturer}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                        <div class='col-3 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='supplier'
                            label='Supplier*'
                            value={this.state.supplier || this.props.supplier}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                        <div class='col-3 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='unitCost'
                            label='Asset Value/Unit Cost (&#8358;)*'
                            value={this.state.unitCost || this.props.unitCost || 0}
                            type='text'
                            onChange={(e) => this.handleNumberCommaChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                      </div>
                    </div>

                    <div class='form-group'>
                      <div class='row '>
                        <div class='col-12 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='serialNumber'
                            label={`Tag/Serial Number*`}
                            value={this.state.serialNumber || this.props.serialNumber}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                      </div>
                    </div>

                    <div class='form-group'>
                      <div class='row '>
                        <div class='col-3 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='quantity'
                            label='Quantity*'
                            value={this.state.quantity || this.props.quantity}
                            type='text'
                            onChange={(e) => this.handleNumberChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                        <div class='col-3 m-t-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='deliveryDate'
                            label='Delivery Date*'
                            value={this.state.deliveryDate || this.props.deliveryDate}
                            type='date'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                        <div class='col-4 m-t-10 m-l-10'>
                          <TextInput
                            className='w-100 m-b-10'
                            name='location'
                            label='Location*'
                            value={this.state.location}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                      </div>
                    </div>

                    <div class='form-group'>
                      <div class='row '>
                        <div class='col-12 m-t-10'>
                          <TextareaInput
                            className='w-100 m-b-10'
                            name='description'
                            label='Item Description*'
                            value={this.state.description || this.props.description}
                            type='text'
                            onChange={(e) => this.handleChange(e)}
                            color='rgba(112,112,112,.7)'
                          />
                        </div>
                      </div>
                    </div>

                    <div class='form-group'>
                      <div class='row '>
                        <div class='w-100 m-t-10 flex flex-v-center flex-h-center'>
                          {!this.props.loading && (
                            <>
                              <label
                                className='custom-file-upload '
                                style={{ backgroundColor: '#EFD66BF2', color: 'black' }}>
                                <input
                                  type='file'
                                  className='w-100 m-b-10'
                                  name='file'
                                  multiple
                                  onChange={this.onFileChange}
                                />
                                <HiOutlineUpload />
                                {this.state.fileMessage}
                              </label>
                              <div style={{ marginLeft: 10, marginRight: 10 }}>OR</div>
                              <label
                                className='custom-file-upload '
                                style={{ backgroundColor: '#EFD66BF2', color: 'black' }}
                                onClick={() => this.setState({ openModal: true })}>
                                <BsSearch /> Search from M-File
                              </label>
                            </>
                          )}
                        </div>

                        {this.state.openModal && (
                          <div class='col-3 m-t-10'>
                            <PureModal
                              header='Search Asset Pictures'
                              footer={
                                <div>
                                  <button onClick={() => this.setState({ openModal: !openModal })}>
                                    Cancel
                                  </button>
                                </div>
                              }
                              isOpen={this.state.openModal}
                              closeButton='close'
                              closeButtonPosition='bottom'
                              onClose={() => {
                                this.setState({ openModal: !openModal });
                              }}>
                              <TextInput
                                className='w-100 m-b-10'
                                name='text'
                                label='Search Documents'
                                value={this.state.fileType || this.props.fileType}
                                type='text'
                                onChange={(e) => this.handleSearchChange(e)}
                                color='rgba(112,112,112,.7)'
                              />
                              <br />
                              <h3>Results</h3>
                              <br />
                              {this.props.fileData.length === 0 &&
                              this.props.loadingModal === false ? (
                                <p>
                                  <i>empty results</i>
                                </p>
                              ) : (
                                <>
                                  {this.props.loadingModal ? (
                                    <Loader type='ThreeDots' color='green' height={50} width={50} />
                                  ) : (
                                    <>
                                      {this.props.fileData.map((value, i) => {
                                        return (
                                          <>
                                            <a
                                              href='#'
                                              onClick={() => this.saveSearchFile(value)}
                                              key={i}
                                              style={{ textDecoration: 'none' }}>
                                              <div className='mail'>
                                                <div className='from'>
                                                  {i + 1}. {value.title}
                                                </div>
                                                {/* <div className='subject'>{value.fileurl}</div> */}
                                              </div>
                                            </a>
                                            <hr style={{ width: '100%' }} />
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </>
                              )}
                            </PureModal>
                          </div>
                        )}

                        {/* search approvers */}
                        {this.state.openApproversModal && (
                          <div class='col-3 m-t-10'>
                            <PureModal
                              header='Add Asset Approvers'
                              footer={
                                <div>
                                  <button
                                    onClick={() =>
                                      this.setState({ openApproversModal: !openApproversModal })
                                    }>
                                    Cancel
                                  </button>
                                </div>
                              }
                              isOpen={this.state.openApproversModal}
                              closeButton='close'
                              closeButtonPosition='bottom'
                              onClose={() => {
                                this.setState({ openApproversModal: !openApproversModal });
                              }}>
                              <Multiselect
                                options={this.props.approverFileData}
                                displayValue='name'
                                placeholder=''
                                style={{
                                  chips: {
                                    background: 'gray',
                                    'white-space': 'normal',
                                  },
                                  multiselectContainer: {
                                    color: 'gray',
                                    'background-color': 'white',
                                    'margin-bottom': '10px',
                                    'white-space': 'normal',
                                  },
                                }}
                                // loading
                                emptyRecordMsg='Search for approvers'
                                onSearch={(val) => {
                                  this.handleApproverSearchChange(val);
                                }}
                                onSelect={(selectedList, selectedItem) => {
                                  const modulesList = selectedList.map((item) => item.id);
                                  this.setState({ approverId: modulesList });
                                }}
                                onRemove={(selectedList, removedItem) => {
                                  const modulesList = this.state.approverId.filter(
                                    (item) => item !== removedItem.id,
                                  );
                                  this.setState({ approverId: modulesList });
                                }}
                              />
                              <br />
                              <button
                                className='btn btn-large w-100'
                                type='button'
                                onClick={() => {
                                  this.props.onSubmit(
                                    {
                                      ...this.state,
                                    },
                                    'Submit',
                                  );
                                }}>
                                Submit Approvers
                              </button>
                            </PureModal>
                          </div>
                        )}
                      </div>
                    </div>

                    <br />
                    <p
                      style={{
                        color: this.props.validationCode === 1 ? 'green' : 'red',
                        fontSize: 15,
                      }}>
                      {this.props.validationStatus}
                    </p>

                    {this.state.loading || this.props.loading ? (
                      <Loader type='ThreeDots' color='green' height={50} width={50} />
                    ) : (
                      <div className='w-100 m-t-10 flex flex-v-center flex-h-center space-between'>
                        <button
                          className='btn btn-large w-49'
                          style={{ backgroundColor: '#43425D' }}
                          type='button'
                          onClick={() => {
                            this.setState(
                              {
                                ...this.state,
                                unitCost: +this.numberWithOutCommas(this.state.unitCost),
                              },
                              () => {
                               
                                this.props.onSubmit(
                                  {
                                    ...this.state,
                                  },
                                  'Save',
                                );
                              },
                            );
                          }}
                          // onClick={() => {
                          //   this.props.onSubmit(
                          //     {
                          //       ...this.state,
                          //     },
                          //     'Save',
                          //   );
                          // }}
                        >
                          Save Request
                        </button>
                        <button
                          className='btn btn-large w-49'
                          type='button'
                          Submit
                          onClick={() => {
                            this.setState(
                              {
                                ...this.state,
                                unitCost: +this.numberWithOutCommas(this.state.unitCost),
                              },
                              () => {
                                let k = {
                                  ...this.state,
                                };
                                this.props.onSubmit(
                                  {
                                    ...this.state,
                                  },
                                  'Submit',
                                );
                              },
                            );
                          }}>
                          Assign Asset
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AssetProfilerFormModal;
