/*eslint-disable */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import ButtonLoader from '../../assets/svg/loading.svg';
import { Post, Get, Delete, Put } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import moment from 'moment';
import { GrEdit } from 'react-icons/gr';
import { GiRecycle } from 'react-icons/gi';
import { GiTrashCan } from 'react-icons/gi';
import TextInput from '../inputs/TextInput';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import data from '../../data';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../resources/loadingSpinner';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};
const bottomBorder = {
  borderBottom: '.5px solid rgba(99,99,99,.6)',
};

class AssetCategoryForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      button: 'Save Record',
      requestId: 0,
      records: [],
      catRecords: [],
      loading: false,
      bgColor: 'white',
      loadTable: false,
      loadForm: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      // category:e.target.value,
      [e.target.name]: e.target.value,
    });
  };

  validateForm = () => {
    const { category } = this.state;

    if (!category) return true;
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loadTable: true,
      loadForm: true,
    });
    const { category, button, requestId } = this.state;

    let error_data = this.validateForm();

    if (error_data) {
      this.setState({
        loadTable: false,
        loadForm: false,
      });
      return errorMessage('Please, Enter Category');
    } else if (!error_data) {
      let request;

      // save new record
      if (button === 'Save Record') {
        request = {
          category,
        };
        this.onSubmitSave(request);
      }

      // update a new record
      if (button === 'Update') {
        request = {
          category,
          requestId,
        };
        this.onSubmitUpdate(request);
      }
    }
  };

  onSubmitSave = (data) => {
    Post(data, URLAPI.itemAssetCategory.Post, this.onSubmitAfterSave);
  };

  onSubmitAfterSave = (data) => {
    if (data.code === 1) {
      successMessage(data.message);
      this.props.categoryUpdate('update');
      this.setState({
        loadTable: true,
      });
      Get(URLAPI.itemAssetCategory.Get, this.getRequestCategory);
      this.setState({
        category: '',
        loadForm: false,
      });
      this.props.categoryUpdate('done');
    } else if (data.code === 0) {
      this.setState({
        category: '',
        loadTable: false,
        loadForm: false,
      });
      errorMessage(data.message);
    }
  };

  getRequestCategory = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];
      console.log(_records, '_records');
      this.setState({ catRecords: _records, loadTable: false });
    }
  };

  //handle delete record
  handleDeleteRecord(record) {
    deleteMessage(() => this.onDelete(record));
  }

  onDelete = (record) => {
    this.setState({ loadTable: true });
    Delete(URLAPI.itemAssetCategory.Delete + '/' + record.id, this.onAfterDelete);
  };

  onAfterDelete = (res) => {
    if (res.code === 1) {
      this.props.categoryUpdate('update');
      Get(URLAPI.itemAssetCategory.Get, this.getRequestCategory);

      successMessage(res.message);
      this.setState({ loadTable: false });
      this.props.categoryUpdate('done');
    } else if (res.code === 0) {
      this.setState({ loadTable: false, visible: false });
      errorMessage(res.message);
    } else {
      this.setState({
        loadTable: false,
      });
      errorMessage(res.message);
    }
  };

  onEdit = (item) => {
    if (item) {
      const { id, categoryName } = item;
      this.setState({
        button: 'Update',
        requestId: id,
        category: categoryName,
        bgColor: '#c2f542',
      });
    }
  };

  onSubmitUpdate = (record) => {
    if (record.requestId !== 0) {
      const { category, requestId } = this.state;
      Put({ category }, URLAPI.itemAssetCategory.Put + '/' + requestId, this.onSubmitAfterUpdate);
    }
  };

  onSubmitAfterUpdate = (data) => {
    if (data.code === 1) {
      successMessage(data.message);
      this.props.categoryUpdate('update');
      this.setState({ loadTable: true });
      Get(URLAPI.itemAssetCategory.Get, this.getRequestCategory);

      this.setState({ category: '', button: 'Save Record', loadForm: false, bgColor: 'white' });
      this.props.categoryUpdate('done');
    } else if (data.code === 0) {
      this.setState({
        loadTable: false,
        loadForm: false,
        category: '',
        button: 'Save Record',
        bgColor: 'white',
      });
      errorMessage(data.message);
    } else {
      this.setState({
        loadTable: false,
        loadForm: false,
        category: '',
        button: 'Save Record',
        bgColor: 'white',
      });
      errorMessage(data.message);
    }
  };

  handleCancel = () => {
    this.setState({
      category: '',
      button: 'Save Record',
      bgColor: 'white',
    });
  };

  componentDidMount() {
    this.setState({ loadTable: true });
    this._isMounted = true;
    try {
      Get(URLAPI.itemAssetCategory.Get, this.getRequestCategory);
    } catch (err) {
      this.setState({ loadTable: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { category, button, loading, loadTable, loadForm } = this.state;
    return (
      <>
        <div className='row'>
          <div className='col-4'>
            <div
              className='w-100  p-10 m-l-10 pd-b-0  h-10'
              style={{ background: this.state.bgColor }}>
              <form>
                <TextInput
                  type='text'
                  value={category}
                  onChange={(e) => this.handleChange(e)}
                  label='Create Category'
                  name='category'
                  required
                  color='rgba(112,112,112,.7)'
                />
                <div
                  className='w-100 m-b-auto flex flex-v-center space-between m-t-10'
                  style={{ alignSelf: 'flex-end' }}>
                  <button
                    className='btn btn-large w-49 p-10'
                    type='submit'
                    onClick={this.onSubmit}
                    disabled={loadForm}>
                    {button}{' '}
                    {loadForm && (
                      <img src={ButtonLoader} className='btn-loading' alt='Loading...' />
                    )}
                  </button>
                  <button
                    className='btn-large w-49 cancel-btn p-10'
                    type='button'
                    onClick={this.handleCancel}
                    disabled={loadForm}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className='col-8'>
            <div
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#43425D'}`,
                  color: `${this.props.headerColor || 'white'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <GiRecycle fontSize='20px' />
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Category</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Last Updated</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Updated By</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>
              {loadTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <>
                  {this.state.catRecords.length === 0 ? (
                    <h3>No category created</h3>
                  ) : (
                    <div className='table-body no-border'>
                      {this.state.catRecords.map((item) => (
                        <div
                          className='table-row no-border'
                          style={{ paddingBottom: 20 }}
                          key={item.id}>
                          <div
                            className='td left-text font-sz-22 pointer'
                            style={{ ...tableBorder }}>
                            {loadForm ? null : (
                              <>
                                <ReactTooltip
                                  type='warning'
                                  id={`editTip-${item.id}`}
                                  place='bottom'
                                  effect='solid'>
                                  Edit
                                </ReactTooltip>
                                <GrEdit
                                  data-tip
                                  data-for={`editTip-${item.id}`}
                                  onClick={() => this.onEdit(item)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className='td font-sz-13'
                            style={{
                              ...tableBorder,
                            }}>
                            {item.categoryName || 'empty category name'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.lastUpdated || 'empty last updated'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.lastUpdatedBy || 'empty last updated by'}
                          </div>

                          <div className='td m-l-10 font-sz-22 pointer' style={{ ...tableBorder }}>
                            {button === 'Update' || loadForm ? null : (
                              <>
                                <ReactTooltip
                                  type='error'
                                  id={`deleteTip-${item.id}`}
                                  place='bottom'
                                  effect='solid'>
                                  Delete
                                </ReactTooltip>
                                <GiTrashCan
                                  data-tip
                                  data-for={`deleteTip-${item.id}`}
                                  onClick={() => this.handleDeleteRecord(item)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* )} */}
      </>
    );
  }
}

export default AssetCategoryForm;
