/* eslint-disable */
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import TextareaInput from '../inputs/TextareaInput';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { IoMdClose } from 'react-icons/io';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class ViewReviewDisposalDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  //   approvers: [{…}]
  // assetApprovalStatus: "Director of Admin"
  // assetCategory: "Door Knob"
  // assetStatus: "test"
  // assetSupportingDoc: []
  // assignmentHistory: []
  // dateDelivered: "08 Jul, 2021"
  // dateInitiated: "16-Aug-2021 11:07:55AM"
  // dateProfiled: "17-Jul-2021"
  // description: "sdfasdfasdf"
  // disposalCategory: "Expired"
  // disposalNote: "test"
  // disposalSupportingDoc: [{…}]
  // id: 2
  // initiatedBy: "Emeson Kalu"
  // itemId: 30
  // itemName: "sdfasd"
  // lastUpdated: "16 Aug, 2021"
  // manufacturer: "asdfasd"
  // profilerId: 48
  // serialNumber: "asdfasd"
  // supplier: "sdfasd"
  // unitCost

  render() {
    const {
      itemName,
      initiatedBy,
      manufacturer,
      supplier,
      serialNumber,
      unitCost,
      disposalNote,
      disposalCategory,
      description,
      dateProfiled,
      dateInitiated,
      dateDelivered,
      assetStatus,
      assetCategory,
      assetApprovalStatus,

      assignmentHistory,
      assetSupportingDoc,
      approvers,
      disposalSupportingDoc,
      currentValue
    } = this.props.data;

    return (
      <>
        <div className='overlay'>
          <IoMdClose className='close-btn pointer m-l-5' onClick={this.props.closeModal} />
          <div className='modal-box' style={{ width: '90%' }}>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Disposal Details</p>
              <p>{itemName} Information</p>
            </div>
            <div className='content'>
              <div className='w-100 bg-white'>
                <div className='flex flex-direction-column'>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Initiated By: </div>
                    <div className='w-80 bold-text'>{initiatedBy || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Manufacturer: </div>
                    <div className='w-80 bold-text'>{manufacturer || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Supplier: </div>
                    <div className='w-80 bold-text'>{supplier || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Asset Value: </div>
                    <div className='w-80 bold-text'>&#8358;{unitCost || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Current Value: </div>
                    <div className='w-80 bold-text'>&#8358;{currentValue || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Disposal Note: </div>
                    <div className='w-80 bold-text'>{disposalNote || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>
                      Disposal Category:{' '}
                    </div>
                    <div className='w-80 bold-text'>{disposalCategory || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Description: </div>
                    <div className='w-80 bold-text'>{description || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Entered: </div>
                    <div className='w-80 bold-text'>{dateProfiled || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Initiated: </div>
                    <div className='w-80 bold-text'>{dateInitiated || 'null'}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Serial Number: </div>
                    <div className='w-80 bold-text'>{serialNumber || 'null'}</div>
                  </div>

                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Date Delivered: </div>
                    <div className='w-80 bold-text'>{dateDelivered || 'null'}</div>
                  </div>

                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Asset Status: </div>
                    <div className='w-80 bold-text'>{assetStatus || 'null'}</div>
                  </div>

                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Asset Category: </div>
                    <div className='w-80 bold-text'>{assetCategory || 'null'}</div>
                  </div>

                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>
                      Asset Approval Status:{' '}
                    </div>
                    <div className='w-80 bold-text'>{assetApprovalStatus || 'null'}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Disposal Supporting Document</p>
            </div>
            {disposalSupportingDoc.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>
                        There are no disposal supporting document
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {disposalSupportingDoc.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                            {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Supporting Document</p>
            </div>

            {assetSupportingDoc.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no asset supporting document</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {assetSupportingDoc.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                            {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Assignment History</p>
            </div>
            {assignmentHistory.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no assignment history</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {assignmentHistory.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                            {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewReviewDisposalDetails;
