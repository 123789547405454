/*eslint-disable */
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import ButtonLoader from '../../assets/svg/loading.svg';
import moment from 'moment';
import FormSearchInput from '../inputs/FormSearchInput';
import TextInput from '../inputs/TextInput';
import SelectInput from '../inputs/SelectInput';
import Select from 'react-select';
import { Post, Get, Delete, Put, GetwithQueryString } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
import { GiTrashCan } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
import data from '../../data';
import { GiRecycle } from 'react-icons/gi';
import Cookies from 'js-cookie';
import { deleteMessage, errorMessage, successMessage } from '../../resources/alert';
import LoadingSpinner from '../../resources/loadingSpinner';
const bottomBorder = {
  borderBottom: '.5px solid rgba(99,99,99,.6)',
};
const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class DepreciationRatioForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      approvalId: '',
      sequence: '',
      button: 'Save Record',
      records: [],
      employees: [],
      employee: 0,
      employeeName: '',
      bgColor: '',
      requestId: 0,
      approval: [],
      loading: false,
      bgColor: 'white',
      loadTable: false,
      loadForm: false,
      categoryId: 0,
      itemCategory: '',
      percentageRatio: 0,
    };
    // this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    console.log(e.target.name, 'value');
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEmpOption = (approvalId) => {
    this.setState({ approvalId }, () => console.log('option selected', this.state.approvalId));
  };

  getEmployee = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];

      this.setState({ employees: _records, loading: false });
    }
  };

  getApproval = (data) => {
    if (this._isMounted) {
      let _records = Array.isArray(data) ? data : [];

      this.setState({ approval: _records, loadTable: false });
    }
  };

  getCategoryList = (data) => {
    this.setState({
      employees: data,
    });
  };

  componentDidMount() {
    this.setState({ loadTable: true });
    const { depreciation, listLoader } = this.props;
    this._isMounted = true;
    try {
      Get(URLAPI.ratio.GetRatioList + '/' + 'depreciation', this.getApproval);
      Get(URLAPI.ratio.GetCategory, this.getCategoryList);
    } catch (err) {
      this.setState({ loadTable: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateForm = () => {
    const { categoryId, percentageRatio } = this.state;

    if (!categoryId) return { status: true, message: 'Please, Select a category item' };
    if (!percentageRatio) return { status: true, message: 'Please, Enter Devaluation Ratio' };
    return false;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loadTable: true,
      loadForm: true,
    });

    const { percentageRatio, categoryId, id } = this.state;

    let error_data = this.validateForm();

    if (error_data.status && error_data.status === true) {
      this.setState({
        loadTable: false,
        loadForm: false,
      });
      return errorMessage(error_data.message);
    }

    if (error_data === false) {
      let request;

      if (this.state.button === 'Save Record') {
        request = {
          itemCategoryId: Number(categoryId),
          category: 'Depreciation',
          percentageRatio: Number(percentageRatio),
        };
        this.onSubmitSave(request);
      } else if (this.state.button === 'Update') {
        request = {
          itemCategoryId: Number(categoryId),
          id,
          category: 'Depreciation',
          percentageRatio: Number(percentageRatio),
        };
        this.onSubmitUpdate(request);
      }
    }
  };

  onSubmitSave = (data) => {
    Post(data, URLAPI.ratio.Post, this.onSubmitAfterSave);
  };

  onSubmitAfterSave = (data) => {
    if (data.code === 1) {
      successMessage(data.message);
      Get(URLAPI.ratio.GetRatioList + '/' + 'depreciation', this.getApproval);

      this.setState({
        categoryId: 0,
        percentageRatio: 0,
        loadForm: false,
      });
    }
    if (data.code === 0) {
      this.setState({
        categoryId: 0,
        percentageRatio: 0,
        loadTable: false,
        loadForm: false,
      });
      errorMessage(data.message);
    }
  };

  handleDeleteRecord(record) {
    deleteMessage(() => this.onDelete(record));
  }

  onDelete = (record) => {
    this.setState({ loadTable: true });
    Delete(URLAPI.ratio.Delete + '/' + record.id, this.onAfterDelete);
  };

  onAfterDelete = (res) => {
    if (res.code === 1) {
      Get(URLAPI.ratio.GetRatioList + '/' + 'depreciation', this.getApproval);
      successMessage(res.message);

      this.setState({ loadTable: false });
    } else if (res.code === 1) {
      this.setState({ loadTable: false, visible: false });
      errorMessage(res.message);
    } else {
      this.setState({ loadTable: false });
      errorMessage(res.message);
    }
  };

  onEdit = (item) => {
    console.log(item, 'item was here >>>>');
    if (item) {
      const { itemCategory, percentageRatio, id, itemCategoryId } = item;
      this.setState({
        itemCategory,
        id,
        button: 'Update',
        itemCategoryId: id,
        categoryId: itemCategoryId,
        category: 'Depreciation',
        percentageRatio,
        bgColor: '#c2f542',
      });
    }
  };

  onSubmitUpdate = (request) => {
    if (request.itemCategoryId !== 0) {
      Put(request, `${URLAPI.ratio.Put}/${request.id}`, this.onSubmitAfterUpdate);
    }
  };

  onSubmitAfterUpdate = (data) => {
    if (data.code === 1) {
      successMessage(data.message);
      this.setState({ loadTable: true });
      Get(URLAPI.ratio.GetRatioList + '/' + 'depreciation', this.getApproval);
      //commit to state
      this.setState({
        category: '',
        itemCategory: 0,
        percentageRatio: 0,
        button: 'Save Record',
        loadForm: false,
        bgColor: 'white',
      });

      //this.handleCancel();
    } else if (data.code === 0) {
      this.setState({ loadTable: false, loadForm: false, button: 'Save Record', bgColor: 'white' });
      errorMessage(data.message);
    } else {
      errorMessage('Something went wrong while trying to update record Please, try again later.');
      this.setState({ loadTable: false, loadForm: false, button: 'Save Record', bgColor: 'white' });
    }
  };

  handleClear = () => {
    //clear all action
    this.setState({
      itemCategory: 0,
      percentageRatio: 0,
      button: 'Save Record',
      bgColor: 'white',
    });
  };

  handleStateChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleUpdateCategory = () => {
    Get(URLAPI.ratio.GetCategory, this.getCategoryList);
    Get(URLAPI.ratio.GetRatioList + '/' + 'depreciation', this.getApproval);
  };

  render() {
    const { percentageRatio, button, loadForm, loadTable, loading } = this.state;

    return (
      <>
        <div className='row'>
          <div className='col-4'>
            <div
              className='form-group p-10 m-l-10 pd-b-0  h-10'
              style={{ background: this.state.bgColor }}>
              <label>Select Category</label>
              {this.props.loading ? this.handleUpdateCategory() : null}
              {this.props.loading ? (
                <h4>loading...</h4>
              ) : (
                <select
                  className='w-100 m-b-10'
                  style={{
                    fontSize: 13,
                  }}
                  name='categoryId'
                  value={this.state.categoryId}
                  onChange={(e) => this.handleChange(e)}>
                  <option>{this.state.itemCategory}</option>
                  {this.state.employees.map((res, i) => (
                    <option value={res.id} key={i}>
                      {res.name}
                    </option>
                  ))}
                </select>
              )}
              <br />
              <TextInput
                label='Devaluation Ratio/year'
                value={percentageRatio}
                name='percentageRatio'
                type='number'
                color='rgba(112,112,112,.7)'
                onChange={(e) => this.handleChange(e)}
              />
              <div
                className='w-100 m-b-auto flex flex-v-center space-between m-t-10'
                style={{ alignSelf: 'flex-end' }}>
                <button
                  className='btn btn-large w-49 p-10'
                  type='button'
                  onClick={this.onSubmit}
                  disabled={loadForm}>
                  {button}{' '}
                  {loadForm && <img src={ButtonLoader} className='btn-loading' alt='Loading...' />}
                </button>
                <button
                  className='btn-large w-49 cancel-btn p-10'
                  type='button'
                  onClick={this.handleClear}
                  disabled={loadForm}>
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className='col-8'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                // border: 'none',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#43425D'}`,
                  color: `${this.props.headerColor || 'white'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <GiRecycle fontSize='20px' />
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Item</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Devaluation Value(%)</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Last Updated</p>
                  </div>
                  <div className='th case title w-25' style={{ ...bottomBorder }}>
                    <p>Updated By</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Action</p>
                  </div>
                </div>
              </div>

              {loadTable ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignContents: 'center' }}>
                  <Loader type='ThreeDots' color='green' height={80} width={80} />
                </div>
              ) : (
                <>
                  {this.state.approval.length === 0 ? (
                    <div>
                      <br />
                      <h3>No depreciation ratio created</h3>
                    </div>
                  ) : (
                    <div className='table-body no-border'>
                      {this.state.approval.map((item) => (
                        <div className='table-row no-border' key={item.id}>
                          <div
                            className='td left-text font-sz-22 pointer'
                            style={{ ...tableBorder }}>
                            {loadForm ? null : (
                              <>
                                <ReactTooltip
                                  id={`editTip-${item.id}`}
                                  place='bottom'
                                  type='warning'
                                  effect='solid'>
                                  Edit
                                </ReactTooltip>
                                <GrEdit
                                  data-tip
                                  data-for={`editTip-${item.id}`}
                                  onClick={() => this.onEdit(item)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className='td font-sz-13'
                            style={{
                              ...tableBorder,
                            }}>
                            {item.itemCategory || 'empty item category'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.percentageRatio || 'empty percentage ratio'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.lastUpdated || 'empty last updated'}
                          </div>
                          <div className='td font-sz-13' style={{ ...tableBorder }}>
                            {item.lastUpdatedBy || 'empty last updated by'}
                          </div>

                          <div className='td m-l-10 font-sz-22 pointer' style={{ ...tableBorder }}>
                            {button === 'Update' || loadForm ? null : (
                              <>
                                <ReactTooltip
                                  id={`deleteTip-${item.id}`}
                                  place='bottom'
                                  type='error'
                                  effect='solid'>
                                  Delete
                                </ReactTooltip>
                                <GiTrashCan
                                  data-tip
                                  data-for={`deleteTip-${item.id}`}
                                  onClick={() => this.handleDeleteRecord(item)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DepreciationRatioForm;
