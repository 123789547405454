/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
// import { Link } from 'react-router-dom';
import { TiArrowSortedDown } from 'react-icons/ti';

import UserAvater from '../../assets/images/user-avater.png';

import { formatFileUrl } from '../../utility/general';

const Header = ({ history, details = {}, navList = [] }) => {
  const [userPix, setUserPix] = useState('');
  const [imgHasError, setImgHasError] = useState(false);

  useEffect(() => {
    !imgHasError && setUserPix(formatFileUrl(details.userProfilePicture));
  }, [imgHasError, details]);

  const handleImgError = (e) => {
    e.target.src = UserAvater;
    setUserPix('');
    setImgHasError(true);
  };

  return (
    <header>
      <div className='content header-content space-between flex-v-center'>
        <div className='header-left'>
          <h3>Asset Management</h3>
        </div>
        <div className='header-right flex flex-v-center'>
          <div className='right-item'>
            <ul className='nav-menu'>
              {navList && navList.map((item) => <li key={item}>{item}</li>)}
            </ul>
          </div>
          <div className='right-item'>
            <div
              role='presentation'
              onClick={() => {}}
              className='user-avater-details flex flex-v-center pointer'>
              <div className='m-r-5 right-text' style={{ paddingTop: '3px' }}>
                <p className='name'> {Cookies.get('fullname')}</p>
                <p className='role'>{Cookies.get('role')}</p>
              </div>
              <div className='flex'>
                <img
                  onError={handleImgError}
                  src={'http://devsvr.edogoverp.com/requestmanager' + Cookies.get('profile')}
                  alt={Cookies.get('fullname')}
                />
              </div>
              <div className='flex arrow'>
                <TiArrowSortedDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
