/* eslint-disable */
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import TextareaInput from '../inputs/TextareaInput';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { IoMdClose } from 'react-icons/io';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class ViewProfilerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const {
      profiledBy,
      itemName,
      description,
      supplier,
      manufacturer,
      deliveryDate,
      unitCost,
      totalCost,
      serialNumber,
      category,
      quantity,
      lastUpdated,
      supportingDoc,
      approvers,
    } = this.props;

    return (
      <>
        <div className='overlay'>
          <IoMdClose className='close-btn pointer m-l-5' onClick={this.props.closeModal} />
          <div className='modal-box' style={{ width: '90%' }}>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Asset Entry Details</p>
              <p>{itemName} Information</p>
            </div>
            <div className='content'>
              <div className='w-100 bg-white'>
                <div className='flex flex-direction-column'>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Entered By: </div>
                    <div className='w-80 bold-text'>{profiledBy || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Item Name: </div>
                    <div className='w-80 bold-text'>{itemName || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Description: </div>
                    <div className='w-80 bold-text'>{description || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Supplier: </div>
                    <div className='w-80 bold-text'>{supplier || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Manufacturer: </div>
                    <div className='w-80 bold-text'>{manufacturer || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Delivery Date: </div>
                    <div className='w-80 bold-text'>{deliveryDate || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Asset Value: </div>
                    <div className='w-80 bold-text'>&#8358;{unitCost || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Total Value: </div>
                    <div className='w-80 bold-text'>&#8358;{totalCost || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Serial Number: </div>
                    <div className='w-80 bold-text'>{serialNumber || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Category: </div>
                    <div className='w-80 bold-text'>{category || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Quantity: </div>
                    <div className='w-80 bold-text'>{quantity || ''}</div>
                  </div>
                  <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                    <div className='col-2-4 no-padding no-margin bold-text'>Last Updated: </div>
                    <div className='w-80 bold-text'>{lastUpdated || ''}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#44435D', color: 'white' }}>
              <p>Supporting Document</p>
            </div>
            {supportingDoc.length === 0 ? (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='w-80 bold-text'>There are no supporting document</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    {supportingDoc.map((value, i) => {
                      return (
                        <div key={i} className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                          <div className='w-80 bold-text'>
                            {i + 1}.
                            <a
                              style={{ textDecoration: 'none' }}
                              href={value.documentPath}
                              target='_blank'>
                              {value.documentTitle}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewProfilerDetails;
